import { useMutation } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import { matchfyApi } from '@/api';
import AccountSection from '@/components/Account/AccountSection';
import Development from '@/components/Development';
import Button from '@/components/ui/Button';
import Field from '@/components/ui/Field';
import { USER_PLANS, USER_TYPES } from '@/data';
import { useSession, useTranslations } from '@/hooks';
export default function DevSettings() {
  const [score, setScore] = useState();
  const [gems, setGems] = useState();
  const [plan, setPlan] = useState();
  const [type, setType] = useState();
  const {
    data: session,
    update
  } = useSession();
  const user = session?.user;
  const t = useTranslations();
  const planOptions = Object.values(USER_PLANS).map(plan => ({
    label: plan,
    value: USER_PLANS[plan]
  }));
  const userTypeOptions = Object.values(USER_TYPES).map(type => ({
    label: type,
    value: type
  }));
  const {
    mutate: updateUserSettings,
    isLoading
  } = useMutation({
    mutationFn: async data => {
      const response = await matchfyApi.devUpdatePlan(data);
      update();
      return response.data;
    }
  });
  useEffect(() => {
    setPlan(USER_PLANS[user?.plan?.name]);
    setScore(user?.referral?.score);
    setGems(user?.referral?.gems);
    setType(user?.type);
  }, [user?.plan?.name]);
  return <Development data-sentry-element="Development" data-sentry-component="DevSettings" data-sentry-source-file="DevSettings.jsx">
      <AccountSection title={t('settings.dev.title')} data-sentry-element="AccountSection" data-sentry-source-file="DevSettings.jsx">
        <div>
          <label htmlFor="settings_plan">
            {t('settings.dev.plan.label')}
          </label>

          <p className="mb-4 text-sm opacity-80">
            {t('settings.dev.plan.help')}
          </p>

          <Field id="settings_plan" name="plan" type="select" onChange={option => setPlan(option.value)} options={planOptions} value={planOptions.find(({
          value
        }) => value === plan)} className="select" classNamePrefix="select" isSearchable={false} data-sentry-element="Field" data-sentry-source-file="DevSettings.jsx" />
        </div>

        <div>
          <label htmlFor="settings_gems">
            {t('settings.dev.gems.label')}
          </label>

          <p className="mb-4 text-sm opacity-80">
            {t('settings.dev.gems.help')}
          </p>

          <Field id="settings_gems" name="gems" type="number" value={gems} onChange={e => setGems(e.target.value)} data-sentry-element="Field" data-sentry-source-file="DevSettings.jsx" />
        </div>

        <div>
          <label htmlFor="settings_score">
            {t('settings.dev.score.label')}
          </label>

          <p className="mb-4 text-sm opacity-80">
            {t('settings.dev.score.help')}
          </p>

          <Field id="settings_score" name="score" type="number" value={score} onChange={e => setScore(e.target.value)} data-sentry-element="Field" data-sentry-source-file="DevSettings.jsx" />
        </div>

        <div>
          <label htmlFor="settings_type">
            {t('settings.dev.type.label')}
          </label>

          <Field id="settings_type" name="type" type="select" onChange={option => setType(option.value)} options={userTypeOptions} value={userTypeOptions.find(({
          value
        }) => value === type)} data-sentry-element="Field" data-sentry-source-file="DevSettings.jsx" />
        </div>

        <Button className="w-full md:w-auto" variant="primary" disabled={isLoading} loading={isLoading} onClick={() => updateUserSettings({
        plan,
        score,
        gems,
        type
      })} label={t('settings.actions.save')} data-sentry-element="Button" data-sentry-source-file="DevSettings.jsx" />
      </AccountSection>
    </Development>;
}