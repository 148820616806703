import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { matchfyApi } from '@/api';
import Button from '@/components/ui/Button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/Dialog';
import { STATUSES, getPushProSystemMessage } from '@/data';
import { QUERIES } from '@/data/queries';
import { useTranslations } from '@/hooks';
import { toast } from '@/lib';
import { useChatStore } from '@/store';
export const PushProClose = ({
  chatId,
  size,
  isProCurator
}) => {
  const t = useTranslations();
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const {
    sendSystemMessage
  } = useChatStore();
  const {
    mutate: onSubmitForm,
    isLoading
  } = useMutation({
    mutationFn: matchfyApi.closePushPro,
    onSuccess: () => {
      const systemMessage = getPushProSystemMessage(STATUSES.CLOSED, isProCurator);
      sendSystemMessage(systemMessage);
      toast('success', t('push_pro.close.success'));
      queryClient.invalidateQueries({
        queryKey: [QUERIES.CHAT.LIST]
      });
      setOpen(false);
    },
    onError: () => {
      toast('error', t('push_pro.close.error'));
    }
  });
  return <>
      <Button size={size} variant="blue-light" icon="MessageCircleReply" label={t('common.actions.close')} onClick={() => setOpen(true)} data-sentry-element="Button" data-sentry-source-file="PushProClose.jsx" />

      <Dialog open={open} onOpenChange={setOpen} data-sentry-element="Dialog" data-sentry-source-file="PushProClose.jsx">
        <DialogContent size="sm" data-sentry-element="DialogContent" data-sentry-source-file="PushProClose.jsx">
          <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="PushProClose.jsx">
            <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="PushProClose.jsx">
              {t('push_pro.close.title')}
            </DialogTitle>
          </DialogHeader>

          <DialogDescription data-sentry-element="DialogDescription" data-sentry-source-file="PushProClose.jsx">
            {t('push_pro.close.description')}
          </DialogDescription>

          <DialogFooter className="flex items-center justify-between mt-4" data-sentry-element="DialogFooter" data-sentry-source-file="PushProClose.jsx">
            <Button onClick={() => setOpen(false)} variant="inverse" label={t('common.actions.cancel')} data-sentry-element="Button" data-sentry-source-file="PushProClose.jsx" />

            <Button onClick={() => onSubmitForm({
            id: chatId
          })} loading={isLoading} label={t('common.actions.confirm')} data-sentry-element="Button" data-sentry-source-file="PushProClose.jsx" />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>;
};
export default PushProClose;