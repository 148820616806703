'use client';

import Alert from '@/components/ui/Alert';
import Button from '@/components/ui/Button';
import { PLANS } from '@/data';
import { useTranslations } from '@/hooks';
import { cn, planRedirect } from '@/utils';
export const ProFeatureAlert = ({
  suggestedPlan = PLANS.ARTIST,
  className
}) => {
  const t = useTranslations();
  return <Alert inline size="sm" variant="theme-primary" className={cn({
    'pl-4 pr-2 py-2': true,
    [className]: className
  })} cta={<Button size="xs" href={planRedirect(suggestedPlan)} variant="theme-primary" className="w-full md:w-auto">
          {t('banner.free.actions.upgrade')}
        </Button>} data-sentry-element="Alert" data-sentry-component="ProFeatureAlert" data-sentry-source-file="ProFeatureAlert.jsx">
      <p className="pr-4 text-sm">
        {t('pro_feature.body', {
        plan: suggestedPlan,
        shouldParse: true
      })}
      </p>
    </Alert>;
};
export default ProFeatureAlert;