import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { OutgoingSubmissionItem } from './OutgoingSubmissionItem';
import { matchfyApi } from '@/api';
import { ProFeatureAlert } from '@/components/ProFeature';
import { Accordion, AccordionItem } from '@/components/ui/Accordion';
import Empty from '@/components/ui/Empty';
import Spinner from '@/components/ui/Spinner';
import { CURATOR_STATS_DAYS_RANGE, QUERIES } from '@/data';
import { useTranslations, useSession } from '@/hooks';
import { cn, buildSpotifyTrackUrl, isFree } from '@/utils';
export function CuratorOutgoingSubmissions({
  userId,
  className
}) {
  const DAYS_RANGE = 90;
  const startDate = dayjs().subtract(DAYS_RANGE, 'days').toDate();
  const endDate = dayjs().toDate();
  const t = useTranslations();
  const {
    data: session
  } = useSession();
  const {
    user
  } = session || {};
  const isOwner = user.id === userId;
  const {
    data,
    isLoading
  } = useQuery({
    queryKey: [QUERIES.CURATOR.COUNTERS.OUTGOING, userId],
    queryFn: () => matchfyApi.getCuratorOutgoingCounters(userId, {
      startDate,
      endDate
    })
  });
  return <Accordion data-sentry-element="Accordion" data-sentry-component="CuratorOutgoingSubmissions" data-sentry-source-file="CuratorOutgoingSubmissions.jsx">
      <AccordionItem id="curator-outgoing-submissions" icon="ArrowRightToLine" label={`${t('curator.widgets.outgoing_submissions')} (${t('common.time.latest_x_days', {
      days: CURATOR_STATS_DAYS_RANGE
    })})`} data-sentry-element="AccordionItem" data-sentry-source-file="CuratorOutgoingSubmissions.jsx">
        <div className={cn('curator-header', className)}>
          {isLoading && <div className="flex items-center justify-center">
              <Spinner />
            </div>}

          {isFree(user) && !isOwner && <ProFeatureAlert className="mb-6" />}

          {!isLoading && data && data.length > 0 && <div className={cn({
          "grid grid-cols-1 gap-y-6 gap-x-8 md:grid-cols-2": true,
          "blur-[3px] pointer-events-none": isFree(user) && !isOwner
        })}>
              {data.map(item => <OutgoingSubmissionItem key={item.trackId} url={buildSpotifyTrackUrl(item.trackId)} title={item.track?.name} image={item.track?.image} total={item.total} accepted={item.accepted} rejected={item.rejected} pending={item.pending} hasVip={item.hasVip} />)}
            </div>}

          {!isLoading && (!data || data?.length === 0) && <Empty horizontal className="w-full py-10 my-0 border-0" />}
        </div>
      </AccordionItem>
    </Accordion>;
}
export default CuratorOutgoingSubmissions;