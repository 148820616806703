'use client';

import { useRouter } from 'next/navigation';
import { useSession } from 'next-auth/react';
import React, { useState } from 'react';
import Auth from '@/components/Auth';
import Button from '@/components/ui/Button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/Dialog';
import Empty from '@/components/ui/Empty';
import { PLANS } from '@/data';
import { useTranslations } from '@/hooks';
import { planRedirect } from '@/utils';
export const ProFeature = ({
  allowedPlans,
  suggestedPlan,
  icon = 'Lock',
  children,
  className,
  onClick
}) => {
  const [open, setOpen] = useState(false);
  const {
    data: session
  } = useSession();
  const user = session?.user || {};
  const router = useRouter();
  const t = useTranslations();
  const canAccessFeature = e => {
    if (!allowedPlans.includes(user?.plan?.name)) {
      e.preventDefault();
      setOpen(true);
      return false;
    } else {
      if (onClick) {
        onClick(e);
      }
    }
  };
  const handleClick = () => {
    setOpen(false);
    router.push(planRedirect(suggestedPlan ? suggestedPlan : PLANS.ARTIST));
  };
  const descriptionText = t('pro_feature.body', {
    plan: <Button key={suggestedPlan ? suggestedPlan : PLANS.ARTIST} variant="link" onClick={handleClick}>
        {suggestedPlan ? suggestedPlan : PLANS.ARTIST}
      </Button>,
    shouldParse: true
  });
  return <>
      <Dialog open={open} onOpenChange={setOpen} data-sentry-element="Dialog" data-sentry-source-file="ProFeature.jsx">
        <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="ProFeature.jsx">
          <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="ProFeature.jsx">
            <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="ProFeature.jsx">{t('pro_feature.title')}</DialogTitle>
          </DialogHeader>

          <Auth login emptyState className="my-0" data-sentry-element="Auth" data-sentry-source-file="ProFeature.jsx">
            <Empty className="my-0" message={descriptionText} icon={icon} data-sentry-element="Empty" data-sentry-source-file="ProFeature.jsx">
              <Button label={t('common.actions.upgrade')} onClick={handleClick} className="mt-4 md:min-w-[200px]" data-sentry-element="Button" data-sentry-source-file="ProFeature.jsx" />
            </Empty>
          </Auth>
        </DialogContent>
      </Dialog>

      <div className={className} onClick={canAccessFeature}>
        {children}
      </div>
    </>;
};
export default ProFeature;