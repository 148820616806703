import { useMutation } from '@tanstack/react-query';
import { signOut } from "next-auth/react";
import { matchfyApi } from '@/api';
import { DropdownMenuItem } from '@/components/ui/Dropdown/DropdownMenu';
import Icon from '@/components/ui/Icon';
import { LOGOUT_REDIRECT } from '@/data';
import { clearStorage } from '@/utils';
export default function DevPurgeUser() {
  const {
    mutate: purgeUser,
    isPurging
  } = useMutation({
    mutationFn: async () => {
      console.log('Purging user');
      await matchfyApi.devPurgeUser();
      console.log('User purged');
      clearStorage();
      signOut({
        callbackUrl: LOGOUT_REDIRECT
      });
    }
  });
  return <DropdownMenuItem disabled={isPurging} loading={isPurging} id="dev-purge-user" onSelect={e => e.preventDefault()} onClick={() => purgeUser()} className="inline-flex items-center gap-2" label="Purge User" data-sentry-element="DropdownMenuItem" data-sentry-component="DevPurgeUser" data-sentry-source-file="DevPurgeUser.jsx">
      <Icon name="Eraser" className="w-4 h-4" data-sentry-element="Icon" data-sentry-source-file="DevPurgeUser.jsx" />
      <span>Purge User</span>
      <span className="px-1 ml-auto text-[0.6rem] tracking-wide uppercase border rounded-[0.2rem] text-muted-foreground border-muted-foreground">
        Dev
      </span>
    </DropdownMenuItem>;
}