import { useState } from 'react';
import { Dialog, DialogContent } from '@/components/ui/Dialog';
import { CURATOR_BIO_LIMIT } from '@/data';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
export function UserBio({
  bio,
  limit = CURATOR_BIO_LIMIT,
  className
}) {
  const t = useTranslations();
  const [isOpen, setIsOpen] = useState(false);
  const longBio = bio?.length > limit;
  const bioCn = cn({
    'curator-bio text-balance text-left opacity-90': true
  });
  if (!longBio) {
    return <div className={cn(bioCn, className)}>
        <p>{bio || t('user.defaults.bio')}</p>
      </div>;
  }
  return <Dialog open={isOpen} onOpenChange={setIsOpen} data-sentry-element="Dialog" data-sentry-component="UserBio" data-sentry-source-file="UserBio.jsx">
      <div className={cn(bioCn, className, 'cursor-pointer hover:opacity-100')}>
        <p>{(bio || t('user.defaults.bio')).slice(0, limit)}...</p>
        <span className="block mt-2 text-sm text-primary">
          {t('common.actions.readMore')}
        </span>
      </div>

      <DialogContent className="max-w-2xl" data-sentry-element="DialogContent" data-sentry-source-file="UserBio.jsx">
        <p className="whitespace-pre-wrap">{bio || t('user.defaults.bio')}</p>
      </DialogContent>
    </Dialog>;
}
export default UserBio;