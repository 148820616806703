'use client';

import { cn } from '@/utils';
export const ChartLegend = ({
  data,
  className,
  position = 'bottom'
}) => {
  const classes = cn({
    'flex flex-wrap justify-center gap-x-4 gap-y-2': position === 'bottom' || position === 'top',
    'absolute bottom-4 left-0 right-0': position === 'bottom',
    'absolute top-4 left-0 right-0': position === 'top',
    [className]: className
  });
  return <div className={classes} data-sentry-component="ChartLegend" data-sentry-source-file="ChartLegend.jsx">
      {data.map(({
      id,
      label,
      color,
      value
    }) => <div key={id} className="flex items-center gap-1">
          <div className="w-3 h-3 rounded-full" style={{
        backgroundColor: color
      }} />
          <span className="text-sm font-medium">
            {label} ({value})
          </span>
        </div>)}
    </div>;
};
export default ChartLegend;