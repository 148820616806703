import Badge from '@/components/ui/Badge';
import Image from '@/components/ui/Image';
import { Placeholder } from '@/components/ui/Placeholder';
import { useTranslations } from '@/hooks';
import { cn, formatNumber } from '@/utils';
export function OutgoingSubmissionItem({
  className,
  url,
  title,
  image,
  total,
  accepted,
  rejected,
  pending,
  hasVip
}) {
  const t = useTranslations();
  const classes = cn({
    "flex items-start gap-3": true,
    [className]: className
  });
  return <div className={classes} data-sentry-component="OutgoingSubmissionItem" data-sentry-source-file="OutgoingSubmissionItem.jsx">
      <Image src={image} placeholder={<Placeholder size="sm" icon="Music" />} alt={title} className="relative overflow-hidden rounded-sm w-14 h-14 aspect-square shrink-0" imageClasses="absolute inset-0 object-cover w-full h-full" data-sentry-element="Image" data-sentry-source-file="OutgoingSubmissionItem.jsx" />

      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-2">
          <a href={url} target="_blank" rel="noopener noreferrer">
            <h3 className="mb-0 text-base font-medium">
              {title}
            </h3>
          </a>

          {hasVip && <Badge size="sm" variant="gold-light" label={t('playlist.queue.metadata.vip')} icon="Gem" />}
        </div>

        <div className="flex flex-wrap gap-2">
          {total && <Badge size="sm" icon="Sigma" variant="light" label={t('common.labels.total')} value={formatNumber(total)} />}

          {!!accepted && <Badge size="sm" variant="green-light" label={t('common.states.accepted', {
          count: accepted
        })} value={formatNumber(accepted)} icon="Check" />}

          {!!pending && <Badge size="sm" variant="orange-light" label={t('common.states.in_queue', {
          count: pending
        })} value={formatNumber(pending)} icon="Hourglass" />}

          {!!rejected && <Badge size="sm" variant="red-light" label={t('common.states.rejected', {
          count: rejected
        })} value={formatNumber(rejected)} icon="X" />}
        </div>
      </div>
    </div>;
}
export default OutgoingSubmissionItem;