import Badge from '@/components/ui/Badge';
import { PLAN_CONFIG } from '@/data';
import { useTranslations } from '@/hooks';
export function UserPlanBadge({
  plan
}) {
  const t = useTranslations();
  const getBadgeProps = () => {
    return {
      ...PLAN_CONFIG[plan],
      variant: `${PLAN_CONFIG[plan].color}-light`,
      label: PLAN_CONFIG[plan].title
    };
  };
  return <Badge {...getBadgeProps()} data-sentry-element="Badge" data-sentry-component="UserPlanBadge" data-sentry-source-file="UserPlanBadge.jsx" />;
}
export default UserPlanBadge;