import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { matchfyApi } from '@/api';
import Button from '@/components/ui/Button';
import ConfirmationPopup from '@/components/ui/ConfirmationPopup';
import { getPushProSystemMessage, STATUSES } from '@/data';
import { QUERIES } from '@/data/queries';
import { useTranslations } from '@/hooks';
import { toast } from '@/lib';
import { useChatStore } from '@/store';
export const PushProDisputeUndo = ({
  chatId,
  size,
  isProCurator
}) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const {
    pushProId,
    setPushProHasDispute,
    sendSystemMessage
  } = useChatStore();
  const t = useTranslations();
  const queryClient = useQueryClient();
  const {
    mutate: undoDispute,
    isLoading
  } = useMutation({
    mutationFn: () => matchfyApi.undoReportPushPro(pushProId),
    onSuccess: data => {
      const systemMessage = getPushProSystemMessage(STATUSES.DISPUTE_UNDO, isProCurator);
      sendSystemMessage(systemMessage);
      queryClient.invalidateQueries({
        queryKey: [QUERIES.CHAT.LIST]
      });
      setConfirmationOpen(false);
      setPushProHasDispute(false);
      toast('success', t('push_pro.report_undo.success'));
    },
    onError: () => {
      toast('error', t('feedback.error.generic'));
    }
  });
  return <>
      <ConfirmationPopup open={confirmationOpen} onConfirm={undoDispute} loading={isLoading} onReject={() => setConfirmationOpen(false)} title={t('push_pro.report_undo.title')} text={t('push_pro.report_undo.description')} data-sentry-element="ConfirmationPopup" data-sentry-source-file="PushProDisputeUndo.jsx" />

      <Button size={size} variant="orange-light" icon="MessageSquareWarning" label={t('push_pro.button.report_undo')} onClick={() => setConfirmationOpen(true)} loading={isLoading} disabled={isLoading} data-sentry-element="Button" data-sentry-source-file="PushProDisputeUndo.jsx" />
    </>;
};
export default PushProDisputeUndo;