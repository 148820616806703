'use client';

import { useState, useEffect } from 'react';
import CuratorPageButton from './CuratorPageButton';
import MonthlyPushLimitPopup from './MonthlyPushLimitPopup';
import PushButton from './PushButton';
import VipCampaignPopup from './VipCampaignPopup';
import CuratorContacts from '@/components/Curator/CuratorContacts';
import { MatchMessage } from '@/components/Match';
import { useAnalytics, useTranslations } from '@/hooks';
import { EVENTS, toast, mxp } from '@/lib';
import { usePushToPlaylistStore, useMatchStore } from '@/store';
import { cn } from '@/utils';
function PushToPlaylist({
  className,
  disabled,
  curatorId,
  curatorName,
  isPushed = false,
  playlist,
  score,
  track,
  tracks,
  trackId,
  userName,
  checksum,
  playlistPushEnabled = true
}) {
  const [open, setOpen] = useState(false);
  const [pushed, setPushed] = useState(isPushed);
  const [isTrackInPlaylist, setIsTrackInPlaylist] = useState(isPushed);
  const {
    gtagEvent
  } = useAnalytics();
  const {
    trackId: matchTrackId,
    genres: matchGenres
  } = useMatchStore();
  const {
    success,
    error,
    loading,
    increment,
    pushedPlaylists,
    setPushedPlaylists,
    pushToPlaylist,
    resetFeedback
  } = usePushToPlaylistStore();
  const t = useTranslations();
  useEffect(() => {
    if (isPushed) {
      setIsTrackInPlaylist(true);
      return;
    }
    if (!tracks || !track || tracks.length === 0) {
      setIsTrackInPlaylist(false);
      return;
    }
    const filteredTracks = tracks.filter(track => track !== null && track !== undefined);
    const found = Boolean(filteredTracks.find(({
      track
    }) => track?.id === trackId));
    setIsTrackInPlaylist(found);
  }, [trackId, tracks, isPushed, track]);
  const pushHandler = async () => {
    const trackData = {
      id: trackId,
      name: track?.name,
      image: track?.album?.images[0].url
    };
    await pushToPlaylist(playlist.id, trackData, checksum);
  };
  useEffect(() => {
    return () => {
      resetFeedback();
    };
  }, []);
  useEffect(() => {
    setPushed(isTrackInPlaylist);
  }, [isTrackInPlaylist]);
  useEffect(() => {
    setPushed(false);
  }, [matchTrackId, matchGenres]);
  useEffect(() => {
    if (loading) return;
    if (error) {
      toast('error', t(error));
      if (error === 'errors.push.already_exists') {
        setPushed(true);
      }
      if (error === 'errors.push.plan_limit_reached' || error === 'errors.push.daily_limit_reached') {
        setOpen(true);
      }
    }
    if (success) {
      increment();
      setPushed(true);
      setPushedPlaylists([...pushedPlaylists, playlist.id]);
      gtagEvent({
        category: 'Push to playlist',
        action: 'Push to playlist'
      });
      mxp.track(EVENTS.PUSH_TO_PLAYLIST, {
        playlistId: playlist.id,
        playlistName: playlist.name,
        trackId: trackId,
        trackTitle: track?.title,
        trackArtist: track?.artist
      });
      toast('success', t('feedback.success.track.push'));
    }
  }, [success, error]);
  const classes = cn({
    'push-to-playlist': true,
    [className]: className
  });
  if (pushed || isTrackInPlaylist) {
    return <div className={classes}>
        <CuratorPageButton trackId={trackId} playlistId={playlist.id} isTrackInPlaylist={isTrackInPlaylist} />

        <CuratorContacts curatorId={curatorId} />

        <MatchMessage className="mt-4" typology="spotify" score={score} track={track} playlist={playlist} curatorName={curatorName} userName={userName} />
      </div>;
  }
  if (!playlistPushEnabled) {
    return null;
  }
  return <>
      <MonthlyPushLimitPopup open={open} setOpen={setOpen} data-sentry-element="MonthlyPushLimitPopup" data-sentry-source-file="PushToPlaylist.jsx" />

      <VipCampaignPopup data-sentry-element="VipCampaignPopup" data-sentry-source-file="PushToPlaylist.jsx" />

      <PushButton loading={loading} className={classes} disabled={disabled} onClick={pushHandler} data-sentry-element="PushButton" data-sentry-source-file="PushToPlaylist.jsx" />
    </>;
}
export default PushToPlaylist;