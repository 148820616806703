import { useMutation } from '@tanstack/react-query';
import { matchfyApi } from '@/api';
import { DropdownMenuItem } from '@/components/ui/Dropdown/DropdownMenu';
import Icon from '@/components/ui/Icon';
import { useSession } from '@/hooks';
export default function DevVerifyUser() {
  const {
    update
  } = useSession();
  const {
    mutate: verifyUser,
    isVerifying
  } = useMutation({
    mutationFn: async () => {
      await matchfyApi.devVerifyUser();
      update();
    }
  });
  return <DropdownMenuItem disabled={isVerifying} loading={isVerifying} onSelect={e => e.preventDefault()} onClick={() => verifyUser()} className="inline-flex items-center gap-2" label="Verify User" data-sentry-element="DropdownMenuItem" data-sentry-component="DevVerifyUser" data-sentry-source-file="DevVerifyUser.jsx">
      <Icon name="UserCheck" className="w-4 h-4" data-sentry-element="Icon" data-sentry-source-file="DevVerifyUser.jsx" />
      <span>Verify User</span>
      <span className="px-1 ml-auto text-[0.6rem] tracking-wide uppercase border rounded-[0.2rem] text-muted-foreground border-muted-foreground">
        Dev
      </span>
    </DropdownMenuItem>;
}