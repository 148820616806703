'use client';

import React, { useEffect, useState } from 'react';
import Button from '@/components/ui/Button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/Dialog';
import { ROUTES } from '@/data';
import { useTranslations } from '@/hooks';
import { usePushToPlaylistStore } from '@/store';
export const VipCampaignPopup = () => {
  const [open, setOpen] = useState(false);
  const {
    pushedPlaylists,
    pushedLimit,
    incrementLimit
  } = usePushToPlaylistStore();
  const t = useTranslations();
  useEffect(() => {
    if (pushedPlaylists?.length > pushedLimit) {
      setOpen(true);
      incrementLimit();
    } else {
      setOpen(false);
    }
  }, [pushedPlaylists]);
  return <Dialog open={open} onOpenChange={setOpen} data-sentry-element="Dialog" data-sentry-component="VipCampaignPopup" data-sentry-source-file="VipCampaignPopup.jsx">
      <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="VipCampaignPopup.jsx">
        <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="VipCampaignPopup.jsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="VipCampaignPopup.jsx">{t('vip_campaign.popup.title')}</DialogTitle>
        </DialogHeader>

        <img className="mb-5 rounded-xl border-outline" src="/images/vip/vip_campaign_mockup.jpg" alt="VIP Campaign" />

        <p className="mb-3 text-lg">
          {t('vip_campaign.popup.text.0', {
          shouldParse: true
        })}
        </p>

        <p className="mb-3 text-lg">
          {t('vip_campaign.popup.text.1', {
          shouldParse: true
        })}
        </p>

        <p className="mb-3 text-lg">
          {t('vip_campaign.popup.text.2', {
          shouldParse: true
        })}
        </p>

        <Button variant="gradient" className="w-full mt-3" href={ROUTES.VIP} label="VIP Campaign" data-sentry-element="Button" data-sentry-source-file="VipCampaignPopup.jsx" />
      </DialogContent>
    </Dialog>;
};
export default VipCampaignPopup;