'use client';

import Button from '@/components/ui/Button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/Dialog';
import { useTranslations } from '@/hooks';
export default function ConfirmationPopup({
  open,
  onReject,
  onConfirm,
  title,
  icon,
  text,
  confirmText,
  rejectText,
  rejectVariant = 'inverse',
  confirmVariant = 'primary',
  children,
  className,
  bodyClasses,
  loading
}) {
  const t = useTranslations();
  const handleConfirm = async () => {
    if (onConfirm) {
      await onConfirm();
    }
  };
  return <Dialog open={open} onOpenChange={onReject} data-sentry-element="Dialog" data-sentry-component="ConfirmationPopup" data-sentry-source-file="ConfirmationPopup.jsx">
      <DialogContent className={className} size="md" data-sentry-element="DialogContent" data-sentry-source-file="ConfirmationPopup.jsx">
        {title && <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
          </DialogHeader>}

        <div className={bodyClasses}>
          {text && <p>{text}</p>}
          {children}
        </div>

        <DialogFooter className="flex items-center justify-between gap-4 mt-4" data-sentry-element="DialogFooter" data-sentry-source-file="ConfirmationPopup.jsx">
          <Button variant={rejectVariant} className="w-full md:min-w-[150px]" onClick={onReject} data-sentry-element="Button" data-sentry-source-file="ConfirmationPopup.jsx">
            {rejectText || t('common.actions.cancel')}
          </Button>

          <Button variant={confirmVariant} onClick={handleConfirm} loading={loading} disabled={loading} className="w-full md:min-w-[150px]" data-sentry-element="Button" data-sentry-source-file="ConfirmationPopup.jsx">
            {confirmText || t('common.actions.confirm')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>;
}