import { DEV_FLAGS } from '@/data';
import { useRouter } from '@/i18n/routing';
import { useDevStore } from '@/store';
export default function Development({
  children,
  redirect
}) {
  const {
    isDev,
    hasFlag
  } = useDevStore();
  const router = useRouter();
  if (!isDev && redirect) {
    router.push(redirect);
  }
  if (isDev && hasFlag(DEV_FLAGS.SHOW_DEV_LOGS)) {
    return <>{children}</>;
  }
}