import { CuratorIncomingSubmissions, CuratorOutgoingSubmissions, UserArtists } from '@/components/User';
import { cn } from '@/utils';
export function UserAnalytics({
  userId,
  className
}) {
  const classes = cn('flex flex-col gap-4', className);
  return <div className={classes} data-sentry-component="UserAnalytics" data-sentry-source-file="UserAnalytics.jsx">
      <CuratorIncomingSubmissions userId={userId} data-sentry-element="CuratorIncomingSubmissions" data-sentry-source-file="UserAnalytics.jsx" />

      <CuratorOutgoingSubmissions userId={userId} data-sentry-element="CuratorOutgoingSubmissions" data-sentry-source-file="UserAnalytics.jsx" />

      <UserArtists userId={userId} data-sentry-element="UserArtists" data-sentry-source-file="UserAnalytics.jsx" />
    </div>;
}
export default UserAnalytics;