import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { matchfyApi } from '@/api';
import ContactForm from '@/components/ContactForm/ContactForm';
import Alert from '@/components/ui/Alert';
import Button from '@/components/ui/Button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/Dialog';
import { CONTACT_TYPES } from '@/data';
import { useSession, useTranslations } from '@/hooks';
import { toast } from '@/lib';
import { isPro } from '@/utils';
export function ProRequestForm({
  message,
  useDialog = true,
  onClose
}) {
  const [showContactForm, setShowContactForm] = useState(false);
  const {
    data: session
  } = useSession();
  const {
    user
  } = session || {};
  const t = useTranslations();
  const {
    mutate: sendContactRequest
  } = useMutation({
    mutationFn: data => matchfyApi.sendContactRequest({
      formData: data,
      type: CONTACT_TYPES.PRO
    })
  });
  const handleSubmit = data => {
    try {
      sendContactRequest(data);
      toast('success', t('feedback.success.contact_form.sent'));
      if (useDialog) {
        setShowContactForm(false);
      } else {
        onClose?.();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const contactForm = <ContactForm submitBtnVariant="blue" messageLabel={t('banner.pro.form.message.label')} messagePlaceholder={t('banner.pro.form.message.placeholder')} user={user} onSubmit={handleSubmit} />;
  return <>
      {!isPro(user?.type) && useDialog && <Alert inline variant="blue" size="sm" className="py-2 pl-4 pr-2 mb-6" cta={<Button size="xs" icon="ArrowRight" onClick={() => setShowContactForm(true)} variant="blue" className="w-full md:w-auto">
              {t('banner.pro.actions.become_pro')}
            </Button>}>
          <p className="pr-4 text-sm">
            {message}
          </p>
        </Alert>}

      {useDialog ? <Dialog open={showContactForm} onOpenChange={setShowContactForm}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>
                {t('banner.pro.form.title')}
              </DialogTitle>
              <DialogDescription className="mt-3">
                {t('banner.pro.form.description')}
              </DialogDescription>
            </DialogHeader>
            {contactForm}
          </DialogContent>
        </Dialog> : contactForm}
    </>;
}
export default ProRequestForm;