'use client';

import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useState, Fragment, useCallback, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { matchfyApi } from '@/api';
import PlaylistTableItem from '@/components/Playlist/PlaylistTableItem';
import Badge from '@/components/ui/Badge';
import Empty from '@/components/ui/Empty';
import Icon from '@/components/ui/Icon';
import Spinner from '@/components/ui/Spinner';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/Tooltip/Tooltip';
import { PLAYLISTS_PER_PAGE, QUERIES } from '@/data';
import { useTranslations } from '@/hooks';
import { toast } from '@/lib';
import { usePlaylistStore } from '@/store';
function PlaylistsTable({
  userId,
  isUserVerified
}) {
  const [loadingWatch, setLoadingWatch] = useState(false);
  const [loadingEnabled, setLoadingEnabled] = useState(false);
  const {
    resetFeedback,
    success,
    error,
    toggleVerification,
    toggleWatch
  } = usePlaylistStore();
  const {
    ref,
    inView
  } = useInView({
    threshold: 0
  });
  const queryClient = useQueryClient();
  const queryKey = `${QUERIES.PLAYLIST.LIST}`;
  const t = useTranslations();
  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery({
    queryKey: [queryKey],
    queryFn: async params => {
      const res = await matchfyApi.getUserPlaylists({
        ...params,
        limit: PLAYLISTS_PER_PAGE
      });
      return res;
    },
    getNextPageParam: lastPage => {
      if (!lastPage?.nextPage) return;
      return lastPage.nextPage;
    }
  });
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);
  useEffect(() => {
    if (success) {
      toast('success', t(success));
    } else if (error) {
      toast('error', t(error));
    }
    resetFeedback();
  }, [success, error]);
  const updatePlaylistData = useCallback((playlistId, updatedFields) => {
    queryClient.setQueryData([queryKey], oldData => {
      if (!oldData) return oldData;
      return {
        ...oldData,
        pages: oldData.pages.map(page => ({
          ...page,
          data: page.data.map(playlist => playlist.playlistId === playlistId ? {
            ...playlist,
            ...updatedFields
          } : playlist)
        }))
      };
    });
  }, [queryClient, queryKey]);
  const handleToggleVerification = useCallback(async (playlistId, newEnabledState) => {
    setLoadingEnabled(playlistId);
    try {
      await toggleVerification(playlistId, newEnabledState);
      updatePlaylistData(playlistId, {
        enabled: newEnabledState
      });
    } catch {
      updatePlaylistData(playlistId, {
        enabled: !newEnabledState
      });
    } finally {
      setLoadingEnabled(false);
    }
  }, [toggleVerification, updatePlaylistData]);
  const handleToggleWatch = useCallback(async (playlistId, newWatchState) => {
    setLoadingWatch(playlistId);
    try {
      await toggleWatch(playlistId, newWatchState);
      updatePlaylistData(playlistId, {
        watch: newWatchState
      });
    } catch {
      updatePlaylistData(playlistId, {
        watch: !newWatchState
      });
    } finally {
      setLoadingWatch(false);
    }
  }, [toggleWatch, updatePlaylistData]);
  if (isLoading) return <Spinner />;
  const userPlaylists = data?.pages?.map(page => page.data).flat() || [];
  return <div className="table-responsive" data-sentry-component="PlaylistsTable" data-sentry-source-file="PlaylistsTable.jsx">
      <table className="table">
        <thead>
          <tr>
            <th>Playlist</th>
            <th>
              <Tooltip data-sentry-element="Tooltip" data-sentry-source-file="PlaylistsTable.jsx">
                <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="PlaylistsTable.jsx">
                  <div className="flex items-center">
                    {t('playlist.table.columns.auto.label')}
                    <Badge label="AI" icon="Sparkles" variant="blue-light" className="ml-2" data-sentry-element="Badge" data-sentry-source-file="PlaylistsTable.jsx" />
                  </div>
                </TooltipTrigger>
                <TooltipContent className="max-w-[280px]" side="bottom" data-sentry-element="TooltipContent" data-sentry-source-file="PlaylistsTable.jsx">
                  <span>{t('playlist.table.columns.auto.tooltip')}</span>
                </TooltipContent>
              </Tooltip>
            </th>
            <th>
              <Tooltip data-sentry-element="Tooltip" data-sentry-source-file="PlaylistsTable.jsx">
                <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="PlaylistsTable.jsx">
                  <div className="flex items-center">
                    {t('common.states.enabled', {
                    gender: 'female'
                  })}
                    <Icon name="Info" className="ml-2" data-sentry-element="Icon" data-sentry-source-file="PlaylistsTable.jsx" />
                  </div>
                </TooltipTrigger>
                <TooltipContent className="max-w-[280px]" side="bottom" data-sentry-element="TooltipContent" data-sentry-source-file="PlaylistsTable.jsx">
                  <span>{t('playlist.table.columns.verified.tooltip')}</span>
                </TooltipContent>
              </Tooltip>
            </th>
            <th>
              <div className="flex items-center">
                {t('playlist.table.columns.watch.label')}
                <Icon name="Info" className="ml-2" data-sentry-element="Icon" data-sentry-source-file="PlaylistsTable.jsx" />
              </div>
            </th>
            <th>
              <Tooltip data-sentry-element="Tooltip" data-sentry-source-file="PlaylistsTable.jsx">
                <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="PlaylistsTable.jsx">
                  <div className="flex items-center">
                    {t('playlist.table.columns.submissions.label')}
                    <Icon name="Info" className="ml-2" data-sentry-element="Icon" data-sentry-source-file="PlaylistsTable.jsx" />
                  </div>
                </TooltipTrigger>
                <TooltipContent className="max-w-[280px]" side="bottom" data-sentry-element="TooltipContent" data-sentry-source-file="PlaylistsTable.jsx">
                  <span>{t('playlist.table.columns.submissions.tooltip')}</span>
                </TooltipContent>
              </Tooltip>
            </th>
            <th className="text-right">
              {t('common.actions.title')}
            </th>
          </tr>
        </thead>

        {!isLoading && userPlaylists?.length > 0 ? <tbody>
            {userPlaylists.map((playlist, playlistIndex) => <Fragment key={`playlist-${playlist.playlistId}-${playlistIndex}`}>
                <PlaylistTableItem index={playlistIndex + 1} playlist={playlist} isUserVerified={isUserVerified} userId={userId} updatePlaylistData={updatePlaylistData} loadingEnabled={loadingEnabled} loadingWatch={loadingWatch} toggleVerification={() => handleToggleVerification(playlist.playlistId, !playlist.enabled)} toggleWatch={() => handleToggleWatch(playlist.playlistId, !playlist.watch)} />
              </Fragment>)}
          </tbody> : <tbody>
            <tr>
              <td colSpan={7}>
                <Empty ghost horizontal className="my-0" message={t('empty_state.content.playlists.no_data')} />
              </td>
            </tr>
          </tbody>}
      </table>

      {(isLoading || isFetchingNextPage) && <Spinner />}

      <div ref={ref} />
    </div>;
}
export default PlaylistsTable;