'use client';

import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { matchfyApi } from '@/api';
import Genres from '@/components/Genres';
import Alert from '@/components/ui/Alert';
import Badge from '@/components/ui/Badge';
import Button from '@/components/ui/Button';
import { MAXIMUM_GENRES_PER_PLAYLIST } from '@/data';
import { QUERIES } from '@/data/queries';
import { useTranslations } from '@/hooks';
import { usePlaylistStore } from '@/store';
import { cn } from '@/utils';
export const PlaylistSelectGenres = ({
  className,
  closeModalFn
}) => {
  const t = useTranslations();
  const [genres, setGenres] = useState([]);
  const {
    currentPlaylist,
    updatePlaylistGenres,
    currentPlaylistId
  } = usePlaylistStore();
  const handleGenresUpdate = async () => {
    try {
      await updatePlaylistGenres(genres);
      closeModalFn();
    } catch (error) {
      console.log(error);
    }
  };
  const {
    data: playlistGenres
  } = useQuery({
    queryKey: [QUERIES.PLAYLIST.GENRES, currentPlaylistId],
    queryFn: () => matchfyApi.getPlaylistGenres(currentPlaylistId)
  });
  useEffect(() => {
    if (playlistGenres) {
      setGenres(playlistGenres.genres || []);
    }
  }, [playlistGenres]);
  const classes = cn({
    'field-input-container': true,
    'field-icon-before': true,
    [className]: className
  });
  return <div className={classes} data-sentry-component="PlaylistSelectGenres" data-sentry-source-file="PlaylistSelectGenres.jsx">
      {currentPlaylist?.genres.length === 0 && <Alert className="mb-4">
          {t('playlist.settings.genres.disclaimer', {
        limit: MAXIMUM_GENRES_PER_PLAYLIST
      })}
        </Alert>}

      <Genres label={t('playlist.settings.genres.select')} className="w-full" genres={genres} maxLimit={MAXIMUM_GENRES_PER_PLAYLIST} onGenreSelect={genres => setGenres(genres)} data-sentry-element="Genres" data-sentry-source-file="PlaylistSelectGenres.jsx" />

      {currentPlaylist && currentPlaylist?.tags?.length > 0 && <div className="flex flex-col w-full gap-2 mt-3">
          <span>
            {t('playlist.settings.genres.suggested')}
          </span>

          <div className="tags">
            {currentPlaylist?.tags.map(genre => {
          return <Badge key={genre} variant="primary-light" label={genre} className="cursor-pointer" onClick={() => setGenres([...genres, genre])} />;
        })}
          </div>
        </div>}

      <Button className="w-full mt-3" disabled={genres.length === 0} onClick={handleGenresUpdate} data-sentry-element="Button" data-sentry-source-file="PlaylistSelectGenres.jsx">
        {t('playlist.settings.genres.save')}
      </Button>
    </div>;
};
export default PlaylistSelectGenres;