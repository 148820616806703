import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import Button from '@/components/ui/Button';
import Icon from '@/components/ui/Icon';
import { getNotificationsProps } from '@/data';
import { useLocale, useTranslations } from '@/hooks';
import { cn } from '@/utils';
import 'dayjs/locale/es';
import 'dayjs/locale/it';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
export const NotificationItem = ({
  notification
}) => {
  const {
    content,
    type
  } = notification;
  const t = useTranslations();
  const {
    locale
  } = useLocale();
  dayjs.locale(locale);
  const getTime = () => {
    return dayjs(notification.createdAt).locale(locale).fromNow();
  };
  const NotificationCta = () => {
    if (notification.metadata && notification.metadata.cta) {
      return <div className="notification-item-cta">
          <Button size="sm" icon="Eye" variant="outline-primary" label={t(notification.metadata.cta.label)} className="notification-item-cta" href={notification.metadata.cta.url} />
        </div>;
    }
  };
  return <div className="notification-item" data-sentry-component="NotificationItem" data-sentry-source-file="NotificationItem.jsx">
      <div className="notification-item-inner">
        <div className={cn('notification-item-icon', {
        [`icon-${getNotificationsProps(type).color}`]: true
      })}>
          <Icon name={getNotificationsProps(type).icon} data-sentry-element="Icon" data-sentry-source-file="NotificationItem.jsx" />
        </div>
        <div className="notification-item-content">
          <div dangerouslySetInnerHTML={{
          __html: content
        }} />
          <div className="mt-1 notification-item-time">{getTime()}</div>
        </div>
      </div>
      <NotificationCta data-sentry-element="NotificationCta" data-sentry-source-file="NotificationItem.jsx" />
    </div>;
};
export default NotificationItem;