import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { matchfyApi } from '@/api';
import { ProFeatureAlert } from '@/components/ProFeature';
import { Accordion, AccordionItem } from '@/components/ui/Accordion';
import { DashboardCard } from '@/components/ui/Card';
import { PieChart } from '@/components/ui/Chart';
import Empty from '@/components/ui/Empty';
import Spinner from '@/components/ui/Spinner';
import { CURATOR_STATS_DAYS_RANGE, QUERIES } from '@/data';
import { useTranslations, useSession } from '@/hooks';
import { cn, isFree } from '@/utils';
export function CuratorIncomingSubmissions({
  userId,
  className
}) {
  const startDate = dayjs().subtract(CURATOR_STATS_DAYS_RANGE, 'days').toDate();
  const endDate = dayjs().toDate();
  const t = useTranslations();
  const {
    data: session
  } = useSession();
  const {
    user
  } = session || {};
  const isOwner = user.id === userId;
  const {
    data,
    isLoading
  } = useQuery({
    queryKey: [QUERIES.CURATOR.COUNTERS.INCOMING, userId],
    queryFn: () => matchfyApi.getCuratorIncomingCounters(userId, {
      startDate,
      endDate
    })
  });
  const getStateColor = state => {
    switch (state) {
      case 'pending':
        return '#fb923c';
      case 'accepted_manual':
        return '#1DB854';
      case 'accepted_auto':
        return '#127636';
      case 'rejected_manual':
        return '#f44336';
      case 'rejected_auto':
        return '#a80804';
      default:
        return '#000000';
    }
  };
  const contentClasses = cn(className);
  const calculatePercentage = (value, total) => {
    if (total === 0 || value === 0) {
      return 0;
    }
    return (value / total * 100).toFixed(2);
  };
  return <Accordion data-sentry-element="Accordion" data-sentry-component="CuratorIncomingSubmissions" data-sentry-source-file="CuratorIncomingSubmissions.jsx">
      <AccordionItem id="curator-incoming-submissions" icon="ArrowLeftToLine" label={`${t('curator.widgets.incoming_submissions')} (${t('common.time.latest_x_days', {
      days: CURATOR_STATS_DAYS_RANGE
    })})`} data-sentry-element="AccordionItem" data-sentry-source-file="CuratorIncomingSubmissions.jsx">
        <div className={contentClasses}>
          {isLoading && <div className="flex items-center justify-center">
              <Spinner />
            </div>}

          {isFree(user) && !isOwner && <ProFeatureAlert className="mb-6" />}

          {!isLoading && data && <div className={cn({
          "grid grid-cols-1 gap-4 md:grid-cols-2": true,
          "blur-[6px] pointer-events-none": isFree(user) && !isOwner
        })}>
              <PieChart applyColorScale={false} chartData={Object.keys(data).filter(item => item !== 'total' && data[item] > 0).map(item => {
            return {
              id: item,
              label: t(`common.states.${item}`, {
                count: data[item]
              }),
              value: data[item],
              color: getStateColor(item)
            };
          })} margin={{
            top: 20,
            right: 10,
            bottom: 100,
            left: 10
          }} enableArcLabels={false} innerRadius={0.75} enableArcLinkLabels={false} useDataColors={true} legendPosition="bottom" legendClasses="bottom-6" mobileClasses="h-[400px]" t={t} />

              <div className="flex items-center justify-center">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                  <DashboardCard label={t('curator.dashboard.metrics.submissions.total_submissions.label')} value={data.total} />

                  <DashboardCard label={t('curator.dashboard.metrics.submissions.pending.label')} value={data.pending} />

                  <DashboardCard label={t('curator.dashboard.metrics.submissions.accepted.label')} value={data.accepted_auto + data.accepted_manual} />

                  <DashboardCard label={t('curator.dashboard.metrics.submissions.rejected.label')} value={data.rejected_auto + data.rejected_manual} />

                  <DashboardCard label={t('curator.dashboard.metrics.submissions.accepted.label')} value={calculatePercentage(data.accepted_auto, data.total)} text={t('curator.dashboard.metrics.submissions.handled_by.label', {
                entity: 'ai'
              })} />

                  <DashboardCard label={t('curator.dashboard.metrics.submissions.rejected.label')} value={calculatePercentage(data.rejected_auto, data.total)} type="percentage" text={t('curator.dashboard.metrics.submissions.handled_by.label', {
                entity: 'ai'
              })} />

                  <DashboardCard label={t('curator.dashboard.metrics.submissions.accepted.label')} value={calculatePercentage(data.accepted_manual, data.total)} type="percentage" text={t('curator.dashboard.metrics.submissions.handled_by.label', {
                entity: 'curator'
              })} />

                  <DashboardCard label={t('curator.dashboard.metrics.submissions.rejected.label')} value={calculatePercentage(data.rejected_manual, data.total)} type="percentage" text={t('curator.dashboard.metrics.submissions.handled_by.label', {
                entity: 'curator'
              })} />
                </div>
              </div>
            </div>}

          {!isLoading && (!data || data?.length === 0) && <Empty horizontal className="w-full py-10 my-0 border-0" />}
        </div>
      </AccordionItem>
    </Accordion>;
}
export default CuratorIncomingSubmissions;