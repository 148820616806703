import { useQuery } from '@tanstack/react-query';
import { matchfyApi } from '@/api';
import Button from '@/components/ui/Button';
import Icon from '@/components/ui/Icon';
import { NotificationBadge } from '@/components/ui/Notification';
import { QUERIES, ROUTES } from '@/data';
import { cn } from '@/utils';
export const ChatNavItem = ({
  className
}) => {
  const classes = cn('relative', className);
  const {
    data
  } = useQuery({
    queryKey: [QUERIES.CHAT.COUNT],
    queryFn: () => matchfyApi.getChatNotificationCount()
  });
  return <div data-sentry-component="ChatNavItem" data-sentry-source-file="ChatNavItem.jsx">
      <Button iconVariant className={classes} variant="ghost" href={ROUTES.CHAT} aria-label="Chat" data-sentry-element="Button" data-sentry-source-file="ChatNavItem.jsx">
        <Icon name="MessageCircle" data-sentry-element="Icon" data-sentry-source-file="ChatNavItem.jsx" />
        <NotificationBadge hideCount count={data?.totalUnreadCount > 0 ? data?.totalUnreadCount : 0} data-sentry-element="NotificationBadge" data-sentry-source-file="ChatNavItem.jsx" />
      </Button>
    </div>;
};
export default ChatNavItem;