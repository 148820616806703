import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { CuratorCard } from '@/components/Curator';
import Button from '@/components/ui/Button';
import SectionTitle from '@/components/ui/SectionTitle/SectionTitle';
import Spinner from '@/components/ui/Spinner';
import { useSession, useTranslations } from '@/hooks';
import { cn, isPro, isStar } from '@/utils';
const CuratorsGrid = ({
  queryKey,
  fetchFn,
  title,
  type,
  subtitle,
  badge,
  enabled = true,
  limit,
  contentClasses,
  gridClassName = "grid grid-cols-2 gap-4 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:gap-8",
  showTitle = true,
  showWhenEmpty = false,
  extraProps = {},
  ctaLink,
  ctaLabel,
  ctaProps = {}
}) => {
  const {
    data: session
  } = useSession();
  const user = session?.user || {};
  const t = useTranslations();
  const {
    ref,
    inView
  } = useInView({
    threshold: 0
  });
  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery({
    queryKey: [queryKey],
    queryFn: async params => {
      const response = await fetchFn({
        ...params,
        type
      });
      return response;
    },
    getNextPageParam: lastPage => {
      if (!lastPage?.nextPage) return;
      return lastPage.nextPage;
    },
    enabled
  });
  useEffect(() => {
    if (inView && hasNextPage && (!limit || (data?.pages?.flatMap(page => page.data)?.length || 0) < limit)) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage, limit, data?.pages]);
  const curators = data?.pages?.flatMap(page => page.data) || [];
  const limitedCurators = limit ? curators.slice(0, limit) : curators;
  if (!showWhenEmpty && !isLoading && limitedCurators.length === 0) {
    return null;
  }
  const renderCurators = () => {
    return limitedCurators.map(curator => {
      if (!curator) return null;
      return <CuratorCard key={curator?.id} curator={curator} isOwner={user?.spotifyId === curator?.id} pro={isPro(curator?.type)} star={isStar(curator?.type)} {...extraProps} />;
    });
  };
  return <>
      {showTitle && (isLoading || !isLoading && limitedCurators.length > 0) && <SectionTitle title={title} tag="h3" subtitle={subtitle} subHeadingClasses="text-lg" badge={badge} />}

      {isLoading && <Spinner />}

      {!isLoading && limitedCurators.length > 0 && <div className={cn(contentClasses)}>
          <div className={gridClassName}>
            {renderCurators()}
          </div>

          <div ref={ref} />

          {isFetching && <Spinner />}

          {ctaLink && ctaLabel && <div className="flex justify-start mt-8">
              <Button href={ctaLink} {...ctaProps}>
                {ctaLabel}
              </Button>
            </div>}
        </div>}
    </>;
};
export default CuratorsGrid;