import { ChatStart } from '@/components/Chat';
import { MatchAudioFeatures } from '@/components/Match';
import Badge, { BadgesList, CuratorScoreBadge } from '@/components/ui/Badge';
import Icon from '@/components/ui/Icon';
import { generateRoute, ROUTES } from '@/data';
import { useLocale, useTranslations } from '@/hooks';
import { cn, humanReadableDuration, buildSpotifyPlaylistUrl } from '@/utils';
function PlaylistOverview({
  children,
  className,
  curatorName,
  curatorId,
  curator,
  description = '',
  followers,
  genres,
  hideTitle = false,
  id,
  image,
  name,
  score,
  values,
  wrapperClassName
}) {
  const t = useTranslations();
  const {
    locale
  } = useLocale();
  const classes = cn({
    'playlist-overview': true,
    'grid-cols-1': !values,
    [className]: className
  });
  const wrapperClasses = cn({
    'playlist-info-wrapper': true,
    [wrapperClassName]: wrapperClassName
  });
  return <div key={id} className={classes} data-sentry-component="PlaylistOverview" data-sentry-source-file="PlaylistOverview.jsx">
      <div className={wrapperClasses}>
        {image && <div className="playlist-cover" style={{
        backgroundImage: `url("${image}")`
      }} />}

        <div className="playlist-info">
          <div className="playlist-meta">
            {score ? <Badge variant="gradient" icon="Star" label="Match" value={`${score}%`} /> : null}

            {curatorId && curatorName && <Badge variant="light" icon="CircleUserRound" label={curatorName ?? curatorId} labelHref={generateRoute(ROUTES.CURATOR_DETAIL, {
            id: curatorId
          })} value={<ChatStart inline chatUserId={curatorId} />} />}

            {followers ? <Badge variant="light" icon="UsersRound" label={t('common.metrics.followers')} value={followers} /> : null}
          </div>

          {!hideTitle && <h2>{name}</h2>}

          {curator && <div className="flex flex-wrap gap-2">
              <CuratorScoreBadge label={t('curator.score.responseRate')} value={curator?.responseRate > 0 ? `${(curator?.responseRate * 100).toFixed(0)}%` : null} icon="MessageCircle" className="capitalize" variant="light" fallbackValue={t('curator.score.noData')} />

              <CuratorScoreBadge label={t('curator.score.timeMean')} value={curator?.timeMean > 0 ? humanReadableDuration({
            value: curator?.timeMean,
            locale,
            t
          }) : null} icon="Clock" className="capitalize" variant="light" fallbackValue={t('curator.score.noData')} />
            </div>}

          {genres && genres.length > 0 && <BadgesList tags={genres} maxVisible={5} className="mt-2" />}

          {description && <p className="mt-2" dangerouslySetInnerHTML={{
          __html: description
        }} />}

          {children}

          {id && <a href={buildSpotifyPlaylistUrl(id)} className="mt-3 button button-primary-light" target="_blank" rel="noopener noreferrer">
              <Icon className="mr-2" type="brand" name="Spotify" />
              {t('common.actions.open_in_spotify')}
            </a>}
        </div>
      </div>

      {values ? <MatchAudioFeatures values={values} /> : null}
    </div>;
}
export default PlaylistOverview;