import { useState, useEffect } from 'react';
import { ChatItem } from '@/components/Chat';
import Empty from '@/components/ui/Empty';
import { CHAT_FILTERS, CHAT_TYPES } from '@/data';
import { useTranslations } from '@/hooks';
import { useChatStore } from '@/store';
export const ChatActive = ({
  activeSid,
  showArchive,
  handleChatSelect,
  chatList,
  isLoading
}) => {
  const [filteredChatList, setFilteredChatList] = useState([]);
  const {
    filter
  } = useChatStore();
  const t = useTranslations();
  useEffect(() => {
    const filtered = chatList.filter(chat => {
      if (filter === CHAT_FILTERS.UNREAD && chat.unreadCount === 0) return false;
      if (filter === CHAT_FILTERS.PRO && chat.type !== CHAT_TYPES.PRO) return false;
      return true;
    });
    setFilteredChatList(filtered);
  }, [chatList, filter]);
  return <>
      {!showArchive && filteredChatList?.length > 0 && filteredChatList.map(chat => <ChatItem key={chat.sid} active={activeSid === chat.sid} type={chat.type} chat={chat} onClick={handleChatSelect} />)}

      {!showArchive && !isLoading && filteredChatList?.length === 0 && <Empty ghost size="sm" className="h-full m-0" message={t('empty_state.chat.no_chats')} icon="Inbox" />}
    </>;
};
export default ChatActive;