import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { matchfyApi } from '@/api';
import { PushProCashOut } from '@/components/PushPro';
import Badge from '@/components/ui/Badge';
import Button from '@/components/ui/Button';
import { CounterFlow } from '@/components/ui/Counter';
import Date from '@/components/ui/Date';
import Emoji from '@/components/ui/Emoji';
import Spinner from '@/components/ui/Spinner';
import { DEV_FLAGS, QUERIES } from '@/data';
import { useSession, useTranslations } from '@/hooks';
import { useDevStore } from '@/store';
export const PushProWallet = () => {
  const [isCashOutModalOpen, setIsCashOutModalOpen] = useState(false);
  const {
    isDev,
    hasFlag
  } = useDevStore();
  const {
    data: session
  } = useSession();
  const user = session?.user || {};
  const t = useTranslations();
  const {
    data,
    isLoading
  } = useQuery({
    queryKey: [QUERIES.PUSH_PRO.DASHBOARD],
    queryFn: () => matchfyApi.getPushProDashboard()
  });
  const handleCashOut = () => {
    if (data?.hasDisputes) {
      return;
    }
    setIsCashOutModalOpen(true);
  };
  const forceCashOut = isDev && hasFlag(DEV_FLAGS.PUSH_PRO);
  const isCashOutEnabled = data?.isCashOutPeriod || forceCashOut;
  return <div className="gap-4 p-4 rounded-md card border-outline" data-sentry-component="PushProWallet" data-sentry-source-file="PushProWallet.jsx">
      <div>
        <h4 className="mb-2 font-sans text-lg font-normal text-muted-foreground">
          {t('push_pro.wallet.available_balance')}
        </h4>

        <div className="flex items-center gap-2 mb-4">
          <span className="text-4xl font-bold">
            {isLoading && <Spinner inline />}

            {!isLoading && <CounterFlow value={data?.cashOutAmount || 0} type="currency" />}
          </span>
        </div>

        <div className="flex flex-wrap items-start gap-x-8 gap-y-2">
          <Badge size="md" className="p-0" label={t('push_pro.wallet.gems_collected', {
          count: data?.cashOutAmount || 0
        })} emoji={<Emoji symbol="💎" label="Gem" />} labelClasses="gap-2 tracking-tight" variant="text" data-sentry-element="Badge" data-sentry-source-file="PushProWallet.jsx" />

          {!isCashOutEnabled && <Badge size="md" label={t('push_pro.wallet.next_cash_out_period')} className="p-0" value={<Date value={data?.nextCashOutDate} />} labelClasses="gap-2 tracking-tight" variant="text" icon="Calendar" />}
        </div>
      </div>

      <div className="mt-auto">
        {isCashOutEnabled && <Button label={t('push_pro.wallet.cash_out_button')} icon="HandCoins" className="w-full mt-auto" variant="gradient-secondary" disabled={data?.cashOutAmount <= 0 || data?.hasDisputes} onClick={handleCashOut} />}

        {data?.hasDisputes && <Badge size="md" label={t('push_pro.wallet.cash_out_unavailable_disputes')} icon="TriangleAlert" variant="red-light" className="mt-2" labelClasses="gap-2 tracking-tight" />}
      </div>

      <PushProCashOut isOpen={isCashOutModalOpen} onOpenChange={setIsCashOutModalOpen} cashOutAmount={data?.cashOutAmount} user={user} data-sentry-element="PushProCashOut" data-sentry-source-file="PushProWallet.jsx" />
    </div>;
};
export default PushProWallet;