'use client';

import { AnimatePresence, motion } from "motion/react";
import { nanoid } from 'nanoid';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Icon from '@/components/ui/Icon';
import { cn } from '@/utils';
const transition = {
  type: 'tween',
  ease: 'easeOut',
  duration: 0.15
};
export const Tabs = ({
  tabs,
  selectedTabIndex,
  setSelectedTab
}) => {
  const [buttonRefs, setButtonRefs] = useState([]);
  useEffect(() => {
    setButtonRefs(prev => prev.slice(0, tabs.length));
  }, [tabs.length]);
  const navRef = useRef(null);
  const navRect = navRef.current?.getBoundingClientRect();
  const selectedRect = buttonRefs[selectedTabIndex]?.getBoundingClientRect();
  const [hoveredTabIndex, setHoveredTabIndex] = useState(null);
  const tabIndex = hoveredTabIndex !== null ? hoveredTabIndex : -1;
  const hoveredRect = buttonRefs[tabIndex]?.getBoundingClientRect();
  return <nav ref={navRef} className="tab-header" onPointerLeave={() => setHoveredTabIndex(null)} data-sentry-component="Tabs" data-sentry-source-file="Tabs.jsx">
      {tabs.map((item, i) => {
      return <motion.button key={nanoid()} className={cn('button', {
        hovered: hoveredTabIndex === i || selectedTabIndex === i,
        active: selectedTabIndex === i
      })} ref={el => buttonRefs[i] = el} onPointerEnter={!isMobile ? () => {
        setHoveredTabIndex(i);
      } : null} onFocus={!isMobile ? () => {
        setHoveredTabIndex(i);
      } : null} onClick={() => {
        setSelectedTab([i, i > selectedTabIndex ? 1 : -1]);
      }}>
            {item.icon && <Icon type={item.iconType} name={item.icon} />}
            <span>{item.label}</span>
          </motion.button>;
    })}

      <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="Tabs.jsx">
        {hoveredRect && navRect && <motion.div key="hover" className="tab-hover" initial={{
        x: hoveredRect.left - navRect.left,
        y: hoveredRect.top - navRect.top,
        width: hoveredRect.width,
        height: hoveredRect.height,
        opacity: 0
      }} animate={{
        x: hoveredRect.left - navRect.left,
        y: hoveredRect.top - navRect.top,
        width: hoveredRect.width,
        height: hoveredRect.height,
        opacity: 1
      }} exit={{
        x: hoveredRect.left - navRect.left,
        y: hoveredRect.top - navRect.top,
        width: hoveredRect.width,
        height: hoveredRect.height,
        opacity: 0
      }} transition={transition} />}
      </AnimatePresence>
      {selectedRect && navRect && <motion.div className="tab-indicator" initial={false} animate={{
      width: selectedRect.width * 0.8,
      x: `calc(${selectedRect.left - navRect.left}px + 10%)`,
      opacity: 1
    }} transition={transition} />}
    </nav>;
};
export const TabContent = ({
  children,
  className,
  selectedTabIndex,
  direction
}) => {
  const tabContentClasses = cn({
    'tab-content': true,
    [className]: className
  });
  return <AnimatePresence mode="wait" custom={direction} data-sentry-element="AnimatePresence" data-sentry-component="TabContent" data-sentry-source-file="Tabs.jsx">
      <motion.div key={selectedTabIndex} variants={{
      enter: dir => ({
        opacity: 0,
        x: dir > 0 ? 100 : -100,
        scale: 0.8
      }),
      center: {
        opacity: 1,
        x: 0,
        scale: 1,
        rotate: 0
      },
      exit: dir => ({
        opacity: 0,
        x: dir > 0 ? -100 : 100,
        scale: 0.8,
        position: 'absolute'
      })
    }} transition={{
      duration: 0.25
    }} initial="enter" animate="center" exit="exit" custom={direction} className={tabContentClasses} data-sentry-element="unknown" data-sentry-source-file="Tabs.jsx">
        {children}
      </motion.div>
    </AnimatePresence>;
};