'use client';

import React, { useEffect, useState } from 'react';
import PlaylistOverview from './PlaylistOverview';
import PushPro from '@/components/PushPro';
import PushToPlaylist from '@/components/PushToPlaylist';
import { TrackDetails } from '@/components/Track';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/Dialog';
import { TabContent, Tabs, useTabs } from '@/components/ui/Tabs';
import { UserAnalytics } from '@/components/User';
import { useSession, useTranslations } from '@/hooks';
import { useMatchStore, usePushToPlaylistStore } from '@/store';
import { isPro } from '@/utils';
const PlaylistModal = ({
  playlist,
  curator,
  isAlreadyPushed,
  score,
  checksum,
  values,
  open,
  onClose
}) => {
  const [matchScore, setMatchScore] = useState(score);
  const [matchValues, setMatchValues] = useState(values);
  const [matchChecksum, setMatchChecksum] = useState(checksum);
  const t = useTranslations();
  const {
    getMatch,
    track,
    trackId
  } = useMatchStore();
  const {
    checkPushedPlaylist
  } = usePushToPlaylistStore();
  const isPushed = isAlreadyPushed || checkPushedPlaylist(playlist);
  const {
    data: session
  } = useSession();
  const {
    user
  } = session || {};

  // const data = useQuery({
  //   queryKey: ['fakeScore', playlist.id],
  //   queryFn: () => matchfyApi.getPlaylistFakeScore(playlist.id),
  //   enabled: open && !!playlist.id,
  // })

  useEffect(() => {
    const fetchMatchData = async () => {
      if (!values || !score || !playlist.id || !checksum) {
        const matchData = await getMatch(playlist.id, trackId);
        setMatchScore(matchData?.score);
        setMatchValues(matchData?.values);
        setMatchChecksum(matchData?.checksum);
      }
    };
    fetchMatchData();
  }, [checksum, values, score]);
  const tabsData = {
    tabs: [{
      id: 'push-to-playlist',
      icon: 'ArrowRight',
      label: t('match.spotify.submit.title'),
      children: <div className="flex flex-col items-start gap-4">
            <PushToPlaylist playlistId={playlist?.id} playlist={playlist} isPushed={isPushed} disabled={!matchChecksum || !matchScore || !matchValues} score={matchScore} checksum={matchChecksum} curatorId={curator?.id} curatorName={curator?.spotifyUsername} track={track} trackId={trackId} userName={user?.spotifyUsername} userContacts={user?.contacts} playlistPushEnabled={curator?.playlistPushEnabled} />
            {curator?.pushProFeedbackPrice && isPro(curator?.type) ? <PushPro className={!curator?.playlistPushEnabled ? 'mt-4' : ''} curatorId={curator?.spotifyId} curatorName={curator?.spotifyUsername ?? curator?.spotifyId} curatorAvatar={curator?.avatar} curatorType={curator?.type ?? USER_TYPES.USER} curatorRoles={curator?.contacts?.roles} feedbackPrice={curator?.pushProFeedbackPrice} /> : null}
          </div>
    }, {
      id: 'tracks',
      icon: 'ListMusic',
      label: t('playlist.table.columns.track_list'),
      children: <TrackDetails playlistId={playlist?.id} />
    }, {
      id: 'curator-analytics',
      icon: 'ChartPie',
      label: t('common.labels.analytics'),
      children: <UserAnalytics userId={curator?.id} />
    }
    // {
    //   'playlist-check',
    //   icon: 'Check',
    //   label: t('navigation.playlist_check'),
    //   children: <PlaylistAnalysis playlistId={playlist?.id} latest hideOverview />,
    // },
    ],
    initialTabId: 'push-to-playlist'
  };
  const tabs = useTabs(tabsData);
  return <Dialog open={open} onOpenChange={onClose} data-sentry-element="Dialog" data-sentry-component="PlaylistModal" data-sentry-source-file="PlaylistModal.jsx">
      <DialogContent className="sm:max-w-[1200px]" data-sentry-element="DialogContent" data-sentry-source-file="PlaylistModal.jsx">
        <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="PlaylistModal.jsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="PlaylistModal.jsx">{playlist.name}</DialogTitle>
        </DialogHeader>

        <PlaylistOverview hideTitle id={playlist?.id} name={playlist?.name} image={playlist?.image} followers={playlist?.followers} description={playlist?.description} genres={playlist?.genres} score={matchScore} curator={curator} curatorName={curator?.spotifyUsername ?? curator?.id} curatorId={curator?.id} values={matchValues} data-sentry-element="PlaylistOverview" data-sentry-source-file="PlaylistModal.jsx" />

        <div className="pt-4">
          <Tabs inline showIndicator={false} variant="toggle" {...tabs.tabProps} data-sentry-element="Tabs" data-sentry-source-file="PlaylistModal.jsx" />

          <TabContent {...tabs.contentProps} className="px-0 pb-0" data-sentry-element="TabContent" data-sentry-source-file="PlaylistModal.jsx">
            {tabs.selectedTab.children}
          </TabContent>
        </div>
      </DialogContent>
    </Dialog>;
};
export default PlaylistModal;