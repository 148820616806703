'use client';

import { useTranslations, useLocale } from 'next-intl';
import React, { useEffect, useState, createContext, useContext } from 'react';
import Alert from '@/components/ui/Alert';
import Button from '@/components/ui/Button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/Dialog';
import { INTERCOM_CONFIG } from '@/data/intercom';
import { IUBENDA_CONFIG } from '@/data/iubenda';
import { loadScript } from '@/utils';
const ConsentContext = createContext();
export const useConsent = () => useContext(ConsentContext);
export const ConsentProvider = ({
  children
}) => {
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [consent, setConsent] = useState(false);
  const t = useTranslations();
  const {
    locale
  } = useLocale();
  const activateScripts = () => {
    try {
      if (window._iub && window._iub.cs && window._iub.cs.api && window._iub.cs.api.activateSnippets) {
        window._iub.cs.api.activateSnippets();
      }
    } catch (err) {
      console.error(err);
    }
  };
  const checkConsent = () => {
    if (window._iub && window._iub.cs) {
      const consent = window._iub.cs.api.isConsentGiven();
      if (consent) {
        activateScripts();
      }
      setConsent(consent);
    }
  };
  const getConsent = () => {
    if (window._iub && window._iub.cs) {
      return window._iub.cs;
    }
    return false;
  };
  const handleIntercomToggle = () => {
    checkConsent();
    if (consent) {
      window.Intercom('show');
    } else {
      setOpen(true);
    }
  };
  useEffect(() => {
    loadScript('//cdn.iubenda.com/cs/tcf/stub-v2.js', 'iubenda-tcf-stub-v2', true, true);
    loadScript('//cdn.iubenda.com/cs/iubenda_cs.js', 'iubenda-cs', true, true);
    if (locale) {
      IUBENDA_CONFIG.lang = locale;
    }
    window._iub = window._iub || [];
    window._iub.csConfiguration = IUBENDA_CONFIG;
    window._iub.csConfiguration.callback.onPreferenceExpressed = () => {
      checkConsent();
    };

    // Intercom Config
    window.intercomSettings = INTERCOM_CONFIG;
    setLoaded(true);
  }, []);
  if (!loaded) return null;
  return <ConsentContext.Provider value={{
    activateScripts,
    checkConsent,
    consent,
    getConsent,
    handleIntercomToggle
  }} data-sentry-element="unknown" data-sentry-component="ConsentProvider" data-sentry-source-file="ConsentProvider.jsx">
      <Dialog open={open} onOpenChange={setOpen} data-sentry-element="Dialog" data-sentry-source-file="ConsentProvider.jsx">
        <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="ConsentProvider.jsx">
          <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="ConsentProvider.jsx">
            <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="ConsentProvider.jsx">{t('consent.warning.title')}</DialogTitle>
          </DialogHeader>

          <div className="grid gap-4 py-4">
            <div className="flex flex-col gap-4">
              <div className="flex items-center h-20 rounded-full">
                <Alert variant="yellow" className="mb-3" data-sentry-element="Alert" data-sentry-source-file="ConsentProvider.jsx">
                  {t('consent.warning.alert')}
                </Alert>
              </div>

              <p className="text-sm text-muted-foreground">
                {t('consent.warning.body')}
              </p>
            </div>
          </div>

          <DialogFooter data-sentry-element="DialogFooter" data-sentry-source-file="ConsentProvider.jsx">
            <Button className="iubenda-cs-preferences-link" label={t('consent.cookie_preferences')} onClick={() => {
            window._iub.cs.api.showBanner();
            setOpen(false);
          }} data-sentry-element="Button" data-sentry-source-file="ConsentProvider.jsx" />
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {children}

    </ConsentContext.Provider>;
};
export default ConsentProvider;