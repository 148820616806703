import dayjs from 'dayjs';
import React from 'react';
import Badge from '@/components/ui/Badge';
import Image from '@/components/ui/Image';
import Placeholder from '@/components/ui/Placeholder';
import { useLocale, useTranslations } from '@/hooks';
import { cn } from '@/utils';
export const ChatItemPro = ({
  ref,
  chat,
  pushPro,
  onClick,
  active,
  className
}) => {
  const t = useTranslations();
  const {
    locale
  } = useLocale();
  const handleChatClick = () => {
    if (onClick) {
      onClick(chat);
    }
  };
  const getFriendlyName = () => {
    if (chat?.friendlyName) {
      return chat?.friendlyName;
    }
    return chat?.spotifyUsername || chat?.spotifyId;
  };
  const classes = cn({
    'chat-list-item': true,
    active: active,
    [className]: className
  });
  return <div ref={ref} className={classes} onClick={handleChatClick} data-sentry-component="ChatItemPro" data-sentry-source-file="ChatItemPro.jsx">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-3">
          {pushPro?.track && <Image src={pushPro.track?.image} alt={pushPro.track?.name} className="w-12 h-12 overflow-hidden rounded" placeholder={<Placeholder className="w-12 h-12 rounded" icon="Music" />} />}

          <div className="flex flex-col items-start">
            <span className="text-sm font-medium mb-[2px]">
              {pushPro?.track?.name || t('field.track.type.options.unreleased.label')}
            </span>

            <span className="flex items-center gap-2 text-xs font-normal opacity-90">
              {getFriendlyName()}
            </span>

            {chat?.dateUpdated && <small className="opacity-70">
                {dayjs(chat?.dateUpdated).locale(locale).fromNow()}
              </small>}
          </div>
        </div>

        {chat?.unreadCount > 0 && <Badge size="sm" variant="primary" label={chat.unreadCount} />}
      </div>
    </div>;
};
export default ChatItemPro;