import { DevFlag } from '@/components/Development';
import { DEV_FLAGS } from '@/data';
export default function DevMatchRank({
  curator,
  playlist,
  matchRank,
  score
}) {
  const rows = [{
    label: 'Curator Rank',
    value: curator?.rank?.toFixed(2) ?? '-'
  }, {
    label: 'Playlist Rank',
    value: playlist?.rank?.toFixed(2) ?? '-'
  }, {
    label: 'Match Rank',
    value: matchRank?.toFixed(2) ?? '-'
  }, {
    label: 'Score',
    value: score ? `${score.toFixed(0)}%` : '-'
  }];
  return <DevFlag flag={DEV_FLAGS.SHOW_MATCH_RANK} data-sentry-element="DevFlag" data-sentry-component="DevMatchRank" data-sentry-source-file="DevMatchRank.jsx">
      <pre className="absolute z-50 flex flex-col items-start p-2 text-xs text-gray-900 rounded-sm top-5 left-5 right-5 bg-white/50 backdrop-blur-sm">
        <table>
          <tbody>
            {rows.map(row => <tr key={row.label}>
                <td className="text-right"><b>{row.label}</b></td>
                <td className="pl-2">{row.value}</td>
              </tr>)}
          </tbody>
        </table>
      </pre>
    </DevFlag>;
}