'use client';

import { useRouter } from 'next/navigation';
import Button from '@/components/ui/Button';
import Empty from '@/components/ui/Empty';
import { PLANS } from '@/data';
import { useSession } from '@/hooks';
import { EVENTS, mxp } from '@/lib';
import { cn, planRedirect } from '@/utils';
export const Upgrade = ({
  plan,
  message,
  className,
  size,
  icon = 'Lock',
  btnLabel = 'Upgrade',
  btnVariant = 'primary',
  btnClasses,
  emptyStateProps
}) => {
  const {
    data: session
  } = useSession();
  const router = useRouter();
  const classes = cn({
    'account-upgrade': true,
    [className]: className
  });
  const buttonClasses = cn({
    'mt-4': true,
    [btnClasses]: btnClasses
  });
  const handleClick = () => {
    mxp.track(EVENTS.UPGRADE_PLAN_CTA, {
      plan
    });
    router.push(planRedirect(plan ? plan : PLANS.ARTIST));
  };
  return <div className={classes} data-sentry-component="Upgrade" data-sentry-source-file="Upgrade.jsx">
      <Empty login size={size} icon={icon} message={message} {...emptyStateProps} data-sentry-element="Empty" data-sentry-source-file="Upgrade.jsx">
        {session && <Button variant={btnVariant} className={buttonClasses} href={planRedirect(plan ? plan : PLANS.ARTIST)} onClick={handleClick}>
            {btnLabel}
          </Button>}
      </Empty>
    </div>;
};
export default Upgrade;