import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import * as React from 'react';
import Icon from '@/components/ui/Icon';
import { cn } from '@/utils';
const DropdownMenu = DropdownMenuPrimitive.Root;
const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
const DropdownMenuGroup = DropdownMenuPrimitive.Group;
const DropdownMenuPortal = DropdownMenuPrimitive.Portal;
const DropdownMenuSub = DropdownMenuPrimitive.Sub;
const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;
const DropdownMenuSubTrigger = ({
  ref,
  className,
  inset,
  children,
  ...props
}) => <DropdownMenuPrimitive.SubTrigger ref={ref} className={cn('flex cursor-default select-none items-center px-2 py-1.5 text-sm outline-none focus:bg-accent data-[state=open]:bg-accent', inset && 'pl-8', className)} {...props} data-sentry-element="unknown" data-sentry-component="DropdownMenuSubTrigger" data-sentry-source-file="DropdownMenu.jsx">
  {children}
  <Icon name="ChevronRight" className="w-4 h-4 ml-auto" data-sentry-element="Icon" data-sentry-source-file="DropdownMenu.jsx" />
</DropdownMenuPrimitive.SubTrigger>;
DropdownMenuSubTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName;
const DropdownMenuSubContent = ({
  ref,
  className,
  ...props
}) => <DropdownMenuPrimitive.SubContent ref={ref} className={cn('z-50 min-w-[8rem] overflow-hidden border bg-popover p-1 shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2', className)} {...props} data-sentry-element="unknown" data-sentry-component="DropdownMenuSubContent" data-sentry-source-file="DropdownMenu.jsx" />;
DropdownMenuSubContent.displayName = DropdownMenuPrimitive.SubContent.displayName;
const DropdownMenuContent = ({
  ref,
  className,
  sideOffset = 4,
  ...props
}) => <DropdownMenuPrimitive.Portal data-sentry-element="unknown" data-sentry-component="DropdownMenuContent" data-sentry-source-file="DropdownMenu.jsx">
  <DropdownMenuPrimitive.Content ref={ref} sideOffset={sideOffset} className={cn('dropdown-menu-container z-50 min-w-[8rem] overflow-hidden border bg-popover p-1 shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2', className)} {...props} data-sentry-element="unknown" data-sentry-source-file="DropdownMenu.jsx" />
</DropdownMenuPrimitive.Portal>;
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;
const DropdownMenuItem = ({
  ref,
  className,
  inset,
  ...props
}) => <DropdownMenuPrimitive.Item ref={ref} className={cn('dropdown-menu-item relative select-none outline-none transition-colors data-[disabled]:pointer-events-none data-[disabled]:opacity-50', inset && 'pl-8', className)} {...props} data-sentry-element="unknown" data-sentry-component="DropdownMenuItem" data-sentry-source-file="DropdownMenu.jsx" />;
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;
const DropdownMenuCheckboxItem = ({
  ref,
  className,
  children,
  checked,
  ...props
}) => <DropdownMenuPrimitive.CheckboxItem ref={ref} className={cn('dropdown-menu-item relative select-none pl-8 pr-2 outline-none transition-colors data-[disabled]:pointer-events-none data-[disabled]:opacity-50', className)} checked={checked} {...props} data-sentry-element="unknown" data-sentry-component="DropdownMenuCheckboxItem" data-sentry-source-file="DropdownMenu.jsx">
  <span className="absolute top-[.9rem] left-2 flex h-3.5 w-3.5 items-center justify-center">
    <DropdownMenuPrimitive.ItemIndicator data-sentry-element="unknown" data-sentry-source-file="DropdownMenu.jsx">
      <Icon name="Check" className="w-4 h-4" data-sentry-element="Icon" data-sentry-source-file="DropdownMenu.jsx" />
    </DropdownMenuPrimitive.ItemIndicator>
  </span>
  {children}
</DropdownMenuPrimitive.CheckboxItem>;
DropdownMenuCheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName;
const DropdownMenuRadioItem = ({
  ref,
  className,
  children,
  ...props
}) => <DropdownMenuPrimitive.RadioItem ref={ref} className={cn('dropdown-menu-item relative cursor-default select-none pl-8 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50', className)} {...props} data-sentry-element="unknown" data-sentry-component="DropdownMenuRadioItem" data-sentry-source-file="DropdownMenu.jsx">
  <span className="absolute top-[.9rem] left-2 flex h-3.5 w-3.5 items-center justify-center">
    <DropdownMenuPrimitive.ItemIndicator data-sentry-element="unknown" data-sentry-source-file="DropdownMenu.jsx">
      <Icon icon="UserRound" className="w-4 h-4" data-sentry-element="Icon" data-sentry-source-file="DropdownMenu.jsx" />
    </DropdownMenuPrimitive.ItemIndicator>
  </span>
  {children}
</DropdownMenuPrimitive.RadioItem>;
DropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName;
const DropdownMenuLabel = ({
  ref,
  className,
  inset,
  ...props
}) => <DropdownMenuPrimitive.Label ref={ref} className={cn('px-2 py-1.5 text-sm font-semibold', inset && 'pl-8', className)} {...props} data-sentry-element="unknown" data-sentry-component="DropdownMenuLabel" data-sentry-source-file="DropdownMenu.jsx" />;
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;
const DropdownMenuSeparator = ({
  ref,
  className,
  ...props
}) => <DropdownMenuPrimitive.Separator ref={ref} className={cn('-mx-1 my-1 h-px bg-muted', className)} {...props} data-sentry-element="unknown" data-sentry-component="DropdownMenuSeparator" data-sentry-source-file="DropdownMenu.jsx" />;
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;
const DropdownMenuShortcut = ({
  className,
  ...props
}) => {
  return <span className={cn('ml-auto text-xs tracking-widest opacity-60', className)} {...props} data-sentry-component="DropdownMenuShortcut" data-sentry-source-file="DropdownMenu.jsx" />;
};
DropdownMenuShortcut.displayName = 'DropdownMenuShortcut';
export { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuPortal, DropdownMenuRadioGroup, DropdownMenuRadioItem, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger, DropdownMenuTrigger };