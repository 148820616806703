'use client';

import { useEffect } from 'react';
import { AuthButton } from '@/components/Auth';
import AuthVerify from '@/components/Auth/AuthVerify';
import Empty from '@/components/ui/Empty';
import Spinner from '@/components/ui/Spinner';
import { UserEmailForm } from '@/components/User';
import { useSession, useTranslations } from '@/hooks';
import { useCreditsStore } from '@/store';
import { cn } from '@/utils';
export default function Auth({
  children,
  className,
  fallback,
  emptyState = false,
  login = false,
  wrapperClasses,
  loginProps,
  plans,
  verify
}) {
  const {
    data: session,
    status
  } = useSession();
  const {
    user
  } = session || {};
  const missingEmail = user && (user?.contacts?.email === null || user?.contacts?.email === "");
  const {
    setCredits,
    setGems
  } = useCreditsStore();
  useEffect(() => {
    if (user) {
      setCredits(user?.referral?.score);
      setGems(user?.referral?.gems);
    }
  }, [user]);
  const t = useTranslations();
  if (session && status !== "loading" && !window.intercomSettings?.name) {
    window.intercomSettings = {
      ...window.intercomSettings,
      'Spotify ID': user.spotifyId,
      frontend_version: user.frontend_version,
      name: user.spotifyUsername ?? ''
    };
  }
  if (status === "loading") {
    return <Spinner inline />;
  }
  if (!session && login && !fallback && !emptyState || !fallback && !emptyState && missingEmail) {
    return <AuthButton variant="primary" label={t('common.actions.start_now')} missingEmail={missingEmail} {...loginProps} />;
  }
  if (!session && !fallback && emptyState || !fallback && emptyState && missingEmail) {
    return <div className={cn(wrapperClasses)}>
        <Empty login icon="Lock" className={cn(className)} message={missingEmail ? t('auth.missing_email.title') : t('navigation.login.continue')}>
          {missingEmail && <UserEmailForm className="w-full max-w-lg mt-6" />}
        </Empty>
      </div>;
  }
  if (!session || plans && plans.length > 0 && !plans.includes(user.plan?.name)) {
    return fallback ? fallback : null;
  }
  if (verify) {
    return <AuthVerify wrapperClasses={wrapperClasses}>
        {children}
      </AuthVerify>;
  }
  return <>{children}</>;
}