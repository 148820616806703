'use client';

import Image from '@/components/ui/Image';
import { Placeholder } from '@/components/ui/Placeholder';
import { USER_TYPES } from '@/data';
import { cn } from '@/utils';
function Avatar({
  src,
  size,
  label,
  type = USER_TYPES.USER,
  name,
  icon = 'UserRound',
  hideStarBadge = false,
  className
}) {
  const classes = cn({
    avatar: true,
    'avatar-placeholder': !src && !name,
    'avatar-name': !src && name,
    [`avatar-${type}`]: type,
    [className]: className
  });
  return <Image src={src} size={size} label={label} alt={label} type={type} name={name} icon={icon} hideStarBadge={hideStarBadge} className={classes} placeholder={<Placeholder size={size} icon={icon} />} data-sentry-element="Image" data-sentry-component="Avatar" data-sentry-source-file="Avatar.jsx" />;
}
export default Avatar;