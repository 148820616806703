import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Badge from '@/components/ui/Badge';
import { STATUSES } from '@/data';
import { useTranslations } from '@/hooks';
import { getPushProExpireDate, isPushProExpired } from '@/utils';
dayjs.extend(relativeTime);
export const PushProExpireBadge = ({
  push,
  size = 'sm',
  badgeProps,
  ...props
}) => {
  const t = useTranslations();
  const expiresIn = getPushProExpireDate(push.createdAt);
  const isExpired = isPushProExpired(push.createdAt);
  const daysRemaining = expiresIn.diff(dayjs(), 'days');
  const hoursRemaining = expiresIn.diff(dayjs(), 'hours');
  const minutesRemaining = expiresIn.diff(dayjs(), 'minutes');
  if (push.status === STATUSES.CLOSED || push.status === STATUSES.REFUNDED) {
    return null;
  }
  const getBadgeConfig = (days, hours, minutes, expired) => {
    if (expired) {
      return {
        variant: 'light',
        icon: 'Check',
        label: t('push_pro.expired')
      };
    }
    if (days < 1) {
      if (hours < 1) {
        return {
          variant: 'red-light',
          icon: 'ClockAlert',
          label: t('push_pro.expires_in_minutes', {
            minutes
          })
        };
      }
      return {
        variant: 'red-light',
        icon: 'ClockAlert',
        label: t('push_pro.expires_in_hours', {
          hours
        })
      };
    }
    if (days <= 2) {
      return {
        variant: 'orange-light',
        icon: 'ClockAlert',
        label: t('push_pro.expires_in_days', {
          days
        })
      };
    }
    if (days <= 4) {
      return {
        variant: 'yellow-light',
        icon: 'ClockAlert',
        label: t('push_pro.expires_in_days', {
          days
        })
      };
    }
    return {
      variant: 'primary-light',
      icon: 'Clock',
      label: t('push_pro.expires_in_days', {
        days
      })
    };
  };
  const {
    variant,
    icon,
    label
  } = getBadgeConfig(daysRemaining, hoursRemaining, minutesRemaining, isExpired);
  return <Badge variant={variant} icon={icon} label={label} size={size} {...badgeProps} {...props} data-sentry-element="Badge" data-sentry-component="PushProExpireBadge" data-sentry-source-file="PushProExpireBadge.jsx" />;
};
export default PushProExpireBadge;