'use client';

import MatchRecent from './MatchRecent';
import { PushCounter } from '@/components/Match/PushCounter';
import { SearchSelect } from '@/components/Search';
import { useTranslations } from '@/hooks';
import { cn, isAgency, notAgency } from '@/utils';
export const MatchStep1 = ({
  className,
  track,
  trackId,
  setTrack,
  setTrackId,
  setGenres,
  pushedCount,
  resetPushedPlaylists,
  user
}) => {
  const t = useTranslations();
  const handleTrackChange = track => {
    setTrack(track);
    setTrackId(track.id);
    resetPushedPlaylists();
  };
  const handleClear = () => {
    setTrack(null);
    setGenres(null);
  };
  const populateMatch = match => {
    setTrack(match.track);
    setGenres(match.genres);
    setTrackId(match.track.id);
  };
  const isDisabled = isAgency(user) ? false : user?.spotifyArtistIds?.length === 0;
  return <div className={cn("flex flex-col items-start w-full gap-4", className)} data-sentry-component="MatchStep1" data-sentry-source-file="MatchStep1.jsx">
      <SearchSelect id="match-step-1" entity="track" className="w-full" title={t('match.common.steps.first.title')} name="track" tooltipText={<img src="/images/matchfy_select_tutorial.gif" alt="tutorial" loading="lazy" className="max-w-full rounded-sm sm:max-w-4xl" />} label={t('field.track.match.label')} defaultValue={trackId} fetchAudioFeatures={true} artistIds={user?.spotifyArtistIds} limitBy={notAgency(user) ? 'artist' : null} value={trackId} onDataChange={handleTrackChange} onClear={handleClear} isDisabled={isDisabled} data-sentry-element="SearchSelect" data-sentry-source-file="MatchStep1.jsx" />

      <PushCounter pushCount={pushedCount} popularity={track?.popularity} data-sentry-element="PushCounter" data-sentry-source-file="MatchStep1.jsx" />

      <MatchRecent artists={user?.spotifyArtistIds} onClick={populateMatch} user={user} data-sentry-element="MatchRecent" data-sentry-source-file="MatchStep1.jsx" />
    </div>;
};
export default MatchStep1;