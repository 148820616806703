import { truncate } from 'lodash-es';
import { CuratorEmoji } from '@/components/Curator';
import Icon from '@/components/ui/Icon';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/Tooltip/Tooltip';
import { useLocale, useTranslations } from '@/hooks';
import { cn, humanReadableDuration } from '@/utils';
export const MatchCuratorScore = ({
  curator,
  truncateLength = 20,
  className,
  ...props
}) => {
  const t = useTranslations();
  const {
    locale
  } = useLocale();
  const classes = cn({
    'curator-score': true,
    'flex items-center gap-2': true,
    [className]: className
  });
  return <div className={classes} {...props} data-sentry-component="MatchCuratorScore" data-sentry-source-file="MatchCuratorScore.jsx">
      <h4 className="inline-flex items-center gap-1 text-xs whitespace-nowrap">
        <CuratorEmoji curator={curator} data-sentry-element="CuratorEmoji" data-sentry-source-file="MatchCuratorScore.jsx" />
        {curator?.spotifyUsername ? truncate(curator.spotifyUsername, {
        length: truncateLength
      }) : truncate(curator?.id, {
        length: truncateLength
      })}
      </h4>

      <div className="curator-score-data">
        {curator?.responseRate > 0 && <Tooltip>
            <TooltipTrigger asChild>
              <span className="inline-flex items-center text-xs whitespace-nowrap">
                <Icon size="sm" name="MessageCircle" />
                {(curator?.responseRate * 100).toFixed(0)}%
              </span>
            </TooltipTrigger>
            <TooltipContent className="max-w-[280px]">
              <span>{t('curator.score.responseRate')}</span>
            </TooltipContent>
          </Tooltip>}

        {curator?.timeMean > 0 && <Tooltip>
            <TooltipTrigger asChild>
              <span className="inline-flex items-center text-xs whitespace-nowrap">
                <Icon size="sm" name="Clock" />
                {humanReadableDuration({
              value: curator?.timeMean,
              locale,
              t
            })}
              </span>
            </TooltipTrigger>
            <TooltipContent className="max-w-[280px]">
              <span>{t('curator.score.timeMean')}</span>
            </TooltipContent>
          </Tooltip>}

        {!curator?.responseRate && <Tooltip>
            <TooltipTrigger asChild>
              <Icon size="sm" name="MessageCircle" className="text-red" />
            </TooltipTrigger>
            <TooltipContent className="max-w-[280px]">
              <span>{t('curator.score.responseRate')}</span>
            </TooltipContent>
          </Tooltip>}

        {!curator?.timeMean && <Tooltip>
            <TooltipTrigger asChild>
              <Icon size="sm" name="Clock" className="text-red" />
            </TooltipTrigger>
            <TooltipContent className="max-w-[280px]">
              <span>{t('curator.score.timeMean')}</span>
            </TooltipContent>
          </Tooltip>}
      </div>
    </div>;
};
export default MatchCuratorScore;