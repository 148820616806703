import React, { useEffect } from 'react';
import { PushProDisputeAlert, PushProTrack } from '@/components/Chat/PushPro';
import { PushProExpireBadge } from '@/components/PushPro';
import { Accordion, AccordionItem } from '@/components/ui/Accordion';
import Alert from '@/components/ui/Alert';
import Badge, { BadgesList, BadgeStatus } from '@/components/ui/Badge';
import Button from '@/components/ui/Button';
import { DialogReadMore } from '@/components/ui/Dialog';
import Emoji from '@/components/ui/Emoji';
import Info from '@/components/ui/Info';
import { STATUSES, CHAT_STATUSES } from '@/data';
import { useDimensions, useTranslations } from '@/hooks';
import { useChatStore } from '@/store';
import { buildSpotifyTrackUrl, cn } from '@/utils';
export const PushProOverview = ({
  className,
  hasDispute,
  isProCurator,
  push
}) => {
  const {
    activeChat,
    setActiveChat
  } = useChatStore();
  const [ref, {
    height
  }] = useDimensions({});
  const t = useTranslations();
  const classes = cn({
    [className]: className
  });
  useEffect(() => {
    if (activeChat?.pushPro?.status === STATUSES.CLOSED && activeChat?.status !== CHAT_STATUSES.FROZEN) {
      setActiveChat({
        ...activeChat,
        status: CHAT_STATUSES.FROZEN
      });
    }
  }, [activeChat]);
  useEffect(() => {
    const chatWrapper = document.querySelector('.chat-wrapper');
    if (!chatWrapper) return;
    chatWrapper.style.setProperty('--chat-overview-height', `${height}px`);
  }, [height]);
  if (!push) return null;
  const isUnreleased = push?.trackType === 'unreleased';
  const trackLink = push?.trackId ? buildSpotifyTrackUrl(push.trackId) : push?.track?.link;
  const trimLink = (link, maxLength = 64) => {
    if (!link || link.length <= maxLength) return link;
    return `${link.substring(0, maxLength)}...`;
  };
  return <div ref={ref} className={classes} data-sentry-component="PushProOverview" data-sentry-source-file="PushProOverview.jsx">
      <Accordion data-sentry-element="Accordion" data-sentry-source-file="PushProOverview.jsx">
        <AccordionItem className="rounded-sm" id={`push-pro-overview-${push._id}`} label={push?.track?.name} headerContent={<div className="flex flex-wrap items-center gap-1">
              <Badge size="sm" emoji={<Emoji symbol="💎" label="Gem" />} value={push?.amount + push?.feeAmount} variant="secondary-light" />

              <BadgeStatus size="sm" status={hasDispute ? STATUSES.DISPUTE : push.status} />

              <PushProExpireBadge hasDispute={hasDispute} isProCurator={isProCurator} push={push} />
            </div>} data-sentry-element="AccordionItem" data-sentry-source-file="PushProOverview.jsx">
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 md:grid-cols-2 lg:grid-cols-4">
            {hasDispute && <Alert size="sm" variant="yellow" className="rounded-sm md:col-span-2 lg:col-span-4">
                {isProCurator ? t('push_pro.dispute.alert.curator') : t('push_pro.dispute.alert.artist')}
              </Alert>}

            <PushProDisputeAlert push={push} data-sentry-element="PushProDisputeAlert" data-sentry-source-file="PushProOverview.jsx" />

            <PushProTrack className="md:col-span-2 lg:col-span-4" push={push} isUnreleased={isUnreleased} trackLink={trackLink} trimLink={trimLink} data-sentry-element="PushProTrack" data-sentry-source-file="PushProOverview.jsx" />

            <Info vertical label={t('field.typology.label')} valueClasses="w-full" value={<span className="text-sm">
                  {t(`push_pro.feedback.${push?.info?.typology}.label`)}
                </span>} data-sentry-element="Info" data-sentry-source-file="PushProOverview.jsx" />

            <Info vertical label={t('field.genres.label')} valueClasses="w-full" value={<BadgesList size="sm" maxVisible={5} hiddenVariant="primary-light" tags={push?.info?.genres} />} data-sentry-element="Info" data-sentry-source-file="PushProOverview.jsx" />

            <Info vertical label={t('field.lyrics.label')} valueClasses="w-full" value={<DialogReadMore text={push?.info?.lyrics?.length > 0 ? push?.info?.lyrics : t('common.labels.not_available')} title={t('field.lyrics.label')} maxChars={100} trigger={<Button variant="light" size="xs" label={t('common.labels.read_more')} />} contentClasses="text-sm whitespace-pre-wrap" previewClasses="text-xs whitespace-pre-wrap opacity-80" />} data-sentry-element="Info" data-sentry-source-file="PushProOverview.jsx" />

            <Info vertical label={t('field.artistBio.label')} valueClasses="w-full" value={<DialogReadMore text={push?.info?.bio?.length > 0 ? push?.info?.bio : t('common.labels.not_available')} title={t('field.artistBio.label')} maxChars={100} trigger={<Button variant="light" size="xs" label={t('common.labels.read_more')} />} contentClasses="text-sm whitespace-pre-wrap" previewClasses="text-xs whitespace-pre-wrap opacity-80" />} data-sentry-element="Info" data-sentry-source-file="PushProOverview.jsx" />

            <Info vertical className="md:col-span-2 lg:col-span-4" label={t('field.request.label')} valueClasses="w-full" value={<DialogReadMore text={push?.info?.notes?.length > 0 ? push?.info?.notes : t('common.labels.not_available')} title={t('field.request.label')} maxChars={100} trigger={<Button variant="light" size="xs" label={t('common.labels.read_more')} />} contentClasses="text-sm whitespace-pre-wrap" previewClasses="text-xs whitespace-pre-wrap opacity-80" />} data-sentry-element="Info" data-sentry-source-file="PushProOverview.jsx" />
          </div>
        </AccordionItem>
      </Accordion>
    </div>;
};
export default PushProOverview;