import { DevRefreshUserCache, DevRemoveArtist, DevVerifyUser, DevPurgeUser } from '@/components/Development';
import Button from '@/components/ui/Button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/Dropdown/DropdownMenu';
import { Checkbox } from '@/components/ui/Field/Checkbox';
import { useDevStore } from '@/store';
export const DevTools = () => {
  const {
    isDev,
    devFlags,
    disableFlag,
    enabledFlags,
    enableFlag
  } = useDevStore();
  if (isDev) {
    return <DropdownMenu modal={false} className="z-[1000]">
        <DropdownMenuTrigger asChild>
          <div>
            <Button variant="ghost" icon="Code" />
          </div>
        </DropdownMenuTrigger>

        <DropdownMenuContent className="max-w-[250px]">
          <DevRefreshUserCache />

          <DevRemoveArtist />

          <DevVerifyUser />

          <DevPurgeUser />

          {devFlags.map(flag => <DropdownMenuItem key={flag} onSelect={e => e.preventDefault()}>
              <Checkbox id={flag} checked={enabledFlags.includes(flag)} onCheckedChange={value => {
            if (value) {
              enableFlag(flag);
            } else {
              disableFlag(flag);
            }
          }} label={flag} className="font-mono text-xs tracking-tight" aria-label={flag} />
            </DropdownMenuItem>)}
        </DropdownMenuContent>
      </DropdownMenu>;
  }
};
export default DevTools;