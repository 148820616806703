'use client';

import { AnimatePresence, motion } from "motion/react";
import { nanoid } from 'nanoid';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Icon from '@/components/ui/Icon';
import { useRouter } from '@/i18n/routing';
import { cn } from '@/utils';
const transition = {
  type: 'tween',
  ease: 'easeOut',
  duration: 0.15
};
export const TabLinks = ({
  className,
  inline,
  tabs,
  selectedTabIndex,
  showHover = true,
  showIndicator = true,
  variant
}) => {
  const [buttonRefs, setButtonRefs] = useState([]);
  useEffect(() => {
    setButtonRefs(prev => prev.slice(0, tabs.length));
  }, [tabs.length]);
  const navRef = useRef(null);
  const navRect = navRef.current?.getBoundingClientRect();
  const selectedRect = buttonRefs[selectedTabIndex]?.getBoundingClientRect();
  const [hoveredTabIndex, setHoveredTabIndex] = useState(null);
  const tabIndex = hoveredTabIndex !== null ? hoveredTabIndex : -1;
  const hoveredRect = buttonRefs[tabIndex]?.getBoundingClientRect();
  const router = useRouter();
  const classes = cn({
    'tab-header': true,
    'tab-inline': inline,
    [`tab-col-${tabs.length}`]: tabs.length > 1,
    [`tab-${variant}`]: variant,
    [className]: className
  });
  const handleTabClick = item => {
    if (item.onClick) {
      item.onClick();
    }
    if (item.path) {
      router.push(item.path);
    }
  };
  return <nav ref={navRef} className={classes} onPointerLeave={() => setHoveredTabIndex(null)} data-sentry-component="TabLinks" data-sentry-source-file="TabLinks.jsx">
      {tabs.map((item, i) => {
      return <motion.button key={nanoid()} className={cn('button', {
        hovered: hoveredTabIndex === i || selectedTabIndex === i,
        active: selectedTabIndex === i
      })} ref={el => buttonRefs[i] = el} onPointerEnter={!isMobile ? () => {
        setHoveredTabIndex(i);
      } : null} onFocus={!isMobile ? () => {
        setHoveredTabIndex(i);
      } : null} onClick={() => handleTabClick(item)}>
            {item.icon && <Icon type={item.iconType} name={item.icon} />}
            <span>{item.label}</span>
          </motion.button>;
    })}

      <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="TabLinks.jsx">
        {hoveredRect && navRect && showHover && <motion.div key="hover" className="tab-hover" initial={{
        x: hoveredRect.left - navRect.left,
        y: hoveredRect.top - navRect.top,
        width: hoveredRect.width,
        height: hoveredRect.height,
        opacity: 0
      }} animate={{
        x: hoveredRect.left - navRect.left,
        y: hoveredRect.top - navRect.top,
        width: hoveredRect.width,
        height: hoveredRect.height,
        opacity: 1
      }} exit={{
        x: hoveredRect.left - navRect.left,
        y: hoveredRect.top - navRect.top,
        width: hoveredRect.width,
        height: hoveredRect.height,
        opacity: 0
      }} transition={transition} />}
      </AnimatePresence>

      {selectedRect && navRect && showIndicator && <motion.div className="tab-indicator" initial={false} animate={{
      width: selectedRect.width * 0.8,
      x: `calc(${selectedRect.left - navRect.left}px + 10%)`,
      opacity: 1
    }} transition={transition} />}
    </nav>;
};