import { useTranslations } from 'next-intl';
import Button from '@/components/ui/Button';
import SectionTitle from '@/components/ui/SectionTitle/SectionTitle';
import Wrapper from '@/components/ui/Wrapper';
import { useConsent } from '@/context/ConsentProvider';
import { ROUTES_DATA } from '@/data';
export default function BlockedUserPage() {
  const {
    handleIntercomToggle
  } = useConsent();
  const t = useTranslations();
  return <Wrapper id="analysis" data-sentry-element="Wrapper" data-sentry-component="BlockedUserPage" data-sentry-source-file="BlockedUserPage.jsx">
      <SectionTitle title={t('block_user.header.title')} subtitle={t('block_user.header.subtitle')} icon={ROUTES_DATA.BLOCKED_USERS.icon} data-sentry-element="SectionTitle" data-sentry-source-file="BlockedUserPage.jsx" />

      <div className="flex items-center justify-center">
        <Button label={t('support.actions.contact')} className="mb-10 toggle-intercom" onClick={handleIntercomToggle} data-sentry-element="Button" data-sentry-source-file="BlockedUserPage.jsx" />
      </div>
    </Wrapper>;
}