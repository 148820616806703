import { ChatStart } from '@/components/Chat';
import { CuratorPromote } from '@/components/Curator';
import { PushPro } from '@/components/PushPro';
import Avatar from '@/components/ui/Avatar';
import Badge from '@/components/ui/Badge';
import { VerifiedIcon } from '@/components/ui/Icon/VerifiedIcon';
import Share from '@/components/ui/Share/Share';
import { UserBio, UserPlanBadge, UserMoreDropdown } from '@/components/User';
import countries from '@/data/countries.json';
import { useTranslations } from '@/hooks';
import { cn, formatNumber, isPro, isStar } from '@/utils';
export function UserHeader({
  avatar,
  className,
  daysLeft = 0,
  featured,
  followers,
  isOwner,
  type,
  bio,
  country,
  companyName,
  name,
  roles,
  tags,
  plan,
  website,
  user,
  userId
}) {
  const t = useTranslations();
  return <div className={cn('curator-header', className)} data-sentry-component="UserHeader" data-sentry-source-file="UserHeader.jsx">
      <div className="flex flex-col items-start justify-start gap-4 mb-4 md:gap-8 md:flex-row">
        <Avatar size="xl" type={type} hideStarBadge src={avatar} alt={name} data-sentry-element="Avatar" data-sentry-source-file="UserHeader.jsx" />

        <div className="flex flex-col items-start">
          <h1 className="inline-flex items-center gap-2 mb-2 text-xl md:text-2xl">
            <span>{name}</span>

            {(isStar(type) || isPro(type)) && <span className={cn('verified', {
            'text-gold': isStar(type),
            'text-blue': isPro(type)
          })}>
                <VerifiedIcon />
              </span>}

            {!isOwner && <UserMoreDropdown userId={userId} className="ml-2" />}
          </h1>

          <div className="flex flex-wrap gap-5">
            <div className="flex gap-2">
              {isStar(type) && <Badge icon="Star" label="Star" variant="gold-light" />}

              <UserPlanBadge plan={plan} data-sentry-element="UserPlanBadge" data-sentry-source-file="UserHeader.jsx" />

              {country && countries.find(c => c.value === country) && <Badge label={countries.find(c => c.value === country)?.label} variant="light" icon="Flag" />}

              {companyName && <Badge label={companyName} variant="light" icon="Building" />}
            </div>

            {!!followers && followers > 0 && <Badge label={t('common.metrics.followers')} value={formatNumber(followers)} className="p-0 border-0" variant="text" />}

            {isOwner && daysLeft > 0 && <Badge label={t('common.states.featured')} value={t('curator.featured.badge', {
            daysLeft
          })} variant="text" />}
          </div>

          <UserBio bio={bio} className="mt-4 text-sm opacity-80" data-sentry-element="UserBio" data-sentry-source-file="UserHeader.jsx" />

          {(roles?.length > 0 || tags?.length > 0 || website) && <div className="flex flex-wrap gap-1 mt-2">
              {roles?.length > 0 && <div className="flex flex-wrap gap-2">
                  {roles.map(role => <Badge key={role} label={t(`role.${role}`)} variant="light" />)}
                </div>}

              {tags && <div className="flex flex-wrap gap-2">
                  {tags.map(tag => <Badge key={tag} label={tag} variant="primary-light" />)}
                </div>}

              {website && isPro(type) && <Badge href={website} target="_blank" rel="noopener noreferrer" label="Website" value={website} icon="Globe" variant="light" />}
            </div>}

          <div className="flex flex-wrap gap-2 mt-3">
            <Share size="sm" url={window.location} triggerVariant="light" title={t('common.actions.share')} data-sentry-element="Share" data-sentry-source-file="UserHeader.jsx" />

            {isOwner && !featured ? <CuratorPromote size="sm" triggerVariant={isStar(type) ? 'gold' : 'primary'} /> : null}

            {!isOwner && !isPro(type) && <ChatStart size="sm" variant={isStar(type) ? 'gold' : 'primary'} chatUserId={userId} />}

            {isPro(type) && !isOwner && <PushPro size="sm" curatorId={user.spotifyId} curatorName={user.spotifyUsername ?? user.spotifyId} curatorAvatar={user.avatar} curatorType={user.type ?? USER_TYPES.USER} curatorRoles={user.contacts?.roles} feedbackPrice={user.pushProFeedbackPrice} />}
          </div>
        </div>
      </div>
    </div>;
}
export default UserHeader;