import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import * as React from 'react';
import { cn } from '@/utils';
const Checkbox = ({
  ref,
  className,
  label,
  labelClasses,
  checked,
  onCheckedChange,
  id,
  ...props
}) => {
  const classes = cn({
    'flex items-center gap-2': true,
    [className]: className
  });
  const labelCn = cn({
    'm-0 text-sm cursor-pointer': true,
    [labelClasses]: labelClasses
  });
  return <div className={classes} data-sentry-component="Checkbox" data-sentry-source-file="Checkbox.jsx">
      <CheckboxPrimitive.Root ref={ref} id={id} checked={checked} onCheckedChange={onCheckedChange} className={cn('checkbox relative peer h-5 w-5 shrink-0 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50')} {...props} data-sentry-element="unknown" data-sentry-source-file="Checkbox.jsx" />

      {label && <label htmlFor={id} className={labelCn}>
          {label}
        </label>}
    </div>;
};
Checkbox.displayName = CheckboxPrimitive.Root.displayName;
export { Checkbox };
export default Checkbox;