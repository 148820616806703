'use client';

import { useState } from 'react';
import Button from '@/components/ui/Button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/Dialog';
import { UserEmailForm } from '@/components/User';
import { ROUTES } from '@/data';
import { useSession, useTranslations } from '@/hooks';
import { useRouter, usePathname } from '@/i18n/routing';
import { useAppStore } from '@/store';
import { cn } from '@/utils';
export const AuthButton = ({
  className,
  variant = 'outline',
  icon,
  size,
  label = 'Login',
  missingEmail
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const pathname = usePathname();
  const {
    setRedirect
  } = useAppStore();
  const {
    data: session
  } = useSession();
  const t = useTranslations();
  if (session && !missingEmail) {
    return null;
  }
  const handleSignIn = () => {
    setLoading(true);
    if (pathname !== ROUTES.LOGIN && pathname !== ROUTES.HOME) {
      setRedirect(pathname);
    }
    router.push(ROUTES.LOGIN);
  };
  const classes = cn({
    [className]: className
  });
  if (missingEmail) {
    return <>
        <Button variant={variant} className={classes} onClick={() => setOpen(true)} icon="MailWarning" label={t('auth.missing_email.cta')} loading={loading} size={size} />

        <Dialog open={open} className="dialog-xs" onOpenChange={() => setOpen(false)}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>
                {t('auth.missing_email.title')}
              </DialogTitle>
            </DialogHeader>

            <DialogDescription>
              {t('auth.missing_email.description')}
            </DialogDescription>

            <UserEmailForm className="mt-6" />
          </DialogContent>
        </Dialog>
      </>;
  }
  return <Button variant={variant} className={classes} onClick={handleSignIn} icon={icon} label={label} loading={loading} size={size} data-sentry-element="Button" data-sentry-component="AuthButton" data-sentry-source-file="AuthButton.jsx" />;
};
export default AuthButton;