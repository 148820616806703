'use client';

import { useState } from 'react';
import { ChatRestrictions } from './ChatRestrictions';
import { matchfyApi } from '@/api';
import Button from '@/components/ui/Button';
import { ROUTES } from '@/data';
import { useSession, useTranslations } from '@/hooks';
import { useRouter } from '@/i18n/routing';
import { toast } from '@/lib';
export const ChatStart = ({
  inline,
  size,
  icon = 'MessageCircle',
  label = 'Chat',
  variant = 'primary',
  btnProps,
  className,
  chatUserId
}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: session
  } = useSession();
  const user = session?.user || {};
  const router = useRouter();
  const t = useTranslations();
  const handleChatStart = async e => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);
    if (!user) {
      router.push(ROUTES.LOGIN);
      return;
    }
    if (!user && !chatUserId) {
      toast('error', t('feedback.error.default'));
      setIsLoading(false);
      return;
    }
    try {
      const chat = await matchfyApi.createChat([chatUserId]);
      if (chat && chat?.sid && !chat.exists) {
        await matchfyApi.joinChat(chat.sid, [user?.spotifyId, chatUserId]);
      }
      router.push(`${ROUTES.CHAT}/${chat.sid}`);
    } catch (error) {
      if (error?.response?.data?.code === 403) {
        setOpen(true);
      }
      if (error?.response?.data?.message) {
        toast('error', t(error?.response?.data?.message));
      } else {
        toast('error', t('feedback.error.default'));
      }
      setIsLoading(false);
    }
  };
  return <>
      <ChatRestrictions open={open} setOpen={setOpen} data-sentry-element="ChatRestrictions" data-sentry-source-file="ChatStart.jsx" />

      <Button size={size} onClick={handleChatStart} label={label} icon={icon} loading={isLoading} disabled={isLoading} variant={inline ? 'link-plain' : variant} className={className} {...btnProps} data-sentry-element="Button" data-sentry-source-file="ChatStart.jsx" />
    </>;
};
export default ChatStart;