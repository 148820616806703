'use client';

import { useQuery } from '@tanstack/react-query';
import useSound from 'use-sound';
import NotificationBadge from './NotificationBadge';
import { matchfyApi } from '@/api';
import Button from '@/components/ui/Button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/Dropdown/DropdownMenu';
import Empty from '@/components/ui/Empty';
import { NotificationDropdownItem } from '@/components/ui/Notification';
import Spinner from '@/components/ui/Spinner';
import { NOTIFICATION_FETCH_TIMEOUT, ROUTES } from '@/data';
import { useSession, useTranslations } from '@/hooks';
import { useBannerStore } from '@/store';
import { cn } from '@/utils';
export const NotificationDropdown = ({
  className,
  triggerClasses
}) => {
  const t = useTranslations();
  const [play] = useSound('/audio/notification_sound.mp3');
  const {
    setBanner
  } = useBannerStore();
  const {
    data: session
  } = useSession();
  const {
    user
  } = session || {};
  const notificationEnabled = user?.settings?.webNotifications;
  const {
    data,
    isLoading
  } = useQuery({
    queryKey: ['notifications'],
    queryFn: matchfyApi.getNotificationDigest,
    refetchInterval: NOTIFICATION_FETCH_TIMEOUT,
    onSuccess: data => {
      const {
        banner,
        notifications
      } = data;
      if (banner && banner?.isActive) {
        setBanner(banner);
      }
      // Play sound if notifications increased and enabled
      if (notificationEnabled && notifications.length > (previousData?.notifications?.length || 0)) {
        play();
      }
    }
  });
  const notifications = data?.notifications || [];
  const count = data?.count || 0;
  const menuItems = notifications.map(notification => ({
    id: notification._id,
    label: <NotificationDropdownItem notification={notification} />
  }));
  const classes = cn({
    'notification-dropdown': true,
    [className]: className
  });
  return <DropdownMenu modal={false} className={classes} data-sentry-element="DropdownMenu" data-sentry-component="NotificationDropdown" data-sentry-source-file="NotificationDropdown.jsx">
      <DropdownMenuTrigger asChild data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="NotificationDropdown.jsx">
        <Button iconVariant variant="ghost" icon="Bell" className={cn('gap-0', triggerClasses)} aria-label="Notifications" data-sentry-element="Button" data-sentry-source-file="NotificationDropdown.jsx">
          <NotificationBadge count={count} hideCount data-sentry-element="NotificationBadge" data-sentry-source-file="NotificationDropdown.jsx" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end" className="w-[300px]" data-sentry-element="DropdownMenuContent" data-sentry-source-file="NotificationDropdown.jsx">
        <DropdownMenuLabel data-sentry-element="DropdownMenuLabel" data-sentry-source-file="NotificationDropdown.jsx">
          {t('notifications.dropdown.title')}
        </DropdownMenuLabel>

        <DropdownMenuSeparator data-sentry-element="DropdownMenuSeparator" data-sentry-source-file="NotificationDropdown.jsx" />

        <DropdownMenuGroup className="notification-dropdown-menu max-h-[300px] overflow-y-auto" data-sentry-element="DropdownMenuGroup" data-sentry-source-file="NotificationDropdown.jsx">
          {isLoading && <Spinner inline />}

          {!isLoading && menuItems?.length > 0 ? menuItems.map(item => <DropdownMenuItem key={item.id}>{item.label}</DropdownMenuItem>) : <Empty ghost size="sm" className="w-full m-0" icon="BellOff" message={t('notifications.unread.empty_state')} />}
        </DropdownMenuGroup>

        <DropdownMenuSeparator data-sentry-element="DropdownMenuSeparator" data-sentry-source-file="NotificationDropdown.jsx" />

        <div className="p-2">
          <Button size="sm" className="w-full" href={ROUTES.NOTIFICATIONS} icon="Eye" label={t('notifications.dropdown.show_all')} data-sentry-element="Button" data-sentry-source-file="NotificationDropdown.jsx" />
        </div>
      </DropdownMenuContent>
    </DropdownMenu>;
};
export default NotificationDropdown;