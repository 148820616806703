import Badge from '@/components/ui/Badge';
import Icon from '@/components/ui/Icon';
import Image from '@/components/ui/Image';
import { Placeholder } from '@/components/ui/Placeholder';
import { ROUTES } from '@/data';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
export function PlaylistCard({
  playlist,
  className
}) {
  const t = useTranslations();
  const PlaylistCover = () => {
    <Image src={playlist?.images[0]?.url} placeholder={<Placeholder size="lg" icon="Music" className="playlist-cover" />} alt={playlist?.name} />;
  };
  const classes = cn({
    'playlist-overview': true,
    [className]: className
  });
  return <div className={classes} data-sentry-component="PlaylistCard" data-sentry-source-file="PlaylistCard.jsx">
      <div className="playlist-info-wrapper">
        <PlaylistCover data-sentry-element="PlaylistCover" data-sentry-source-file="PlaylistCard.jsx" />

        <div className="playlist-info">
          <div className="playlist-meta">
            <Badge variant="light" icon="CircleUserRound" label={t('match.common.owner')} href={`${ROUTES.COMMUNITY}/${playlist?.owner?.id}`} value={playlist?.owner?.spotifyUsername} data-sentry-element="Badge" data-sentry-source-file="PlaylistCard.jsx" />

            <Badge variant="light" icon="UsersRound" label={t('common.metrics.followers')} value={playlist?.followers?.total} data-sentry-element="Badge" data-sentry-source-file="PlaylistCard.jsx" />
          </div>

          <h2>{playlist.name}</h2>

          {playlist?.description && <p className="playlist-description">
              {playlist.description}
            </p>}

          <div className="flex">
            {playlist?.external_urls?.spotify && <a href={playlist?.external_urls?.spotify} className="mt-3 button button-primary" target="_blank" rel="noopener noreferrer">
                <Icon className="mr-2" type="brand" name="Spotify" />
                {t('common.actions.open_in_spotify')}
              </a>}
          </div>
        </div>
      </div>
    </div>;
}
export default PlaylistCard;