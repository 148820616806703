import dayjs from 'dayjs';
import 'dayjs/locale/es';
import 'dayjs/locale/it';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import Icon from '@/components/ui/Icon';
import { getNotificationsProps, ROUTES_DATA } from '@/data';
import { useLocale } from '@/hooks';
import { Link } from '@/i18n/routing';
import { cn, stripHtml } from '@/utils';
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
export const NotificationDropdownItem = ({
  notification
}) => {
  const {
    content,
    type
  } = notification;
  const {
    locale
  } = useLocale();
  dayjs.locale(locale);
  const notificationHasLink = notification.metadata && notification.metadata.cta && notification.metadata.cta.url;
  const getIcon = () => {
    switch (type) {
      case 'accelerate':
        return ROUTES_DATA.ACCELERATE.icon;
      case 'analysis':
        return ROUTES_DATA.ANALYSIS.icon;
      case 'limit':
        return 'TriangleAlert';
      case 'submission':
        return ROUTES_DATA.SUBMISSIONS.icon;
      case 'submission_accept':
        return 'Check';
      case 'plan':
        return ROUTES_DATA.PRICING.icon;
      case 'push':
        return ROUTES_DATA.NOTIFICATIONS.icon;
      case 'Vip':
        return ROUTES_DATA.VIP.icon;
      default:
        return ROUTES_DATA.NOTIFICATIONS.icon;
    }
  };
  const getTime = () => {
    return dayjs(notification.createdAt).locale(locale).fromNow();
  };
  return <div className="notification-item" data-sentry-component="NotificationDropdownItem" data-sentry-source-file="NotificationDropdownItem.jsx">
      <div className="notification-item-inner">
        <div className={cn('notification-item-icon', {
        [`icon-${getNotificationsProps(type).color}`]: true
      })}>
          <Icon name={getNotificationsProps(type).icon} data-sentry-element="Icon" data-sentry-source-file="NotificationDropdownItem.jsx" />
        </div>
        <div className="relative notification-item-content">
          {notificationHasLink ? <Link href={notification.metadata.cta.url} className="flex flex-col items-start gap-1 text-sm">
              {stripHtml(content)}
              <div className="notification-item-time">{getTime()}</div>
            </Link> : <div className="p-0">
              <div dangerouslySetInnerHTML={{
            __html: content
          }} />
              <div className="notification-item-time">{getTime()}</div>
            </div>}
        </div>
      </div>
    </div>;
};
export default NotificationDropdownItem;