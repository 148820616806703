import { useState } from 'react';
import BlockUser from '@/components/BlockUser';
import Button from '@/components/ui/Button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/ui/Dropdown/DropdownMenu';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
export function UserMoreDropdown({
  userId,
  triggerClasses
}) {
  const [open, setOpen] = useState(false);
  const t = useTranslations();
  return <DropdownMenu modal={false} open={open} onOpenChange={setOpen} className="z-[1000]" data-sentry-element="DropdownMenu" data-sentry-component="UserMoreDropdown" data-sentry-source-file="UserMoreDropdown.jsx">
      <DropdownMenuTrigger asChild data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="UserMoreDropdown.jsx">
        <Button variant="ghost" icon="Ellipsis" className={cn('py-2 px-2', triggerClasses)} data-sentry-element="Button" data-sentry-source-file="UserMoreDropdown.jsx" />
      </DropdownMenuTrigger>

      <DropdownMenuContent align="right" className="w-[150px] overflow-visible" data-sentry-element="DropdownMenuContent" data-sentry-source-file="UserMoreDropdown.jsx">
        <BlockUser size="sm" checkOnlineStatus btnClasses="rounded-sm w-full" btnVariant="light" blockedBtnVariant="light" spotifyId={userId} showTitle={false} data-sentry-element="BlockUser" data-sentry-source-file="UserMoreDropdown.jsx" />
      </DropdownMenuContent>
    </DropdownMenu>;
}
export default UserMoreDropdown;