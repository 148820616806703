import { useMutation } from '@tanstack/react-query';
import { matchfyApi } from '@/api';
import { DropdownMenuItem } from '@/components/ui/Dropdown/DropdownMenu';
import Icon from '@/components/ui/Icon';
import { useSession } from '@/hooks';
export default function DevRefreshUserCache() {
  const {
    update
  } = useSession();
  const {
    mutate: refreshUserCache,
    isRefreshing
  } = useMutation({
    mutationFn: async () => {
      await matchfyApi.devRefreshUserCache();
      update();
    }
  });
  return <DropdownMenuItem disabled={isRefreshing} loading={isRefreshing} id="dev-refresh-user" onSelect={e => e.preventDefault()} onClick={() => refreshUserCache()} className="inline-flex items-center gap-2" label="Refresh User" data-sentry-element="DropdownMenuItem" data-sentry-component="DevRefreshUserCache" data-sentry-source-file="DevRefreshUserCache.jsx">
      <Icon name="RefreshCw" className="w-4 h-4" data-sentry-element="Icon" data-sentry-source-file="DevRefreshUserCache.jsx" />
      <span>Refresh User</span>
      <span className="px-1 ml-auto text-[0.6rem] tracking-wide uppercase border rounded-[0.2rem] text-muted-foreground border-muted-foreground">
        Dev
      </span>
    </DropdownMenuItem>;
}