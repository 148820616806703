import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ArtistConfig } from '@/components/Artist';
import Genres from '@/components/Genres';
import { SearchSelect } from '@/components/Search';
import Button from '@/components/ui/Button';
import Field from '@/components/ui/Field';
import { MAXIMUM_GENRES_PER_PLAYLIST, PUSH_PRO_FEEDBACK, PUSH_PRO_TRACK_TYPES } from '@/data';
import { useTranslations } from '@/hooks';
import { useArtistStore, usePushProFormStore } from '@/store';
import { cn, isAgency, notAgency } from '@/utils';
export const PushProForm = ({
  className,
  trackId,
  genres,
  disabled = false,
  loading,
  user,
  onSubmit
}) => {
  const {
    getUserArtist
  } = useArtistStore();
  const {
    formData,
    setFormData
  } = usePushProFormStore();
  const t = useTranslations();
  const {
    control,
    formState: {
      errors
    },
    handleSubmit,
    setValue,
    watch
  } = useForm({
    defaultValues: {
      ...formData,
      track: trackId || formData.track,
      genres: genres || formData.genres
    }
  });
  const onSubmitForm = data => {
    setFormData(data);
    if (onSubmit) {
      onSubmit(data);
    }
  };
  const handleTrackChange = async track => {
    setValue('track', track);
    setValue('trackId', track?.id);
    setValue('trackName', track?.name);
    if (isAgency(user)) {
      setValue('artistId', track?.artists[0]?.id);
      setValue('artistName', track?.artists[0]?.name);
    } else {
      const userArtist = await getUserArtist();
      setValue('artistId', userArtist?.id);
      setValue('artistName', userArtist?.name);
    }
  };
  const watchedFields = watch();
  useEffect(() => {
    if (JSON.stringify(watchedFields) !== JSON.stringify(formData)) {
      setFormData(watchedFields);
    }
  }, [watchedFields]);
  const classes = cn({
    'grid grid-cols-1 md:grid-cols-2 gap-5': true,
    [className]: className
  });
  return <form id="push-pro-form" className={classes} onSubmit={handleSubmit(onSubmitForm)} data-sentry-component="PushProForm" data-sentry-source-file="PushProForm.jsx">
      <Controller name="trackType" control={control} rules={{
      required: true
    }} render={({
      field
    }) => <Field className="w-full col-span-2" direction="column" isDisabled={disabled} errors={errors.trackType} label={t('field.track.type.label')} optionsContainerClasses="grid grid-cols-1 md:grid-cols-2 gap-3 w-full" options={PUSH_PRO_TRACK_TYPES} type="radio-group" {...field} />} data-sentry-element="Controller" data-sentry-source-file="PushProForm.jsx" />

      <ArtistConfig compact={true} className="md:col-span-2" data-sentry-element="ArtistConfig" data-sentry-source-file="PushProForm.jsx" />

      {watch('trackType') === PUSH_PRO_TRACK_TYPES[0].id && <Controller name="track" control={control} rules={{
      required: true
    }} render={() => <SearchSelect artistIds={user?.spotifyArtistIds} className="w-full col-span-2" defaultValue={formData.trackId} errors={errors.track} fetchAudioFeatures={true} entity="track" isDisabled={disabled} id="push-pro-form-track-id" name="trackId" label={t('field.track.match.label')} limitBy={notAgency(user) ? 'artist' : null} onDataChange={handleTrackChange} onClear={() => handleTrackChange('')} />} />}

      {watch('trackType') === PUSH_PRO_TRACK_TYPES[1].id && <>
          <Controller name="trackName" control={control} disabled={disabled} rules={{
        required: true
      }} render={({
        field
      }) => <Field type="text" className="w-full col-span-2" label={t('field.track.name.label')} placeholder={t('field.track.name.placeholder')} errors={errors.trackName} {...field} />} />

          <Controller name="trackLink" control={control} type="url" disabled={disabled} rules={{
        required: true
      }} render={({
        field
      }) => <Field type="text" className="w-full col-span-2" label={t('field.track.link.label')} placeholder={t('field.track.link.placeholder')} errors={errors.trackLink} {...field} />} />
        </>}

      <Controller name="genres" control={control} rules={{
      required: true
    }} render={({
      field
    }) => <Genres isDisabled={disabled} className="col-span-2" genres={field.value ?? formData?.genres} label={t('field.genres.label')} maxLimit={MAXIMUM_GENRES_PER_PLAYLIST} errors={errors.genres} onGenreSelect={tags => field.onChange(tags)} />} data-sentry-element="Controller" data-sentry-source-file="PushProForm.jsx" />

      <Controller name="lyrics" control={control} disabled={disabled} rules={{
      required: false
    }} render={({
      field
    }) => <Field type="textarea" label={t('field.lyrics.label')} className="col-span-2" errors={errors.lyrics} maxLength={5000} placeholder={t('field.lyrics.placeholder')} {...field} />} data-sentry-element="Controller" data-sentry-source-file="PushProForm.jsx" />

      <Controller name="typology" control={control} rules={{
      required: true
    }} render={({
      field
    }) => <Field isDisabled={disabled} type="radio-group" label={t('field.type.pushPro.label')} optionsContainerClasses="grid grid-cols-1 md:grid-cols-2 gap-3 w-full" className="col-span-2" direction="column" errors={errors.typology} options={PUSH_PRO_FEEDBACK} {...field} />} data-sentry-element="Controller" data-sentry-source-file="PushProForm.jsx" />

      <Controller name="artistBio" control={control} disabled={disabled} rules={{
      required: true
    }} render={({
      field
    }) => <Field type="textarea" maxLength={1000} label={t('field.artistBio.label')} className="col-span-2" errors={errors.artistBio} placeholder={t('field.artistBio.placeholder')} {...field} />} data-sentry-element="Controller" data-sentry-source-file="PushProForm.jsx" />

      <Controller name="notes" control={control} disabled={disabled} rules={{
      required: true
    }} render={({
      field
    }) => <Field type="textarea" maxLength={500} label={t('field.push_pro_request.label')} errors={errors.notes} placeholder={t('field.push_pro_request.placeholder')} className="col-span-2" {...field} />} data-sentry-element="Controller" data-sentry-source-file="PushProForm.jsx" />

      <Button type="submit" loading={loading} label={t('common.actions.send', {
      entity: 'request'
    })} className="col-span-2" disabled={disabled} data-sentry-element="Button" data-sentry-source-file="PushProForm.jsx" />
    </form>;
};
export default PushProForm;