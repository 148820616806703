import { useInfiniteQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { matchfyApi } from '@/api';
import { PushProTableItem } from '@/components/PushPro';
import Button from '@/components/ui/Button';
import Empty from '@/components/ui/Empty';
import Spinner from '@/components/ui/Spinner';
import { ROUTES_DATA, QUERIES } from '@/data';
import { useSession, useTranslations } from '@/hooks';
import { usePathname } from '@/i18n/routing';
import { cn } from '@/utils';
dayjs.extend(relativeTime);
export const PushProTable = ({
  type,
  className
}) => {
  const {
    data: session
  } = useSession();
  const pathname = usePathname();
  const t = useTranslations();
  const {
    ref,
    inView
  } = useInView({
    threshold: 0
  });
  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery({
    queryKey: [QUERIES.CHAT.PUSH_PRO, session?.user?.spotifyId, type, pathname],
    queryFn: async params => {
      const res = await matchfyApi.listPushPro(params, type);
      return res;
    },
    getNextPageParam: lastPage => {
      if (!lastPage?.nextPage) return;
      return lastPage.nextPage;
    },
    enabled: !!session?.user?.spotifyId
  });
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);
  const pushProItems = data?.pages?.map(page => page.data).flat() || [];
  const submitterHeader = type === 'sent' ? {
    label: t('common.labels.curator', {
      count: 1
    }),
    key: 'curator'
  } : {
    label: t('playlist.queue.metadata.submitted_by', {
      count: 1
    }),
    key: 'user'
  };
  const tableHeaders = [{
    label: t('field.track.name.label'),
    key: 'track-name',
    className: 'text-left'
  }, {
    label: t('field.track.type.label'),
    key: 'track-type',
    className: 'text-left'
  }, {
    label: t('common.labels.artist', {
      count: 1
    }),
    key: 'artist',
    className: 'text-left'
  }, {
    label: t('common.metrics.status'),
    key: 'status',
    className: 'text-left'
  }, {
    label: submitterHeader.label,
    key: submitterHeader.key,
    className: 'text-left'
  }, {
    label: t('payment.common.gems'),
    key: 'gems',
    className: 'text-left'
  }, {
    label: t('field.expires_in.label'),
    key: 'expires-in',
    className: 'text-right'
  }, {
    label: t('common.actions.title'),
    key: 'actions',
    className: 'text-right'
  }];
  return <div className={cn('table-responsive', className)} data-sentry-component="PushProTable" data-sentry-source-file="PushProTable.jsx">
      {isLoading && <div className="flex items-center justify-center w-full py-4">
          <Spinner inline />
        </div>}

      {!isLoading && pushProItems?.length > 0 && <table className="table text-center table-responsive">
          <thead>
            <tr>
              {tableHeaders.map(header => <th className={cn('text-left', header.className)} key={header.key}>
                  {header.label}
                </th>)}
            </tr>
          </thead>
          <tbody>
            {pushProItems.map(push => <PushProTableItem key={push._id} push={push} type={type} />)}
          </tbody>
        </table>}

      {!isLoading && pushProItems?.length === 0 && <Empty className="h-full">
          <p>{t(`empty_state.push_pro.${type}`)}</p>

          <Button href={type === 'sent' ? ROUTES_DATA.REVENUE_SHARE_ARTIST.path : ROUTES_DATA.REVENUE_SHARE_CURATOR.path} className="mt-5" label={t(ROUTES_DATA.REVENUE_SHARE_ARTIST.title)} />
        </Empty>}

      <div ref={ref} />
    </div>;
};
export default PushProTable;