import { useMutation } from '@tanstack/react-query';
import { matchfyApi } from '@/api';
import { DropdownMenuItem } from '@/components/ui/Dropdown/DropdownMenu';
import Icon from '@/components/ui/Icon';
import { useSession } from '@/hooks';
export default function DevRemoveArtist() {
  const {
    update
  } = useSession();
  const {
    mutate: removeArtist,
    isRemoving
  } = useMutation({
    mutationFn: async () => {
      await matchfyApi.devRemoveArtist();
      update();
    }
  });
  return <DropdownMenuItem disabled={isRemoving} loading={isRemoving} id="dev-remove-artist" onSelect={e => e.preventDefault()} onClick={() => removeArtist()} className="inline-flex items-center gap-2" label="Remove Artist" data-sentry-element="DropdownMenuItem" data-sentry-component="DevRemoveArtist" data-sentry-source-file="DevRemoveArtist.jsx">
      <Icon name="UserX" className="w-4 h-4" data-sentry-element="Icon" data-sentry-source-file="DevRemoveArtist.jsx" />
      <span>Remove Artist</span>
      <span className="px-1 ml-auto text-[0.6rem] tracking-wide uppercase border rounded-[0.2rem] text-muted-foreground border-muted-foreground">
        Dev
      </span>
    </DropdownMenuItem>;
}