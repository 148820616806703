'use client';

import NumberFlow from '@number-flow/react';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { CURRENCY } from '@/data';
export const CounterFlow = ({
  value,
  type = 'number',
  triggerOnce = false,
  startValue = 0,
  ...props
}) => {
  const [currentValue, setCurrentValue] = useState(startValue);
  const {
    ref,
    inView
  } = useInView({
    triggerOnce,
    threshold: 0.1
  });
  if (inView && currentValue !== value) {
    setCurrentValue(value);
  }
  const getConfig = () => {
    if (type === 'currency') {
      return {
        value: currentValue,
        format: {
          style: 'currency',
          currency: CURRENCY
        }
      };
    }
    if (type === 'percentage') {
      return {
        value: currentValue,
        suffix: '%'
      };
    }
    return {
      value: currentValue
    };
  };
  if (typeof value === 'string') {
    return <span ref={ref}>
        {value}
      </span>;
  }
  return <div ref={ref} data-sentry-component="CounterFlow" data-sentry-source-file="CounterFlow.jsx">
      <NumberFlow {...getConfig()} {...props} data-sentry-element="NumberFlow" data-sentry-source-file="CounterFlow.jsx" />
    </div>;
};
export default CounterFlow;