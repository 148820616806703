'use client';

import { useEffect } from 'react';
import MatchTrack from './MatchTrack';
import { ArtistConfig } from '@/components/Artist';
import { MatchStep1, MatchStep2, MatchSubmit } from '@/components/Match';
import Alert from '@/components/ui/Alert';
import { MATCH_TYPES } from '@/data';
import { useSession, useTranslations } from '@/hooks';
import { EVENTS, mxp } from '@/lib';
import { useMatchStore, usePushToPlaylistStore, useRecentMatchStore } from '@/store';
import { cn, isAgency } from '@/utils';
export const MatchForm = ({
  onSubmit,
  btnLabel = 'Match',
  btnVariant = 'gradient',
  type = MATCH_TYPES.SPOTIFY,
  compact = false,
  className
}) => {
  const t = useTranslations();
  const {
    genres,
    isMatching,
    setGenres,
    setIsMatching,
    setMatchType,
    setTrack,
    setTrackId,
    track,
    trackId,
    clearMatchValues
  } = useMatchStore();
  const {
    addRecentMatch
  } = useRecentMatchStore();
  const {
    pushedCount,
    resetPushedPlaylists
  } = usePushToPlaylistStore();
  const {
    data: session
  } = useSession();
  const {
    user
  } = session || {};
  const handleSubmit = e => {
    e.preventDefault();
    addRecentMatch({
      track,
      genres,
      type
    });
    setIsMatching(true);
    if (onSubmit) {
      onSubmit({
        track,
        genres
      });
    }
    mxp.track(EVENTS.MATCH_QUERY, {
      track,
      genres
    });
    if (document.querySelector('#match-results')) {
      document.querySelector('#match-results').scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  };
  useEffect(() => {
    setMatchType(type);
  }, [type]);
  useEffect(() => {
    setIsMatching(false);
    clearMatchValues();
  }, [trackId, genres]);
  const needsArtist = isAgency(user) ? false : user?.spotifyArtistIds?.length === 0;
  const classes = cn({
    'match-form': true,
    'agency': isAgency(user),
    'compact': compact,
    [className]: className
  });
  return <div className={classes} data-sentry-component="MatchForm" data-sentry-source-file="MatchForm.jsx">
      <ArtistConfig className="[grid-area:artist] md:col-span-2" data-sentry-element="ArtistConfig" data-sentry-source-file="MatchForm.jsx" />

      <MatchStep1 className="[grid-area:step1] h-full" track={track} trackId={trackId} setTrack={setTrack} setTrackId={setTrackId} setGenres={setGenres} pushedCount={pushedCount} resetPushedPlaylists={resetPushedPlaylists} user={user} data-sentry-element="MatchStep1" data-sentry-source-file="MatchForm.jsx" />

      <MatchStep2 className="[grid-area:step2] h-full" genres={genres} disabled={needsArtist} setGenres={setGenres} data-sentry-element="MatchStep2" data-sentry-source-file="MatchForm.jsx" />

      {!compact && <MatchTrack className="[grid-area:cover] hidden md:block" />}

      {needsArtist && <Alert variant={compact ? "primary" : "theme-primary"} className="text-center [grid-area:alert]" label={t('feedback.error.artist.missing')} />}

      <MatchSubmit className="[grid-area:submit]" disabled={!trackId || !genres?.length || needsArtist} loading={isMatching} btnVariant={btnVariant} btnLabel={btnLabel} onSubmit={handleSubmit} data-sentry-element="MatchSubmit" data-sentry-source-file="MatchForm.jsx" />
    </div>;
};
export default MatchForm;