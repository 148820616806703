import { truncate } from 'lodash-es';
import { PushProExpireBadge } from '@/components/PushPro';
import Badge, { BadgeStatus } from '@/components/ui/Badge';
import Button from '@/components/ui/Button';
import Emoji from '@/components/ui/Emoji';
import Image from '@/components/ui/Image';
import Placeholder from '@/components/ui/Placeholder';
import { generateRoute, ROUTES } from '@/data';
import { useTranslations } from '@/hooks';
import { Link } from '@/i18n/routing';
import { buildSpotifyTrackUrl, cn } from '@/utils';
export const PushProListItem = ({
  badgeSize = 'sm',
  push,
  hideBadges = false,
  hideGems = false,
  hideExpire = false,
  hideStatus = false,
  showCta = true,
  truncateLength = 32,
  className
}) => {
  const t = useTranslations();
  const classes = cn({
    'flex flex-col w-full text-sm rounded': true,
    [className]: className
  });
  return <div className={classes} data-sentry-component="PushProListItem" data-sentry-source-file="PushProListItem.jsx">
      <div className="flex items-end justify-between w-full gap-8">
        <div className="flex items-start gap-4">
          <Image src={push.track.image} alt={push.track.name} className="overflow-hidden rounded w-14 h-14" placeholder={<Placeholder className="rounded w-14 h-14" icon="Music" />} data-sentry-element="Image" data-sentry-source-file="PushProListItem.jsx" />

          <div className="flex flex-col items-start justify-between gap-1 h-14">
            <Link href={push?.trackId ? buildSpotifyTrackUrl(push.trackId) : push?.track?.link} target="_blank" className="text-sm font-medium" data-sentry-element="Link" data-sentry-source-file="PushProListItem.jsx">
              {truncate(push.track.name, {
              length: truncateLength
            })}
            </Link>

            {!hideBadges && <div className="flex flex-wrap items-center gap-1">
                {!hideGems && <Badge size={badgeSize} value={push.amount + push.feeAmount} variant="secondary-light" emoji={<Emoji symbol="💎" label="Gem" />} />}

                {!hideStatus && <BadgeStatus size={badgeSize} status={push.status} />}

                {!hideExpire && <PushProExpireBadge size={badgeSize} push={push} />}
              </div>}
          </div>
        </div>

        {showCta && <div className="flex items-center gap-3">
            <Button variant="light" size="xs" label="Chat" icon="MessageCircle" href={generateRoute(ROUTES.CHAT_DETAIL, {
          id: push.chatId
        })} />
          </div>}
      </div>
    </div>;
};
export default PushProListItem;