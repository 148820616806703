import { isEmpty } from 'lodash-es';
import { useState, useEffect } from 'react';
import Thumbnail from '@/components/ui/Thumbnail';
import { useMatchStore } from '@/store';
import { cn } from '@/utils';
function MatchTrack({
  className
}) {
  const [thumbnail, setThumbnail] = useState(null);
  const {
    track
  } = useMatchStore();
  useEffect(() => {
    if (isEmpty(track)) return;
    setThumbnail({
      url: track.external_urls.spotify,
      picture: track.album.images[0].url,
      title: track.name,
      description: track.album.name,
      owners: track.artists
    });
  }, [track]);
  const classes = cn(className);
  return <div className={classes} data-sentry-component="MatchTrack" data-sentry-source-file="MatchTrack.jsx">
      <Thumbnail entity={thumbnail} data-sentry-element="Thumbnail" data-sentry-source-file="MatchTrack.jsx" />
    </div>;
}
export default MatchTrack;