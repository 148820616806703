import React from 'react';
import Alert from '@/components/ui/Alert';
import { useTranslations, useSession } from '@/hooks';
export const PushProDisputeAlert = ({
  hasDispute,
  isProCurator,
  push
}) => {
  const {
    data: session
  } = useSession();
  const user = session?.user || {};
  const t = useTranslations();
  const finalEvent = push?.events?.find(event => event.type === push?.status);
  const winner = finalEvent?.payload?.winner;
  const reason = finalEvent?.payload?.description;
  const isWinner = winner === user?.spotifyId;
  if (winner && reason) {
    return <Alert size="sm" variant={isWinner ? 'blue' : 'red'} className="rounded-sm md:col-span-2 lg:col-span-4">
        <p className="m-0">
          {t('push_pro.dispute.resolved.default')}{'. '}
          {isWinner ? t('push_pro.dispute.resolved.won') : t('push_pro.dispute.resolved.lost')}{' '}
        </p>

        {reason && <p>
            {t('push_pro.dispute.resolved.reason')}{': '}
            {`"${reason}"`}
          </p>}
      </Alert>;
  }
  if (hasDispute) {
    return <Alert size="sm" variant="yellow" className="rounded-sm md:col-span-2 lg:col-span-4">
        {isProCurator ? t('push_pro.dispute.alert.curator') : t('push_pro.dispute.alert.artist')}
      </Alert>;
  }
  return null;
};
export default PushProDisputeAlert;