import Avatar from '@/components/ui/Avatar';
import Badge from '@/components/ui/Badge';
import Button from '@/components/ui/Button';
import Card from '@/components/ui/Card';
import { ROUTES } from '@/data';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
function ArtistCard({
  artist,
  compact = false,
  className
}) {
  const t = useTranslations();
  const artistUrl = `https://open.spotify.com/artist/${artist.id}`;
  const classes = cn({
    'border-outline rounded-md bg-secondary': true,
    'py-3 px-5': !compact,
    'py-2 px-3': compact,
    [className]: className
  });
  return <Card className={classes} contentClasses="flex items-center gap-4" id="artist-card" data-sentry-element="Card" data-sentry-component="ArtistCard" data-sentry-source-file="ArtistCard.jsx">
      <div className="flex items-center gap-3">
        <a href={artistUrl} target="_blank" rel="noreferrer">
          <Avatar src={artist.images[0].url} alt={artist.name} className="rounded-full avatar" size={compact ? 'sm' : undefined} data-sentry-element="Avatar" data-sentry-source-file="ArtistCard.jsx" />
        </a>

        <div className="flex flex-col">
          {artist.name && <a target="_blank" rel="noopener noreferrer" className="font-medium" href={artistUrl}>
              {artist.name}
            </a>}

          {artist.popularity && !compact && <Badge variant="light" icon="Star" className="mt-1" label={t('common.metrics.popularity')} value={`${artist.popularity}%`} />}
        </div>
      </div>

      <Button variant="ghost" icon="Settings" href={ROUTES.ARTISTS} size={compact ? 'sm' : undefined} data-sentry-element="Button" data-sentry-source-file="ArtistCard.jsx" />
    </Card>;
}
export default ArtistCard;