'use client';

import { AuthButton } from '@/components/Auth';
import Icon from '@/components/ui/Icon';
import { useTranslations } from '@/hooks';
import { usePathname } from '@/i18n/routing';
import { cn } from '@/utils';
export const Empty = ({
  size,
  horizontal,
  emoji,
  icon,
  login = false,
  ghost,
  message,
  variant,
  className,
  children
}) => {
  const pathname = usePathname();
  const t = useTranslations();
  const classes = cn({
    'empty-state': true,
    'empty-state-horizontal': horizontal,
    'empty-state-ghost': ghost,
    [`empty-state-${variant}`]: variant,
    [size]: size,
    [className]: className
  });
  return <div className={classes} data-sentry-component="Empty" data-sentry-source-file="Empty.jsx">
      {emoji && emoji}
      {!emoji && !icon && <Icon name="Frown" />}
      {icon && <Icon name={icon} />}

      {!message && !children && <p>
          {t('empty_state.content.generic.no_data')}
        </p>}

      {message && <p>{message}</p>}

      {children && children}

      {login && <AuthButton redirect={pathname} className="mt-4" label={t('navigation.login.continue')} variant="primary" />}
    </div>;
};
export default Empty;