import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { PushProExpireBadge } from '@/components/PushPro';
import Avatar from '@/components/ui/Avatar';
import Badge, { BadgeStatus } from '@/components/ui/Badge';
import Button from '@/components/ui/Button';
import Emoji from '@/components/ui/Emoji';
import Image from '@/components/ui/Image';
import Placeholder from '@/components/ui/Placeholder';
import { generateRoute, ROUTES } from '@/data';
import { useTranslations } from '@/hooks';
import { Link } from '@/i18n/routing';
import { buildSpotifyArtistUrl, buildSpotifyTrackUrl } from '@/utils';
dayjs.extend(relativeTime);
export const PushProTableItem = ({
  push,
  type
}) => {
  const isUnreleased = push.trackType === 'unreleased';
  const t = useTranslations();
  return <tr data-sentry-component="PushProTableItem" data-sentry-source-file="PushProTableItem.jsx">
      <td>
        <Link href={push?.trackId ? buildSpotifyTrackUrl(push.trackId) : push?.track?.link} target="_blank" className="flex items-center gap-3 text-sm text-left" data-sentry-element="Link" data-sentry-source-file="PushProTableItem.jsx">
          <Image src={push.track.image} alt={push.track.name} className="w-10 h-10 overflow-hidden rounded" placeholder={<Placeholder className="w-10 h-10 rounded" icon="Music" />} data-sentry-element="Image" data-sentry-source-file="PushProTableItem.jsx" />

          {push.track.name}
        </Link>
      </td>

      <td className="text-left">
        <Badge size="sm" variant={isUnreleased ? 'red-light' : 'green-light'} label={isUnreleased ? t('field.track.type.options.unreleased.label') : t('field.track.type.options.released.label')} data-sentry-element="Badge" data-sentry-source-file="PushProTableItem.jsx" />
      </td>

      <td className="text-left">
        <Badge size="sm" target="_blank" rel="noopener noreferrer" icon="MicVocal" variant="light" href={buildSpotifyArtistUrl(push?.artist?.artistId)} label={push?.artist?.artistName} data-sentry-element="Badge" data-sentry-source-file="PushProTableItem.jsx" />
      </td>

      <td className="text-left">
        <BadgeStatus size="sm" status={push.status} data-sentry-element="BadgeStatus" data-sentry-source-file="PushProTableItem.jsx" />
      </td>

      <td className="text-left">
        <Link href={generateRoute(ROUTES.CURATOR_DETAIL, {
        id: push?.user?.spotifyId
      })} target="_blank" className="flex items-center gap-2 text-sm" data-sentry-element="Link" data-sentry-source-file="PushProTableItem.jsx">
          <Avatar size="xs" src={type === 'sent' ? push?.curator?.avatar : push?.user?.avatar} alt={type === 'sent' ? push?.curator?.spotifyUsername ?? push?.curator?.spotifyId : push?.user?.spotifyUsername ?? push?.user?.spotifyId} data-sentry-element="Avatar" data-sentry-source-file="PushProTableItem.jsx" />
          <span>
            {type === 'sent' ? push?.curator?.spotifyUsername ?? push?.curator?.spotifyId : push?.user?.spotifyUsername ?? push?.user?.spotifyId}
          </span>
        </Link>
      </td>

      <td className="text-left">
        <Badge size="sm" label={push?.amount + push?.feeAmount} variant="secondary-light" emoji={<Emoji symbol="💎" label="Gem" />} data-sentry-element="Badge" data-sentry-source-file="PushProTableItem.jsx" />
      </td>

      <td className="text-right">
        <PushProExpireBadge push={push} data-sentry-element="PushProExpireBadge" data-sentry-source-file="PushProTableItem.jsx" />
      </td>

      <td className="text-right">
        <Button variant="light" size="sm" label="Chat" icon="MessageCircle" href={generateRoute(ROUTES.CHAT_DETAIL, {
        id: push.chatId
      })} data-sentry-element="Button" data-sentry-source-file="PushProTableItem.jsx" />
      </td>
    </tr>;
};
export default PushProTableItem;