import Avatar from '@/components/ui/Avatar';
import Badge, { BadgesList } from '@/components/ui/Badge';
import { ROUTES } from '@/data';
import { useTranslations } from '@/hooks';
import { Link } from '@/i18n/routing';
import { cn } from '@/utils';
const CuratorListItem = ({
  curator,
  children,
  titleTag = 'h3',
  className,
  featured = false,
  star = false,
  pro = false,
  ...props
}) => {
  const t = useTranslations();
  const classes = cn({
    'flex gap-4 rounded-md p-3': true,
    'bg-secondary border-outline': !featured && !star && !pro,
    'curator-card-featured': featured,
    'curator-card-star': star,
    'curator-card-pro': pro,
    [className]: className
  });
  const Heading = titleTag;
  return <Link href={`${ROUTES.COMMUNITY}/${curator.id}`} className={classes} {...props} data-sentry-element="Link" data-sentry-component="CuratorListItem" data-sentry-source-file="CuratorListItem.jsx">
      <Avatar type={curator?.type} src={curator.images} size="md" className="rounded-full" alt={curator?.name ? curator.name : curator?.id} data-sentry-element="Avatar" data-sentry-source-file="CuratorListItem.jsx" />

      <div className="flex flex-col items-start justify-start w-full gap-1">
        <Heading className="inline-flex flex-col items-start w-full gap-1 m-0 text-lg md:justify-between md:gap-3 md:items-center md:flex-row" data-sentry-element="Heading" data-sentry-source-file="CuratorListItem.jsx">
          <span className="text-base font-semibold md:text-lg">
            {curator?.name ?? curator?.id}
          </span>

          {curator.companyName && <Badge size="sm" icon="AtSign" variant="light" className="h-[32px]" label={curator?.companyName} />}
        </Heading>

        <div className="flex flex-wrap items-start gap-1 md:justify-between md:gap-2">
          {curator.roles?.length > 0 && <BadgesList size="sm" icon="BriefcaseBusiness" maxVisible={1} hiddenVariant={star ? 'gold-light' : 'primary-light'} tags={curator?.roles.map(role => t(`role.${role}`))} />}

          {curator.tags?.length > 0 && <BadgesList size="sm" icon="Tag" maxVisible={1} hiddenVariant={star ? 'gold-light' : 'primary-light'} tags={curator?.tags} />}

          {curator?.followers > 0 && <Badge size="sm" icon="UsersRound" variant="light" value={curator?.followers || `0`} />}
        </div>
      </div>

      {children}
    </Link>;
};
export default CuratorListItem;