import { useQuery } from '@tanstack/react-query';
import { matchfyApi } from '@/api';
import { ProFeatureAlert } from '@/components/ProFeature';
import { Accordion, AccordionItem } from '@/components/ui/Accordion';
import Avatar from '@/components/ui/Avatar';
import { BadgesList, Badge } from '@/components/ui/Badge';
import Card from '@/components/ui/Card';
import Empty from '@/components/ui/Empty';
import Spinner from '@/components/ui/Spinner';
import { QUERIES } from '@/data';
import { useTranslations, useSession } from '@/hooks';
import { buildSpotifyArtistUrl, cn, nFormatter, isFree } from '@/utils';
export function UserArtists({
  userId,
  className
}) {
  const t = useTranslations();
  const {
    data: session
  } = useSession();
  const {
    user
  } = session || {};
  const isOwner = user.id === userId;
  const {
    data,
    isLoading
  } = useQuery({
    queryKey: [QUERIES.CURATOR.ARTISTS, userId],
    queryFn: () => matchfyApi.getCuratorArtists(userId)
  });
  return <Accordion data-sentry-element="Accordion" data-sentry-component="UserArtists" data-sentry-source-file="UserArtists.jsx">
      <AccordionItem id="user-artists" icon="Headphones" label={t('curator.widgets.artists')} data-sentry-element="AccordionItem" data-sentry-source-file="UserArtists.jsx">
        <div className={cn('curator-header', className)}>
          {isLoading && <div className="flex items-center justify-center">
              <Spinner />
            </div>}

          {isFree(user) && !isOwner && <ProFeatureAlert className="mb-6" />}

          {data && data.length > 0 && <div className={cn({
          "grid grid-cols-1 gap-y-6 gap-x-8 md:grid-cols-2": true,
          "blur-[3px] pointer-events-none": isFree(user) && !isOwner
        })}>
              {data.map(artist => <Card key={artist.artistId}>
                  <div className="flex items-start gap-3">
                    <a href={buildSpotifyArtistUrl(artist.artistId)} target="_blank" rel="noreferrer">
                      <Avatar size="md" src={artist.image} alt={artist.name} className="rounded-full avatar" />
                    </a>

                    <div className="flex flex-col gap-1">
                      {artist.name && <a target="_blank" rel="noopener noreferrer" className="font-medium" href={buildSpotifyArtistUrl(artist.artistId)}>
                          <h3 className="mb-0 text-base font-medium">
                            {artist.name}
                          </h3>
                        </a>}

                      <div className="flex flex-wrap gap-1">
                        {artist.analysis?.popularity ? <Badge size="sm" variant="light" icon="Star" label={t('common.metrics.popularity')} value={`${artist.analysis.popularity}%`} /> : null}

                        {artist.analysis?.followers ? <Badge size="sm" variant="light" icon="UsersRound" label={t('common.metrics.followers')} value={nFormatter(artist.analysis.followers)} /> : null}

                        {artist.analysis?.monthlyListeners ? <Badge size="sm" variant="light" icon="AudioLines" label={t('common.metrics.monthly_listeners')} value={nFormatter(artist.analysis.monthlyListeners)} /> : null}
                      </div>

                      {artist.analysis?.genres && <BadgesList size="sm" tags={artist.analysis.genres} variant="primary-light" className="mt-1" />}
                    </div>
                  </div>
                </Card>)}
            </div>}

          {!isLoading && data?.length === 0 && <Empty horizontal className="w-full py-10 my-0 border-0" />}
        </div>
      </AccordionItem>
    </Accordion>;
}
export default UserArtists;