'use client';

import { ResponsivePie } from '@nivo/pie';
import { isMobile } from 'react-device-detect';
import { ChartLegend } from './ChartLegend';
import { createColorScale } from './helpers';
import { cn } from '@/utils';
export const PieChart = ({
  chartData,
  primaryColor = '#5e4af9',
  arcLabelsTextColor = 'transparent',
  useDataColors,
  enableArcLinkLabels = true,
  enableArcLabels = false,
  legendPosition = 'bottom',
  legendClasses,
  innerRadius = 0.7,
  className,
  mobileClasses,
  t,
  margin = {
    top: 80,
    right: 80,
    bottom: 80,
    left: 80
  }
}) => {
  const chartColor = primaryColor;
  const colorScale = createColorScale(chartColor, 10);
  const classes = cn({
    'relative chart-pie': true,
    [className]: className,
    [mobileClasses]: mobileClasses && isMobile
  });
  return <div className={classes} data-sentry-component="PieChart" data-sentry-source-file="PieChart.jsx">
      <ResponsivePie data={chartData} margin={margin} innerRadius={innerRadius} padAngle={1} cornerRadius={4} activeOuterRadiusOffset={8} borderWidth={0} arcLinkLabelsSkipAngle={5} enableArcLinkLabels={enableArcLinkLabels} arcLinkLabelsThickness={2} arcLinkLabelsColor={{
      from: 'color'
    }} arcLabelsTextColor={arcLabelsTextColor} enableArcLabels={enableArcLabels} arcLinkLabel={d => `${t ? t(`common.states.${d.id}`) : d.id} (${d.formattedValue})`} activeInnerRadiusOffset={8} layers={['arcs', 'arcLabels', 'arcLinkLabels']} arcLabelsSkipAngle={2} colors={useDataColors ? {
      datum: 'data.color'
    } : colorScale} animate={true} theme={{
      labels: {
        text: {
          fontSize: 16,
          fontWeight: 600
        }
      }
    }} tooltip={({
      datum: {
        data
      }
    }) => {
      if (!data) return;
      return <div className="chart-tooltip">
              <div className="px-3 py-2 bg-popover">
                <span>
                  <b>{data.label}</b>: {data.value}
                </span>
              </div>
            </div>;
    }} data-sentry-element="ResponsivePie" data-sentry-source-file="PieChart.jsx" />

      <ChartLegend data={chartData} position={legendPosition} className={legendClasses} data-sentry-element="ChartLegend" data-sentry-source-file="PieChart.jsx" />
    </div>;
};
export default PieChart;