import Development from '@/components/Development';
export default function DevMatchInfo({
  isFetching,
  isLoading,
  hasMore,
  fetchedPages,
  fetchLimit,
  batchNumber,
  total,
  lastPage,
  pushedCount
}) {
  const rows = [{
    label: 'Is Fetching',
    value: isFetching ? 'y' : 'n'
  }, {
    label: 'Is Loading',
    value: isLoading ? 'y' : 'n'
  }, {
    label: 'Has More',
    value: hasMore ? 'y' : 'n'
  }, {
    label: 'Fetched Pages',
    value: fetchedPages
  }, {
    label: 'Fetch limit',
    value: fetchLimit
  }, {
    label: 'Batch number',
    value: batchNumber
  }, {
    label: 'Total items',
    value: total
  }, {
    label: 'Last page',
    value: lastPage
  }, {
    label: 'Sent count',
    value: pushedCount ?? 0
  }];
  return <Development data-sentry-element="Development" data-sentry-component="DevMatchInfo" data-sentry-source-file="DevMatchInfo.jsx">
      <pre>
        {rows.map(row => <div key={row.label}>
            {row.label}: {row.value}
          </div>)}
      </pre>
    </Development>;
}