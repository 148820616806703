'use client';

import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import PushProForm from './PushProForm';
import { matchfyApi } from '@/api';
import { Upgrade } from '@/components/Account/Upgrade';
import Auth from '@/components/Auth';
import Alert from '@/components/ui/Alert';
import Avatar from '@/components/ui/Avatar';
import { BadgesList } from '@/components/ui/Badge';
import Button from '@/components/ui/Button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/Dialog';
import Empty from '@/components/ui/Empty';
import { generateRoute, PLANS, PUSH_PRO_MATCHFY_COMMISSION, ROUTES } from '@/data';
import { useAnalytics, useTranslations, useSession, useLocale } from '@/hooks';
import { useRouter, usePathname } from '@/i18n/routing';
import { toast } from '@/lib';
import { useArtistStore, useMatchStore, usePushProFormStore } from '@/store';
import { isFree, formatNumber } from '@/utils';
function PushPro({
  size,
  disabled,
  curatorId,
  curatorName,
  curatorAvatar,
  curatorType,
  curatorRoles,
  feedbackPrice,
  className,
  btnProps,
  variant = 'gradient-secondary'
}) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const {
    pushPro,
    setCuratorId,
    setPushPro,
    setSuccess,
    success,
    resetStatus
  } = usePushProFormStore();
  const {
    artists,
    fetchArtists
  } = useArtistStore();
  const {
    trackId: matchTrackId,
    genres: matchGenres
  } = useMatchStore();
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const {
    data: session,
    update
  } = useSession();
  const user = session?.user;
  const {
    gtagEvent
  } = useAnalytics();
  const t = useTranslations();
  const {
    locale
  } = useLocale();
  const notEnoughGems = !user?.referral?.gems || user?.referral?.gems <= 0 || user?.referral?.gems < totalPrice;
  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
    const params = new URLSearchParams(searchParams.toString());
    params.set('push-pro', curatorId);
    router.push(`${pathname}?${params.toString()}`);
  };
  const handleOpenChange = open => {
    if (!open) {
      const params = new URLSearchParams(searchParams.toString());
      params.delete('push-pro');
      resetStatus();
      router.push(`${pathname}?${params.toString()}`);
    }
    setOpen(open);
  };
  const pushHandler = async data => {
    setLoading(true);
    try {
      const formData = {
        ...(data.trackType === "unreleased" ? {
          unreleased: {
            track: {
              name: data.trackName,
              link: data.trackLink
            }
          }
        } : {
          released: {
            trackId: data.trackId,
            track: {
              name: data.trackName,
              image: data.track.album.images[0].url
            }
          }
        }),
        trackType: data.trackType,
        artist: {
          artistId: data.artistId,
          artistName: data.artistName
        },
        info: {
          bio: data.artistBio,
          genres: data.genres,
          lyrics: data.lyrics,
          typology: data.typology,
          notes: data.notes
        },
        curatorId: curatorId
      };
      const pushResponse = await matchfyApi.createPushPro(formData);
      setPushPro(pushResponse);

      // Prefetch the chat detail page
      const chatDetail = generateRoute(ROUTES.CHAT_DETAIL, {
        id: pushResponse.chatId
      });
      router.prefetch(chatDetail);
      gtagEvent({
        category: 'Push Pro',
        action: 'Push Pro'
      });
      toast('success', t('push_pro.success.title'));
      update();
      setSuccess(true);
    } catch (error) {
      console.error(error);
      toast('error', t('push_pro.error.generic'));
    } finally {
      setLoading(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setSuccess(false);
  };
  const handleChatRedirect = async () => {
    if (!pushPro?.chatId) return;
    const chatDetail = generateRoute(ROUTES.CHAT_DETAIL, {
      id: pushPro.chatId
    });
    await router.push(chatDetail);
    setSuccess(false);
  };
  useEffect(() => {
    if (user?.spotifyArtistIds?.length && !artists.length) {
      fetchArtists(user.spotifyArtistIds);
    }
  }, [artists, user?.spotifyArtistIds]);
  useEffect(() => {
    const pushProParam = searchParams.get('push-pro');
    if (pushProParam === curatorId) {
      setOpen(true);
    }
  }, [searchParams, curatorId]);
  useEffect(() => {
    if (feedbackPrice) {
      const grossAmount = Math.floor(feedbackPrice / (1 - PUSH_PRO_MATCHFY_COMMISSION));
      setTotalPrice(grossAmount);
    }
  }, [feedbackPrice]);
  useEffect(() => {
    if (curatorId) {
      setCuratorId(curatorId);
    }
  }, [curatorId]);
  const PushSuccess = () => {
    return <>
        <Empty className="py-10 my-0" variant="blue" icon="CircleCheck" message={t('push_pro.success.description')} data-sentry-element="Empty" data-sentry-source-file="PushPro.jsx" />

        <DialogFooter className="flex items-center justify-between mt-4" data-sentry-element="DialogFooter" data-sentry-source-file="PushPro.jsx">
          <Button variant="light" className="w-full" label={t('common.actions.close')} onClick={handleClose} data-sentry-element="Button" data-sentry-source-file="PushPro.jsx" />

          <Button icon="ArrowRight" variant="gradient-secondary" className="w-full" label={t('chat.actions.open_chat')} onClick={handleChatRedirect} data-sentry-element="Button" data-sentry-source-file="PushPro.jsx" />
        </DialogFooter>
      </>;
  };
  return <>
      <Button disabled={disabled} size={size} variant={variant} className={className} icon="ArrowUpFromLine" label={t('push_pro.button.label')} onClick={handleClick} {...btnProps} data-sentry-element="Button" data-sentry-source-file="PushPro.jsx" />

      <Dialog open={open} onOpenChange={handleOpenChange} data-sentry-element="Dialog" data-sentry-source-file="PushPro.jsx">
        <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="PushPro.jsx">
          <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="PushPro.jsx">
            <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="PushPro.jsx">
              {t('push_pro.title')}
            </DialogTitle>
          </DialogHeader>

          {success ? <PushSuccess /> : <>
              <DialogDescription className="mb-3">
                {t('push_pro.description', {
              shouldParse: true
            })}
              </DialogDescription>

              <div className="flex justify-between gap-4 p-3 rounded-md border-outline">
                <div className="flex gap-4">
                  <Avatar type={curatorType} src={curatorAvatar} size="md" className="rounded-full" alt={curatorName} />

                  <div className="flex flex-col items-start justify-start w-full gap-1">
                    <h3 className="inline-flex flex-col items-start w-full gap-1 m-0 text-lg md:justify-between md:gap-3 md:items-center md:flex-row">
                      <span className="text-base font-semibold md:text-lg">
                        {curatorName}
                      </span>
                    </h3>

                    {curatorRoles?.length > 0 && <div className="flex flex-wrap items-start gap-1 md:justify-between md:gap-2">
                        <BadgesList size="sm" icon="BriefcaseBusiness" maxVisible={2} hiddenVariant='primary-light' tags={curatorRoles.map(role => t(`role.${role}`))} />
                      </div>}
                  </div>
                </div>

                <div className="flex flex-col items-end justify-center h-full">
                  {totalPrice && <span className="text-xl font-bold text-green">
                      {formatNumber(totalPrice, locale)} 💎
                    </span>}

                  <span className="block mt-1 text-xs opacity-70">
                    {t('push_pro.feedback.price.message')}
                  </span>
                </div>
              </div>

              {notEnoughGems && <Alert inline variant="secondary-bg" className="mt-4" label={t('push_pro.error.not_enough_gems.label')} cta={<Button size="sm" label={t('push_pro.error.not_enough_gems.cta')} href={ROUTES.REVENUE_SHARE_ARTIST} variant="blue" />} />}

              <Auth login emptyState>
                {isFree(user) && <Upgrade btnVariant="gradient" className="mt-4" message={t('paywall.push_pro')} plan={PLANS.ARTIST} emptyStateProps={{
              className: 'm-0'
            }} />}

                {!isFree(user) && <PushProForm className="mt-8" trackId={matchTrackId} genres={matchGenres} disabled={notEnoughGems} user={user} onSubmit={pushHandler} loading={loading} />}
              </Auth>
            </>}
        </DialogContent>
      </Dialog>
    </>;
}
export default PushPro;