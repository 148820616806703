'use client';

import { useSearchParams, useRouter } from 'next/navigation';
import { useEffect } from 'react';
import Button from '@/components/ui/Button';
import { CHAT_FILTERS } from '@/data';
import { useSession, useTranslations } from '@/hooks';
import { useChatStore } from '@/store';
import { cn } from '@/utils';
export const ChatFilters = ({
  className
}) => {
  const {
    data: session
  } = useSession();
  const {
    user
  } = session || {};
  const t = useTranslations();
  const {
    filter,
    resetChats,
    setFilter
  } = useChatStore();
  const router = useRouter();
  const searchParams = useSearchParams();
  const CHAT_FILTERS_OPTIONS = [{
    id: CHAT_FILTERS.ALL,
    label: 'chat.filters.all'
  }, {
    id: CHAT_FILTERS.UNREAD,
    label: 'chat.filters.unread'
  }, {
    id: CHAT_FILTERS.PRO,
    label: 'chat.filters.pro'
  }];
  useEffect(() => {
    const filterParam = searchParams.get('filter');
    if (filterParam && CHAT_FILTERS_OPTIONS.some(f => f.id === filterParam) && filterParam !== filter) {
      setFilter(filterParam);
    }
  }, []);
  const handleFilterClick = filter => {
    setFilter(filter);
    const params = new URLSearchParams(searchParams);
    if (filter === 'all') params.delete('filter');else params.set('filter', filter);
    router.push(`?${params.toString()}`);
  };
  const classes = cn({
    'flex flex-row gap-2': true,
    [className]: className
  });
  return <div className={classes} data-sentry-component="ChatFilters" data-sentry-source-file="ChatFilters.jsx">
      {CHAT_FILTERS_OPTIONS.map(option => <Button key={option.id} size="sm" className="rounded-full" variant={option.id === filter ? 'inverse' : 'light'} onClick={() => handleFilterClick(option.id)}>
          {t(option.label)}
        </Button>)}
    </div>;
};
export default ChatFilters;