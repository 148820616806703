'use client';

import { useEffect, useState } from 'react';
import { ArtistForm } from '@/components/Artist';
import Alert from '@/components/ui/Alert';
import Field from '@/components/ui/Field';
import { CustomImageOption, CustomImageSingleValue } from '@/components/ui/Field/SelectComponents';
import { useSession, useTranslations } from '@/hooks';
import { useArtistStore } from '@/store';
import { cn } from '@/utils';
export const ArtistSelect = ({
  onChange,
  className
}) => {
  const [artist, setArtist] = useState(null);
  const [artistId, setArtistId] = useState(null);
  const [artistOptions, setArtistOptions] = useState([]);
  const {
    artists
  } = useArtistStore();
  const t = useTranslations();
  const {
    update
  } = useSession();
  useEffect(() => {
    if (!artistId && artists?.length > 0) {
      setArtistId(artists[0].id);
      if (onChange) {
        onChange(artists[0]);
      }
    }
  }, [artists]);
  useEffect(() => {
    if (artists.length > 0) {
      const options = artists.map(artist => ({
        image: artist?.images[0].url,
        value: artist?.id,
        label: artist?.name
      }));
      setArtistOptions(options);
      setArtist(options[0]);
    }
  }, [artists]);
  const handleChange = artist => {
    const artistId = artist.value;
    const artistData = artists.find(artist => artist.id === artistId);
    if (!artistData) return;
    setArtist(artist);
    setArtistId(artistData.id);
    if (onChange) {
      onChange(artistData);
    }
  };
  const classes = cn({
    [className]: className
  });
  if (artistOptions.length === 0) {
    return <div className={classes}>
        <Alert className="mb-4" variant="orange">
          {t('wanted.no_artists')}
        </Alert>

        <ArtistForm btnVariant="orange" searchInputClasses="w-full md:w-[400px]" onArtistSelect={() => update()} />
      </div>;
  }
  return <div className={classes} data-sentry-component="ArtistSelect" data-sentry-source-file="ArtistSelect.jsx">
      <Field type="select" isClearable={false} options={artistOptions} value={artist} defaultValue={artistOptions[0]} isDisabled={artistOptions.length === 1} components={{
      Option: CustomImageOption,
      SingleValue: CustomImageSingleValue
    }} onChange={handleChange} imageClasses="w-8 h-8 rounded-full" data-sentry-element="Field" data-sentry-source-file="ArtistSelect.jsx" />
    </div>;
};
export default ArtistSelect;