'use client';

import { useEffect, useState } from 'react';
import Select from 'react-select';
import CopyToClipboard from '@/components/ui/CopyToClipboard';
import { LANGUAGES, MATCH_MESSAGES } from "@/data";
import { useTranslations } from '@/hooks';
import { useMatchStore } from '@/store';
import { cn } from '@/utils';
export const parseMatchText = html => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const paragraphs = doc.querySelectorAll('p');
  paragraphs.forEach(p => {
    p.parentNode.insertBefore(document.createTextNode('\n\n'), p);
    p.parentNode.replaceChild(document.createTextNode(p.textContent), p);
  });
  return doc.body.textContent.trim();
};
function MatchMessage({
  className,
  curatorName,
  playlist,
  score,
  typology,
  userName
}) {
  const t = useTranslations();
  const [message, setMessage] = useState('');
  const [copyMessage, setCopyMessage] = useState('');
  const {
    matchLocale,
    setMatchLocale,
    track
  } = useMatchStore();
  const changeHandler = (option, {
    action
  }) => {
    if (action !== 'select-option') {
      return;
    }
    setMatchLocale(option.value);
  };
  useEffect(() => {
    const trackLink = `<a href="${track?.external_urls?.spotify}" target="_blank" rel="noopener noreferrer">${track?.external_urls?.spotify}</a>`;
    const translation = MATCH_MESSAGES[matchLocale]?.[typology];
    if (translation) {
      const matchMessage = translation.replaceAll('{trackLink}', trackLink).replaceAll('{curatorName}', curatorName).replaceAll('{playlistName}', playlist?.name).replaceAll('{score}', score).replaceAll('{userName}', userName);
      setMessage(matchMessage);
      setCopyMessage(parseMatchText(matchMessage));
    }
  }, [matchLocale, score, typology]);
  const languageOptions = LANGUAGES.map(language => ({
    value: language.value,
    label: t(`languages.${language.value}`)
  }));
  const classes = cn({
    'match-message': true,
    [className]: className
  });
  return <div className={classes} data-sentry-component="MatchMessage" data-sentry-source-file="MatchMessage.jsx">
      <div className="mb-3 match-message-header">
        <Select value={languageOptions.find(({
        value
      }) => value === matchLocale)} options={languageOptions} onChange={changeHandler} className="select select-theme-primary" classNamePrefix="select" isSearchable={false} data-sentry-element="Select" data-sentry-source-file="MatchMessage.jsx" />

        <CopyToClipboard variant="theme-primary-light" label={t('common.actions.copy')} text={copyMessage} data-sentry-element="CopyToClipboard" data-sentry-source-file="MatchMessage.jsx" />
      </div>

      {message && <div className="match-message-body" dangerouslySetInnerHTML={{
      __html: message
    }} />}
    </div>;
}
export default MatchMessage;