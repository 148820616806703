'use client';

import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { matchfyApi } from '@/api';
import Genres from '@/components/Genres';
import { ProFeature } from '@/components/ProFeature';
import { Accordion, AccordionItem } from '@/components/ui/Accordion';
import Badge from '@/components/ui/Badge';
import Button from '@/components/ui/Button';
import Field from '@/components/ui/Field';
import { PLANS, ROUTES } from '@/data';
import { useTranslations } from '@/hooks';
import { useRouter } from '@/i18n/routing';
import { useSearchStore } from '@/store';
import { cn } from '@/utils';
export const CuratorSearchForm = ({
  isLoading = false,
  className
}) => {
  const {
    isSearching,
    search,
    setIsSearching,
    setSearch
  } = useSearchStore();
  const router = useRouter();
  const t = useTranslations();
  useEffect(() => {
    return () => {
      setIsSearching(false);
    };
  }, []);
  const popularityOptions = [{
    label: t('field.curator.popularity.options.all'),
    value: {
      min: 0
    }
  }, {
    label: t('field.curator.popularity.options.niche'),
    value: {
      min: 0,
      max: 250
    }
  }, {
    label: t('field.curator.popularity.options.emerging'),
    value: {
      min: 250,
      max: 1000
    }
  }, {
    label: t('field.curator.popularity.options.established'),
    value: {
      min: 1000,
      max: 5000
    }
  }, {
    label: t('field.curator.popularity.options.wellknown'),
    value: {
      min: 5000,
      max: 10000
    }
  }, {
    label: t('field.curator.popularity.options.superstar'),
    value: {
      min: 10000
    }
  }];
  const typeOptions = [{
    label: t('field.curator.type.options.all'),
    value: 'all'
  }, {
    label: t('field.curator.type.options.featured'),
    value: 'featured'
  }, {
    label: t('field.curator.type.options.star'),
    value: 'star'
  }, {
    label: t('field.curator.type.options.pro'),
    value: 'pro'
  }];
  const {
    control,
    handleSubmit
  } = useForm({
    defaultValues: search
  });
  const handleFormSubmit = data => {
    setSearch(data);
    setIsSearching(true);
    router.push(ROUTES.SEARCH);
    if (document.querySelector('#search-results')) {
      document.querySelector('#search-results').scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  };
  const classes = cn({
    'grid grid-cols-1 md:grid-cols-4 gap-4': true,
    [className]: className
  });
  return <Accordion data-sentry-element="Accordion" data-sentry-component="CuratorSearchForm" data-sentry-source-file="CuratorSearchForm.jsx">
      <AccordionItem id="curator-search" defaultOpen={false} className="overflow-visible" contentClasses="overflow-visible" label={t('common.labels.search')} headerContent={<Badge variant="primary-light" label="Pro" className="font-bold uppercase" size="sm" />} data-sentry-element="AccordionItem" data-sentry-source-file="CuratorSearchForm.jsx">
        <form className={classes} onSubmit={handleSubmit(handleFormSubmit)}>
          <Controller name="roles" control={control} render={({
          field
        }) => <Field id="role" type="select" multi={true} maxLimit={3} className="md:col-span-2" label={t('field.role.label')} fetchFn={matchfyApi.listRoles} value={field.value} onChange={field.onChange} labelKey="name" valueKey="slug" translateKey="role" />} data-sentry-element="Controller" data-sentry-source-file="CuratorSearchForm.jsx" />

          <Controller name="tags" control={control} render={({
          field
        }) => <div className="form-group md:col-span-2">
                <label htmlFor="contact-genres">{t('field.genres.label')}</label>
                <Genres className="w-full" hideLabel={true} genres={field.value} maxLimit={5} onGenreSelect={field.onChange} />
              </div>} data-sentry-element="Controller" data-sentry-source-file="CuratorSearchForm.jsx" />

          <Controller name="companyName" control={control} render={({
          field
        }) => <Field id="contact-company-name" label={t('field.companyName.label')} {...field} />} data-sentry-element="Controller" data-sentry-source-file="CuratorSearchForm.jsx" />

          <Controller name="type" control={control} render={({
          field
        }) => <Field id="type" type="select" multi={false} label={t('field.curator.type.label')} options={typeOptions} value={typeOptions.find(option => option.value === field.value)} onChange={e => field.onChange(e.value)} />} data-sentry-element="Controller" data-sentry-source-file="CuratorSearchForm.jsx" />

          <Controller name="popularity" control={control} render={({
          field
        }) => <Field id="popularity" type="select" multi={false} label={t('field.curator.popularity.label')} options={popularityOptions} value={popularityOptions.find(option => JSON.stringify(option.value) === JSON.stringify(field.value))} onChange={e => field.onChange(e.value)} />} data-sentry-element="Controller" data-sentry-source-file="CuratorSearchForm.jsx" />

          <ProFeature allowedPlans={[PLANS.AGENCY]} suggestedPlan={PLANS.AGENCY} data-sentry-element="ProFeature" data-sentry-source-file="CuratorSearchForm.jsx">
            <div className="flex items-end justify-end h-full">
              <Button type="submit" className="h-[46px] w-full" loading={isLoading} icon="Search" label={t('common.labels.search')} data-sentry-element="Button" data-sentry-source-file="CuratorSearchForm.jsx" />
            </div>
          </ProFeature>
        </form>
      </AccordionItem>
    </Accordion>;
};
export default CuratorSearchForm;