'use client';

import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { matchfyApi } from '@/api';
import { Chat, ChatActive, ChatArchive, ChatArchiveToggle, ChatFilters } from '@/components/Chat';
import Spinner from '@/components/ui/Spinner';
import Wrapper from '@/components/ui/Wrapper';
import { ROUTES, QUERIES, generateRoute } from '@/data';
import { useRouter } from '@/i18n/routing';
import { useChatStore } from '@/store';
import { cn } from '@/utils';
export const ChatList = ({
  detail,
  id,
  mobile,
  user,
  className
}) => {
  const {
    activeSid,
    pushProId,
    setActiveChat,
    chatList,
    archiveList,
    setArchiveList,
    setActiveSid,
    setChatList,
    setPushProId,
    chatTotal,
    setChatTotal,
    totalReached,
    setTotalReached,
    showArchive,
    setShowArchive
  } = useChatStore();
  const router = useRouter();
  const {
    isFetchingNextPage,
    isLoading,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery({
    queryKey: [QUERIES.CHAT.LIST, user?.spotifyId],
    queryFn: async params => {
      const res = await matchfyApi.listChats(params, user?.spotifyId);
      setChatList(res?.data?.chats);
      setArchiveList(res?.data?.archive);
      setChatTotal(archiveList.length + chatList.length);
      if (chatTotal >= archiveList.length + chatList.length) {
        setTotalReached(true);
      }
      return res;
    },
    getNextPageParam: lastPage => {
      if (!lastPage?.nextPage) return undefined;
      return lastPage.nextPage;
    },
    enabled: !!user && !!user?.spotifyId
  });
  const {
    ref,
    inView
  } = useInView({
    threshold: 0
  });
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage && !totalReached) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage, isFetchingNextPage, totalReached]);
  useEffect(() => {
    if (chatList.length > 0 && id && activeSid && activeSid !== id || id && !activeSid) {
      const chat = chatList.find(chat => chat.sid === id);
      if (chat) {
        setActiveSid(id);
        setActiveChat(chat);
        if (pushProId !== chat?.pushPro?._id) {
          setPushProId(chat?.pushPro?._id);
        }
      }
    }
  }, [id, chatList]);
  const handleChatSelect = chat => {
    router.push(generateRoute(ROUTES.CHAT_DETAIL, {
      id: chat.sid
    }));
    setActiveChat(chat);
    setActiveSid(chat.sid);
  };
  const classes = cn({
    'chat-wrapper': true,
    'chat-detail': detail,
    'chat-list': !detail,
    'chat-mobile': mobile,
    [className]: className
  });
  return <Wrapper data-sentry-element="Wrapper" data-sentry-component="ChatList" data-sentry-source-file="ChatList.jsx">
      <h2 className="inline-flex mt-8 mb-4 text-2xl md:text-3xl lg:text-4xl">
        Chats
      </h2>

      <ChatFilters className="mb-4" data-sentry-element="ChatFilters" data-sentry-source-file="ChatList.jsx" />

      <div className={classes}>
        <div className="chat-sidebar">
          <ChatArchiveToggle showArchive={showArchive} setShowArchive={setShowArchive} total={archiveList.length} data-sentry-element="ChatArchiveToggle" data-sentry-source-file="ChatList.jsx" />

          {showArchive ? <div key="chat-archive" className="flex flex-col w-full h-full gap-1">
              {isLoading && <div className="flex items-center justify-center w-full py-8">
                  <Spinner inline />
                </div>}

              <ChatArchive archiveList={archiveList} activeSid={activeSid} showArchive={showArchive} setShowArchive={setShowArchive} handleChatSelect={handleChatSelect} chatList={chatList} isLoading={isLoading} />
            </div> : <div key="chat-list" className="w-full h-full">
              {isLoading && <div className="flex items-center justify-center w-full py-8">
                  <Spinner inline />
                </div>}

              <ChatActive activeSid={activeSid} showArchive={showArchive} handleChatSelect={handleChatSelect} chatList={chatList} isLoading={isLoading} />
            </div>}

          {!isLoading && <div ref={ref} className="w-full h-1" />}
        </div>

        <Chat activeSid={activeSid} detail={detail} user={user} mobile={mobile} data-sentry-element="Chat" data-sentry-source-file="ChatList.jsx" />
      </div>
    </Wrapper>;
};
export default ChatList;