'use client';

import { ChatStart } from '@/components/Chat';
import { PushPro } from '@/components/PushPro';
import Avatar from '@/components/ui/Avatar';
import { BadgesList } from '@/components/ui/Badge';
import { generateRoute, ROUTES, USER_TYPES } from '@/data';
import { useTranslations } from '@/hooks';
import { useRouter } from '@/i18n/routing';
import { cn, isPro, isStar } from '@/utils';
const CuratorCard = ({
  curator,
  children,
  titleTag = 'h3',
  className,
  featured = false,
  isOwner = false,
  star = false,
  pro = false,
  ...props
}) => {
  const router = useRouter();
  const t = useTranslations();
  const classes = cn({
    'card relative cursor-pointer': true,
    'curator-card': true,
    'curator-card-featured': featured,
    'curator-card-star': star,
    'curator-card-pro': pro,
    [className]: className
  });
  const getVariant = () => {
    if (isStar(curator?.type)) return 'gold-light';
    if (isPro(curator?.type)) return 'secondary-light';
    return 'light';
  };
  const handleClick = () => {
    router.push(generateRoute(ROUTES.CURATOR_ACTIVITY, {
      id: curator.id
    }));
  };
  const Heading = titleTag;
  return <div className={classes} {...props} data-sentry-component="CuratorCard" data-sentry-source-file="CuratorCard.jsx">
      <div className="absolute inset-0 z-10 cursor-pointer" onClick={handleClick} />
      <div className="relative card-content">
        <div className="flex items-start justify-between">
          <Avatar type={curator?.type ?? USER_TYPES.USER} src={curator.images} size="md" className="mb-4 rounded-full" alt={curator?.display_name ? curator.display_name : curator?.id} data-sentry-element="Avatar" data-sentry-source-file="CuratorCard.jsx" />

          <div className="relative z-20">
            {!isOwner && !isPro(curator?.type) && <ChatStart size="xs" className="chat-start" variant={getVariant()} chatUserId={curator?.id} btnProps={{
            labelClasses: 'hidden md:inline'
          }} />}

            {isPro(curator?.type) && !isOwner && <PushPro size="xs" className="push-pro" curatorId={curator?.id} curatorName={curator?.display_name ?? curator?.id} curatorAvatar={curator?.images} curatorType={curator?.type ?? USER_TYPES.USER} curatorRoles={curator?.roles} feedbackPrice={curator?.pushProFeedbackPrice} btnProps={{
            labelClasses: 'hidden md:inline'
          }} />}
          </div>
        </div>

        <div className="flex flex-col items-start justify-start gap-1 pl-1">
          <Heading className="inline-flex items-center gap-2 m-0 text-balance" data-sentry-element="Heading" data-sentry-source-file="CuratorCard.jsx">
            {curator?.display_name ? curator.display_name : curator?.id}
          </Heading>

          {curator?.roles && curator?.roles.length > 0 && <BadgesList size="sm" tags={curator?.roles.map(role => t(`role.${role}`))} maxVisible={1} className="mt-2" variant={getVariant()} hiddenVariant={getVariant()} />}
        </div>

        {children}
      </div>
    </div>;
};
export default CuratorCard;