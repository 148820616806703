import React from 'react';
import Button from '@/components/ui/Button';
import Image from '@/components/ui/Image';
import Placeholder from '@/components/ui/Placeholder';
import { useTranslations } from '@/hooks';
import { Link } from '@/i18n/routing';
import { buildSpotifyArtistUrl, cn } from '@/utils';
export const PushProTrack = ({
  className,
  push,
  isUnreleased,
  trackLink,
  trimLink
}) => {
  const t = useTranslations();
  return <div className={cn('flex items-center justify-between w-full gap-8', className)} data-sentry-component="PushProTrack" data-sentry-source-file="PushProTrack.jsx">
      <div className="flex items-start gap-4">
        <Image src={push.track.image} alt={push.track.name} className="overflow-hidden rounded w-14 h-14 shrink-0" placeholder={<Placeholder className="rounded w-14 h-14" icon="Music" />} data-sentry-element="Image" data-sentry-source-file="PushProTrack.jsx" />

        <div className="flex flex-col items-start justify-between gap-2">
          <Link href={trackLink} target="_blank" className="inline-flex items-center gap-2 text-sm font-medium" data-sentry-element="Link" data-sentry-source-file="PushProTrack.jsx">
            <span>{push.track.name}</span>
            {isUnreleased && <span className="text-xs opacity-70">
                ({t('field.track.type.options.unreleased.label')})
              </span>}
          </Link>

          <div className="flex flex-wrap items-center gap-2">
            {push?.artist && <Button href={buildSpotifyArtistUrl(push?.artist?.artistId)} target="_blank" variant="light" size="xs" icon="MicVocal" label={push?.artist?.artistName} />}

            <Button href={trackLink} target="_blank" variant="light" size="xs" label={isUnreleased ? trimLink(trackLink) : t('common.actions.open_in_spotify')} icon="SquareArrowOutUpRight" data-sentry-element="Button" data-sentry-source-file="PushProTrack.jsx" />
          </div>
        </div>
      </div>
    </div>;
};
export default PushProTrack;