import { PaymentItem } from '@/components/Plans/PaymentItem';
import Button from '@/components/ui/Button';
import CopyToClipboard from '@/components/ui/CopyToClipboard';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/Dialog';
import { COMPANY_ADDRESS, COMPANY_CITY, COMPANY_NAME, COMPANY_ZIP_CODE, COMPANY_COUNTRY, COMPANY_SDI_CODE, COMPANY_VAT_NUMBER, PUSH_PRO_CASH_OUT_EMAIL } from '@/data';
import { useTranslations } from '@/hooks';
import { formatCurrency } from '@/utils';
export const PushProCashOut = ({
  isOpen,
  onOpenChange,
  cashOutAmount,
  user
}) => {
  const t = useTranslations();
  const handleSendInvoice = () => {
    const subject = t('push_pro.cash_out.email.subject', {
      spotifyId: user.spotifyId
    });
    const body = t('push_pro.cash_out.email.body', {
      amount: formatCurrency(cashOutAmount),
      name: user.name
    });
    window.location.href = `mailto:${PUSH_PRO_CASH_OUT_EMAIL}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    onOpenChange(false);
  };
  const COMPANY_INFO = [{
    label: COMPANY_NAME,
    text: COMPANY_NAME
  }, {
    label: `${COMPANY_ADDRESS}, ${COMPANY_ZIP_CODE}, ${COMPANY_CITY}, ${COMPANY_COUNTRY}`,
    text: `${COMPANY_ADDRESS}, ${COMPANY_ZIP_CODE}, ${COMPANY_CITY}, ${COMPANY_COUNTRY}`
  }, {
    label: `${t('payment.common.vat', {
      vat: 'generic'
    })} ${COMPANY_VAT_NUMBER}`,
    text: COMPANY_VAT_NUMBER
  }, {
    label: `${t('payment.common.sdi', {
      sdi: 'generic'
    })} ${COMPANY_SDI_CODE}`,
    text: COMPANY_SDI_CODE
  }];
  return <Dialog open={isOpen} onOpenChange={onOpenChange} data-sentry-element="Dialog" data-sentry-component="PushProCashOut" data-sentry-source-file="PushProCashOut.jsx">
      <DialogContent className="max-w-2xl" data-sentry-element="DialogContent" data-sentry-source-file="PushProCashOut.jsx">
        <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="PushProCashOut.jsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="PushProCashOut.jsx">{t('push_pro.cash_out.title')}</DialogTitle>
        </DialogHeader>

        <div className="space-y-4">
          <PaymentItem subtitle={t('push_pro.wallet.cash_out_button')} title={t('push_pro.cash_out.amount_to_invoice')} color="green" price={cashOutAmount} icon="ReceiptEuro" customVatMessage={t('payment.common.vat', {
          vat: 'included'
        })} data-sentry-element="PaymentItem" data-sentry-source-file="PushProCashOut.jsx" />

          <div className="space-y-2">
            <h3 className="font-sans text-lg font-medium">
              {t('push_pro.cash_out.billing_data.title')}
            </h3>

            <div className="flex flex-col items-start gap-1 p-3 rounded-lg bg-secondary border-outline">
              {COMPANY_INFO.map(info => <CopyToClipboard key={info.label} variant="ghost" label={info.label} text={info.text} iconPosition="right" />)}
            </div>
          </div>

          <div className="mt-6 space-y-2">
            <h3 className="font-sans text-lg font-medium">
              {t('push_pro.cash_out.procedure.title')}
            </h3>

            <ol className="space-y-2 list-decimal list-inside">
              <li>{t('push_pro.cash_out.procedure.step1')}</li>
              <li>{t('push_pro.cash_out.procedure.step2')}
                <ul className="mt-1 ml-4 list-disc list-inside">
                  <li>{t('push_pro.cash_out.procedure.step2_detail1')}</li>
                  <li>{t('push_pro.cash_out.procedure.step2_detail2')}</li>
                  <li>{t('push_pro.cash_out.procedure.step2_detail3', {
                    amount: formatCurrency(cashOutAmount)
                  })}</li>
                  <li>{t('push_pro.cash_out.procedure.step2_detail4')}</li>
                </ul>
              </li>
              <li>{t('push_pro.cash_out.procedure.step3')}</li>
              <li>{t('push_pro.cash_out.procedure.step4')}</li>
            </ol>
          </div>
        </div>

        <Button label={t('push_pro.cash_out.send_invoice')} icon="Mail" className="w-full mt-4" variant="gradient-secondary" onClick={handleSendInvoice} data-sentry-element="Button" data-sentry-source-file="PushProCashOut.jsx" />
      </DialogContent>
    </Dialog>;
};
export default PushProCashOut;