import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import PushProListItem from './PushProListItem';
import { matchfyApi } from '@/api';
import Empty from '@/components/ui/Empty';
import Spinner from '@/components/ui/Spinner';
import { QUERIES } from '@/data';
import { useSession, useTranslations } from '@/hooks';
dayjs.extend(relativeTime);
export const PushProList = ({
  limit = 3,
  type,
  title,
  cta
}) => {
  const {
    data: session
  } = useSession();
  const t = useTranslations();
  const {
    data: pushPro,
    isLoading
  } = useQuery({
    queryKey: [QUERIES.CHAT.PUSH_PRO, session?.user?.spotifyId, type],
    queryFn: () => matchfyApi.listPushPro({
      limit
    }, type),
    enabled: !!session?.user?.spotifyId
  });
  return <div className="p-4 rounded-md card border-outline" data-sentry-component="PushProList" data-sentry-source-file="PushProList.jsx">
      <div className="flex items-center justify-between mb-4">
        <h4 className="m-0 font-sans text-lg font-normal text-muted-foreground">
          {title}
        </h4>

        {cta && cta}
      </div>

      <div className="flex flex-col items-start max-h-[250px] h-full overflow-y-auto">
        {isLoading && <div className="flex items-center justify-center w-full py-4">
            <Spinner inline />
          </div>}

        {!isLoading && pushPro?.data?.length > 0 && <div className="flex flex-col w-full gap-3">
            {pushPro.data.map(push => <PushProListItem key={push._id} badgeSize="xs" hideGems={true} hideStatus={true} push={push} />)}
          </div>}

        {!isLoading && pushPro?.data?.length === 0 && <Empty horizontal className="h-full" size="sm" />}
      </div>
    </div>;
};
export default PushProList;