'use client';

import { useEffect } from 'react';
import { ArtistCard, ArtistForm } from '@/components/Artist';
import { useSession, useTranslations } from '@/hooks';
import { useArtistStore, useMatchStore } from '@/store';
import { cn, notAgency } from '@/utils';
export const ArtistConfig = ({
  compact = false,
  hideLabel = false,
  onArtistSelect,
  className
}) => {
  const {
    artists,
    fetchArtists
  } = useArtistStore();
  const t = useTranslations();
  const {
    validateGenres
  } = useMatchStore();
  const {
    data: session,
    update
  } = useSession();
  const {
    user
  } = session || {};
  useEffect(() => {
    if (user.spotifyArtistIds.length && !artists.length) {
      fetchArtists(user.spotifyArtistIds);
    }
  }, [user.spotifyArtistIds]);
  useEffect(() => {
    if (artists.length > 0 && notAgency(user)) {
      const genres = artists.map(artist => artist.genres).flat();
      validateGenres(genres);
      if (onArtistSelect) {
        onArtistSelect();
      }
    }
  }, [artists]);
  const classes = cn(className);
  return <>
      {notAgency(user) && <div className={classes}>
          {(!user.spotifyArtistIds || user.spotifyArtistIds.length === 0) && <div className="artist-form">
                {!hideLabel && <h5 className="font-sans text-lg">
                    {t('match.common.artist.select')}
                  </h5>}

                <ArtistForm btnVariant="gradient" searchInputClasses="w-full md:w-[400px]" onArtistSelect={() => update()} />
              </div>}

          {artists && artists.length > 0 && <div className="flex flex-col">
              <h5 className="font-sans text-lg">
                {t('match.common.artist.selected')}
              </h5>

              <div className="flex gap-3">
                {artists.map(artist => <ArtistCard key={artist.id} artist={artist} compact={compact} />)}
              </div>
            </div>}
        </div>}
    </>;
};
export default ArtistConfig;