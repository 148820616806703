import { useDevStore } from '@/store';
export const DevFlag = ({
  flag,
  children
}) => {
  const {
    hasFlag
  } = useDevStore();
  if (!hasFlag(flag)) {
    return null;
  }
  return <>
      {children}
    </>;
};
export default DevFlag;