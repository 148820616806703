import * as SeparatorPrimitive from '@radix-ui/react-separator';
import * as React from 'react';
import { cn } from '@/utils';
const Separator = ({
  ref,
  className,
  orientation = 'horizontal',
  decorative = true,
  ...props
}) => <SeparatorPrimitive.Root ref={ref} decorative={decorative} orientation={orientation} className={cn('shrink-0 bg-border', orientation === 'horizontal' ? 'h-[1px] w-full' : 'h-full w-[1px]', className)} {...props} data-sentry-element="unknown" data-sentry-component="Separator" data-sentry-source-file="Separator.jsx" />;
Separator.displayName = SeparatorPrimitive.Root.displayName;
export { Separator };
export default Separator;