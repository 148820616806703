import { useMutation } from '@tanstack/react-query';
import { useForm, Controller } from 'react-hook-form';
import { matchfyApi } from '@/api';
import Alert from '@/components/ui/Alert';
import Button from '@/components/ui/Button';
import Field from '@/components/ui/Field';
import { useSession, useTranslations } from '@/hooks';
import { cn } from '@/utils';
export const UserEmailForm = ({
  submitBtnVariant,
  className
}) => {
  const {
    update
  } = useSession();
  const t = useTranslations();
  const {
    control,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm({
    defaultValues: {
      email: ''
    }
  });
  const {
    mutate: updateUserContacts,
    isSuccess,
    isError,
    isPending
  } = useMutation({
    mutationFn: matchfyApi.updateUserContacts,
    onSuccess: () => {
      update();
    }
  });
  const classes = cn({
    'flex flex-col gap-4': true,
    [className]: className
  });
  return <form id="add-email-form" className={classes} onSubmit={handleSubmit(updateUserContacts)} data-sentry-component="UserEmailForm" data-sentry-source-file="UserEmailForm.jsx">
      <Controller name="email" className="w-full" control={control} rules={{
      required: true
    }} render={({
      field
    }) => <Field type="email" label={t('field.email.label')} required error={errors.email} placeholder={t('field.email.placeholder')} {...field} />} data-sentry-element="Controller" data-sentry-source-file="UserEmailForm.jsx" />

      <Button loading={isPending} type="submit" variant={submitBtnVariant} label={t('common.actions.confirm')} className="col-span-2" data-sentry-element="Button" data-sentry-source-file="UserEmailForm.jsx" />

      {isSuccess && <Alert size="sm" variant="green">
          {t('email_verification.confirm.status.email_sent')}
        </Alert>}

      {isError && <Alert size="sm" variant="red">
          {t('errors.generic.internal_server_error')}
        </Alert>}
    </form>;
};
export default UserEmailForm;