import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { PushProDisputeUndo } from './PushProDisputeUndo';
import { matchfyApi } from '@/api';
import Button from '@/components/ui/Button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/Dialog';
import Field from '@/components/ui/Field';
import { PUSH_PRO_REPORT, STATUSES, getPushProSystemMessage } from '@/data';
import { QUERIES } from '@/data/queries';
import { useTranslations } from '@/hooks';
import { toast } from '@/lib';
import { useChatStore } from '@/store';
import { cn } from '@/utils';
export const PushProDispute = ({
  chatId,
  className,
  size,
  canOpenDispute,
  isProCurator,
  buttonTooltip = 'push_pro.report.tooltip.default'
}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const {
    pushProHasDispute,
    pushProId,
    setPushProHasDispute,
    sendSystemMessage
  } = useChatStore();
  const t = useTranslations();
  const {
    control,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm({
    defaultValues: {
      reason: PUSH_PRO_REPORT[0].value,
      description: '',
      id: pushProId
    }
  });
  const {
    mutate: reportPushPro
  } = useMutation({
    mutationFn: matchfyApi.reportPushPro,
    onSuccess: () => {
      const systemMessage = getPushProSystemMessage(STATUSES.DISPUTE, isProCurator);
      sendSystemMessage(systemMessage);
      setPushProHasDispute(true);
      queryClient.invalidateQueries({
        queryKey: [QUERIES.CHAT.LIST]
      });
      toast('success', t('push_pro.report.success'));
      setOpen(false);
    },
    onError: () => {
      toast('error', t('feedback.error.generic'));
    }
  });
  const onSubmit = async data => {
    setIsLoading(true);
    try {
      reportPushPro(data);
    } catch (error) {
      toast('error', t('feedback.error.generic'));
    } finally {
      setIsLoading(false);
    }
  };
  const classes = cn({
    'grid grid-cols-1 md:grid-cols-2 gap-4': true,
    [className]: className
  });
  if (pushProHasDispute) {
    return <PushProDisputeUndo chatId={chatId} className={className} size={size} isProCurator={isProCurator} />;
  }
  return <>
      <Button size={size} tooltip={!canOpenDispute ? buttonTooltip : null} disabled={!canOpenDispute} variant="red-light" icon="MessageSquareWarning" label={t('push_pro.button.report')} onClick={() => setOpen(true)} data-sentry-element="Button" data-sentry-source-file="PushProDispute.jsx" />

      <Dialog open={open} onOpenChange={setOpen} data-sentry-element="Dialog" data-sentry-source-file="PushProDispute.jsx">
        <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="PushProDispute.jsx">
          <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="PushProDispute.jsx">
            <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="PushProDispute.jsx">
              {t('push_pro.report.title')}
            </DialogTitle>

            <DialogDescription className="mt-3" data-sentry-element="DialogDescription" data-sentry-source-file="PushProDispute.jsx">
              {t('push_pro.report.description', {
              shouldParse: true
            })}
            </DialogDescription>
          </DialogHeader>

          <form id="push-pro-report-form" className={classes} onSubmit={handleSubmit(onSubmit)}>
            <Controller name="reason" control={control} rules={{
            required: true
          }} render={({
            field
          }) => <Field type="radio-group" label={t('field.type.pushPro.label')} optionsContainerClasses="grid grid-cols-1 md:grid-cols-2 gap-3 w-full" className="w-full col-span-2" direction="column" error={errors.reason} options={PUSH_PRO_REPORT} {...field} />} data-sentry-element="Controller" data-sentry-source-file="PushProDispute.jsx" />

            <Controller name="description" control={control} rules={{
            required: false
          }} render={({
            field
          }) => <Field type="textarea" label={t('field.description.label')} className="col-span-2" errors={errors.description} {...field} />} data-sentry-element="Controller" data-sentry-source-file="PushProDispute.jsx" />

            <Button type="submit" variant="red" loading={isLoading} disabled={isLoading} label={t('push_pro.report.title')} className="col-span-2" data-sentry-element="Button" data-sentry-source-file="PushProDispute.jsx" />
          </form>
        </DialogContent>
      </Dialog>
    </>;
};
export default PushProDispute;