import dayjs from 'dayjs';
import { useLocale, useTranslations } from '@/hooks';
import { cn, getDateFormat } from '@/utils';
export const Date = ({
  className,
  value,
  format,
  includeTime = false
}) => {
  const t = useTranslations();
  const {
    locale
  } = useLocale();
  if (!value) return null;
  const classes = cn({
    [className]: className
  });
  return <time className={classes} dateTime={value} data-sentry-component="Date" data-sentry-source-file="Date.jsx">
      {dayjs(value).format(getDateFormat(locale, includeTime, format))}
    </time>;
};
export default Date;